import React from 'react';
import styles from './style/footer.module.scss';
import PropTypes from 'prop-types';

class Footer extends React.PureComponent {
  render() {
    return (
      <div className={`${styles.footer} ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Footer;