import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NewLabelItem, NewTitleCard, NoData } from '@/components';
import { ApplyStatus, SaleStatus } from '@/localData';
// import { purchaseBusinessTypeList, paymentTypeList, purchaseStatus } from '@/localData';
// import { getItemByValue, formatThousands } from '@/utils/dataFormat';

function applyInfo(props) {
  const baseInfo = props.baseInfo;
  const carBasic = props.carBasic;
  return (
    <NewTitleCard title="审批信息">
      <NewLabelItem label="申请状态" handleContent={<div>{(baseInfo && baseInfo.status !== null && SaleStatus[baseInfo.status + 1]) ? SaleStatus[baseInfo.status + 1].label : ''}</div>} />
      <NewLabelItem label="申请时间" handleContent={<div>{(baseInfo && baseInfo.createTime) ? baseInfo.createTime : ''}</div>} />
      <NewLabelItem label="申请人员" handleContent={<div>{(carBasic && carBasic.blocName) ? carBasic.blocName : ''}</div>} />
      <NewLabelItem label="审批人员" handleContent={<div>{(baseInfo && baseInfo.approvalName) ? baseInfo.approvalName : ''}</div>} />
      <NewLabelItem label="审批时间" handleContent={<div>{(baseInfo && baseInfo.approvalTime) ? baseInfo.approvalTime : ''}</div>} />
      <NewLabelItem label="备注说明" handleContent={<div>{(baseInfo && baseInfo.remarks) ? baseInfo.remarks : ''}</div>} />
    </NewTitleCard>
  );
}
applyInfo.propTypes = {
  baseInfo: PropTypes.object,
  carBasic: PropTypes.object
};
export default withRouter(applyInfo);
