/* eslint-disable multiline-ternary */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Carousel, WingBlank } from 'antd-mobile';
import PropTypes from 'prop-types';
import { imageBaseUrl } from '@/api/http';
import { mountGlobalImagePopup, unMountGlobalImagePopup } from '@/components/imagePopup/RenderGlobalImagePopup';
import autobind from 'autobind-decorator';
import styles from './style/slider.module.scss';
import iconPhoto from '@/assets/image/zpxj.png';

class antdSlider extends PureComponent {
  @autobind
  clickImage(index) {
    if (this.props.isDetailHead) {
      this.props.history.push({ pathname: '/imageManage', query: { currentPage: 0 } });
    } else {
      let imageList = [];
      imageList = this.props.sliders.map(item => {
        return {
          url: imageBaseUrl + item.photoUrl,
          code: this.props.code
        };
      });
      // mountGlobalImagePopup({ imageList: arr, currentIndex: index });
      mountGlobalImagePopup({
        imageList,
        currentIndex: index,
        onClose: () => {
          unMountGlobalImagePopup();
        }
      });
    }
    // {
    //   imageList: this.props.imageList,
    //   currentIndex: index
    // }
    // let arr = [];
    // arr = this.props.sliders.map(item => {
    //   return item.photoUrl;
    // });
    // mountGlobalImagePopup({ imageList: arr, currentIndex: index });
  }

  toImageManage = () => {
    if (this.props.sliders && this.props.sliders.length > 0) {
      this.props.history.push({ pathname: '/imageManage', query: { currentPage: 0 } });
    }
  }

  render() {
    return (
      <WingBlank className={`${styles.wingDom} ${this.props.className ? this.props.className : ''}`}>
        {this.props.sliders && this.props.sliders.length > 0 ? <Carousel
          autoplay={false}
          infinite
          dots={this.props.dots}
        >
          {this.props.sliders && this.props.sliders.map((val, index) => (
            <div
              key={index}
              style={{ display: 'inline-block', width: '100%', height: this.props.imgHiehgt }}
              className={this.props.sliderClassName}
            >
              <img
                src={imageBaseUrl + val.photoUrl}
                alt=""
                style={{ width: '100%', verticalAlign: 'top' }}
                onLoad={() => {
                  window.dispatchEvent(new Event('resize'));
                  // this.setState({ imgHeight: 'auto' });
                }}
                onClick={() => this.clickImage(index)}
              />
            </div>
          ))}
        </Carousel> : <div className={styles.noImage} onClick={this.toImageManage}></div>}
        <div className={`${styles.picNum} ${this.props.photoNumTop ? styles.photoNumTop : ''}`}>
          <img src={iconPhoto} />
          <span>{this.props.sliders && this.props.sliders.length + '张'}</span>
        </div>
      </WingBlank>
    );
  }
}
antdSlider.propTypes = {
  sliders: PropTypes.array,
  className: PropTypes.string,
  dots: PropTypes.bool,
  imgHiehgt: PropTypes.string,
  sliderClassName: PropTypes.string,
  photoNumTop: PropTypes.bool
};
antdSlider.defaultProps = {
  dots: true,
  photoNumTop: false
};
export default withRouter(antdSlider);
