/* eslint-disable no-case-declarations */
/*
 * @Descripttion:picker
 * @Author: zhongyuanrui
 * @Date: 2019-09-19 10:26:52
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-11-25 18:19:31
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Picker } from 'antd-mobile';
import SelectItem from './SelectItem';
import { getItemByValue, recursiveItemByValue, spliceStringByFormat } from '@/utils/dataFormat';
import autobind from 'autobind-decorator';

@autobind
class PickerItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLabel: []
    };
  }

  // componentDidMount() {
  // this.updateValue([...this.props.value]);
  // }

  // updateValue(value) {
  // let _currentLabel = '';
  // const _list = recursiveItemByValue(this.props.pickerData, value, 'value', this.props.cols);
  // console.log();
  // _list.forEach((item, index) => {
  // if (index === _list.length - 1) {
  // _currentLabel += `${item.label}`;
  // } else {
  // _currentLabel += `${item.label}/`;
  // }
  // });
  // this.setState({
  // currentLabel: _currentLabel
  // });
  // }

  onOk(value) {
    // console.log('value:' + value);
    // console.log(this.props.pickerData[0]);
    // const label = this.state.currentLabel;
    for (let i = 0; i < this.props.cols; i++) {
      if (!value[i]) {
        switch (i) {
          case 0:
            value[i] = this.props.pickerData[0].value;
            // label.push(this.props.pickerData[0].label);
            // label[i] = this.props.pickerData[0].label;
            break;
          case 1:
            const child = getItemByValue(this.props.pickerData, value[0]).children;
            value[i] = (child && child.length > 0) ? child[0].value : null;
            // label[i] = (child && child.length > 0) ? child[0].label : null;
            // label.push((child && child.length > 0) ? child[0].label : null);
            break;
          case 2:
            const child2 = getItemByValue(this.props.pickerData, value[0]);
            const childNext = getItemByValue(child2.children, value[1]);
            value[i] = childNext.children[0].value;
            // label[i] = childNext.children[0].label;
            // label.push(childNext.children[0].label);
            break;
          default:
            break;
        }
      }
    }
    console.log(value);
    // console.log(label);
    // console.log('sss');
    // console.log(this.state.currentLabel);
    // this.updateValue(value);
    this.props.onOk(value, this.props.type);
  }

  render() {
    // const a = recursiveItemByValue(this.props.pickerData, [...this.props.value], 'value', this.props.cols);
    // console.log(a);
    // const b = spliceStringByFormat(a);
    // console.log(b);
    return (
      <Picker
        title={this.props.pickerTitle}
        data={this.props.pickerData}
        value={[...this.props.value]}
        cols={this.props.cols}
        onPickerChange={this.props.onPickerChange}
        onChange={this.props.onChange}
        onOk={this.onOk}
        onDismiss={this.props.onDismiss}
        disabled={this.props.disabled}
        cascade={this.props.cascade}
      >
        <SelectItem
          isRight={this.props.isRight}
          label={this.props.cols > 1
            ? spliceStringByFormat(recursiveItemByValue(this.props.pickerData, [...this.props.value], 'value', this.props.cols))
            : getItemByValue(this.props.pickerData, this.props.value[0]).label}
          placeholder={this.props.placeholder}
        />
        {/* {this.props.cols > 1
          ? <SelectItem isRight={this.props.isRight} label={spliceStringByFormat(recursiveItemByValue(this.props.pickerData, [...this.props.value], 'value', this.props.cols))} placeholder={this.props.placeholder} />
          : <SelectItem isRight={this.props.isRight} label={getItemByValue(this.props.pickerData, this.props.value[0]).label} placeholder={this.props.placeholder} />} */}
      </Picker>
    );
  }
}
PickerItem.propTypes = {
  // picker标题
  pickerTitle: PropTypes.string,
  // picker数据
  pickerData: PropTypes.array,
  // picker列数
  cols: PropTypes.number,
  // value
  value: PropTypes.array,
  // 占位符
  placeholder: PropTypes.string,
  // 修改数据
  onPickerChange: PropTypes.func,
  // 修改
  onChange: PropTypes.func,
  // 点击ok
  onOk: PropTypes.func,
  // 点击取消
  onDismiss: PropTypes.func,
  // 是否可用
  disabled: PropTypes.bool,
  // 是否联动
  cascade: PropTypes.bool,
  // 是否右对其
  isRight: PropTypes.bool,
  // 字段类型
  type: PropTypes.string
};

PickerItem.defaultProps = {
  // picker标题
  pickerTitle: '',
  // picker数据
  pickerData: [],
  // picker列数
  cols: 1,
  // value
  value: [],
  // 占位符
  placeholder: '请选择',
  // 是否不可用
  disabled: false,
  // 是否联动
  cascade: true,
  isRight: false,
  type: ''
};

export default PickerItem;
