/*
 * @Descripttion:带标题的单文件上传
 * @Author: zhongyuanrui
 * @Date: 2019-09-19 20:25:46
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-10-11 14:55:37
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style/titleSingleUpload.module.scss';
import SingleUpload from './SingleUpload';

class TitleSingleUpload extends Component {
  render() {
    return (
      <div className={styles.titleSingleUpload}>
        <SingleUpload bgImage={this.props.bgImage} type={this.props.type} file={this.props.file} onChange={this.props.onChange} photoType={this.props.photoType}
         allPhotos={this.props.allPhotos ? this.props.allPhotos : []} ableUploadImg={this.props.ableUploadImg} ableRemoveImg={this.props.ableRemoveImg}/>
        <div className={`${styles.credentialsImageName} ${this.props.isRequire ? styles.requireStyle : ''}`}>{this.props.title}</div>
      </div>
    );
  }
}

TitleSingleUpload.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  // 文件
  file: PropTypes.string,
  // 标题
  title: PropTypes.string,
  // 修改图片
  onChange: PropTypes.func,
  isRequire: PropTypes.bool,
  ableUploadImg: PropTypes.bool,
  ableRemoveImg: PropTypes.bool
};

TitleSingleUpload.defaultProps = {
  type: '',
  file: '',
  title: '',
  isRequire: false
};

export default TitleSingleUpload;
