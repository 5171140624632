import React from 'react';
import { ModifyCarInfo } from '../../../containers/carInfoEdit';
import DALModifyCarInfo from '@/constants/carInfoEdit/DALModifyCarInfo';
import { Provider, observer } from 'mobx-react';

class CarInfoEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Provider DALModifyCarInfo={DALModifyCarInfo}>
        <div>
          <ModifyCarInfo />
        </div>
      </Provider>
    );
  }
}

export default CarInfoEdit;