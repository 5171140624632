// 经销商看板
import React, { createRef } from 'react';
import DALCommon from '@/constants/common/state';
import { ListView, Toast, Picker, Progress, Flex } from 'antd-mobile';
import styles from './style/dealerEcharts.module.scss';
import API from '@/api';
import { defaultEcharts } from '@/echartsData';
import echarts from 'echarts';
import 'echarts/lib/chart/pie'; // 折线图是line,饼图改为pie,柱形图改为bar
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markPoint';
import ReactEcharts from 'echarts-for-react';
import {
  LabelItem,
  InputItem,
  Card,
  PickerItem,
  DatePickerItem,
  TopNavBar,
  Footer,
  Button,
  CadillacRadioGroup
} from '@/components';
import { transformFile } from '@babel/core';

class DealerEcharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
      isFirstHalfYear: false, // 是否上半年
      dealerData: {},
      indexSelect1: {},
      indexSelect2: {},
      indexSelect3: {},
      indexSelect4: {},
      indexSelect5: {},
      indexSelect6: {},
      indexSelect7: {},
      indexSelect8: {},
      indexSelect9: {}
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    if (DALCommon.blocNum === null) {
      Toast.info('经销商信息为空');
      return;
    }

    const today = new Date();
    const isFirstHalfYear = (today.getMonth() + 1) < 7;
    this.setState({
      isFirstHalfYear
    });
    const param = {
      dealerCode: DALCommon.blocNum
    };

    console.log('blocNum', DALCommon.blocNum);

    API.CarSource.getDealerData(param).then((res) => {
      //
      if (res.code === 200 && res.data) {
        if (res.data.salesSum) {
          const option1 = { ...defaultEcharts.option1 }; // 销量同区占比
          option1.series[0].data = [...res.data.salesSum];
          this.setState({
            indexSelect1: option1
          });
        };
        if (res.data.salesSumByFamily) {
          const option2 = { ...defaultEcharts.option2 }; // 销量分车系占比
          option2.series[0].data = [...res.data.salesSumByFamily];
          this.setState({
            indexSelect2: option2
          });
        }
        // if (res.data.carType) {
        //   const option3 = { ...defaultEcharts.option3 }; // 车辆类别占比
        //   option3.series[0].data = [...res.data.carType];
        //   this.setState({
        //     indexSelect3: option3
        //   });
        // }
        if (res.data.marginByRegion) {
          const option4 = { ...defaultEcharts.option4 }; // 毛利同区排名
          if (res.data.marginByRegion.names) {
            option4.xAxis.data = [...res.data.marginByRegion.names];
          }
          if (res.data.marginByRegion.values) {
            option4.series[0].data = [
              ...res.data.marginByRegion.values
            ];
          }
          this.setState({
            indexSelect4: option4
          });
        };
        if (res.data.regionRanking) {
          const option7 = { ...defaultEcharts.option7 }; // 我的大区排名
          if (res.data.regionRanking.names) {
            option7.xAxis.data = [...res.data.regionRanking.names];
          }
          option7.series[0].total = res.data.regionRanking.total;
          option7.series[0].data = [];
          if (res.data.regionRanking.rank) {
            res.data.regionRanking.rank.forEach((item) => {
              option7.series[0].data.push(
                Number(res.data.regionRanking.total) + 1 - Number(item)
              );
            });
          }
          this.setState({
            indexSelect7: option7
          });
        }
        if (res.data.nationalRanking) {
          const option8 = { ...defaultEcharts.option8 }; // 我的全国排名
          if (res.data.nationalRanking.names) {
            option8.xAxis.data = [...res.data.nationalRanking.names];
          }
          option8.series[0].total = res.data.nationalRanking.total;
          option8.series[0].data = [];
          if (res.data.nationalRanking.rank) {
            res.data.nationalRanking.rank.forEach((item) => {
              option8.series[0].data.push(
                Number(res.data.nationalRanking.total) +
                  1 -
                  Number(item)
              );
            });
          }
          this.setState({
            indexSelect8: option8
          });
        };
        if (res.data.salesProvince) {
          const option9 = { ...defaultEcharts.option9 }; // 本品销量同城市占比
          option9.series[0].data = [...res.data.salesProvince];
          this.setState({
            indexSelect9: option9
          });
        };
        if (
          res.data.excellenceDealerByNational &&
          res.data.excellenceDealerByNational.length > 0
        ) {
          res.data.excellenceDealerByNational.forEach(
            (element, index) => {
              if (index === 0) {
                element.title = '销量王';
              }
              if (index === 1) {
                element.title = '收车王';
              }
              // 毛利最高不展示了
              // if (index === 2) {
              //   element.title = '毛利最高';
              // }
              if (index === 2) {
                element.title = '奖金最高';
              }
            }
          );
        };
        if (
          res.data.excellenceDealerByRegion &&
          res.data.excellenceDealerByRegion.length > 0
        ) {
          res.data.excellenceDealerByRegion.forEach(
            (element, index) => {
              if (index === 0) {
                element.title = '销量王';
              }
              if (index === 1) {
                element.title = '收车王';
              }
              // 毛利最高不展示了
              // if (index === 2) {
              //   element.title = '毛利最高';
              // }
              if (index === 2) {
                element.title = '奖金最高';
              }
            }
          );
        };

        this.setState({
          dealerData: res.data || {}
        });
        console.log('1110', this.state.dealerData);
      }
    });
  }

  onLeftArrowClick() {
    const scorllView = document.getElementById('dropRef');
    scorllView.scrollBy(-50, 0);
  }

  onRightArrowClick() {
    const scorllView = document.getElementById('dropRef');
    scorllView.scrollBy(50, 0);
  }

  render() {
    const { dealerData, isFirstHalfYear } = this.state;

    return (
      <div className={styles.dealerEcharts}>
        <TopNavBar title={'经销商看板'} />
        <div className={styles.dealerCode}>经销商代码：{DALCommon.blocNum.toUpperCase()}</div>
        <div className={styles.content}>
          <div className={styles.leftArrow} onClick={this.onLeftArrowClick} />
          <div className={styles.dataContent} id="dropRef">
            {dealerData.totalData &&
              dealerData.totalData.length > 0 &&
              dealerData.totalData.map((item, index) => {
                return (
                  <div className={styles[`img${index % 6}`]} key={index}>
                    <div className={styles.itemTotal}>{item.total}</div>
                    <span className={styles.itemValue}
                      >{ item.value
                      }<span style={{ fontSize: '12px' }}>{ item.target }</span></span>
                  </div>
                );
              })}
          </div>
          <div className={styles.rightArrow} onClick={this.onRightArrowClick} />
        </div>
        <div className={styles.bigTitle}>任务进度</div>
        {dealerData && dealerData.workProgress && dealerData.workProgress.length > 0 && dealerData.workProgress.map((item, index) => {
          return (
            <div className={styles.progressContainer} key={index}>
              <div className={styles.taskTitie}>{ index === 1 ? '年度任务进度' : isFirstHalfYear ? '上半年任务进度' : '下半年任务进度' }</div>
              <div className={styles.progressNum}>
                <Progress percent={Number(item.value) > Number(item.total) ? 100 : Math.round((parseFloat(item.value) / parseFloat(item.total)) * 100)} position="normal" style={{ width: '80%', height: '5px' }}/>
                <div style={{ width: '100px', marginLeft: '10px', whiteSpace: 'nowrap', fontSize: '14px' }}>{item.total}台/{Math.round((parseFloat(item.value) / parseFloat(item.total)) * 100)}%</div>
              </div>
            </div>
          );
        })}

        <div className={styles.bigTitle}>{isFirstHalfYear ? '上半年' : '下半年'}销售贡献奖金</div>
        <div className={styles.rankContent}>
          <div className={styles.saleContent}>
            <span style={{ marginLeft: '10px' }}>试乘试驾车</span>
            <span>非试乘试驾车</span>
            <span>单车毛利率</span>
            <span style={{ marginRight: '10px' }}>销售贡献奖</span>
          </div>
          <div className={styles.saleContent}>
            <span style={{ marginLeft: '10px' }}>{ (dealerData.dealerBonusInfo && dealerData.dealerBonusInfo.testDriveNum) || '-' }台</span>
            <span>{ (dealerData.dealerBonusInfo && dealerData.dealerBonusInfo.testDriveNotNum) || '-' }台</span>
            <span>{ (dealerData.dealerBonusInfo && dealerData.dealerBonusInfo.bicycleMargin) || '-' }%</span>
            <span style={{ marginRight: '10px' }}>{ (dealerData.dealerBonusInfo && dealerData.dealerBonusInfo.dealerBonus) || '-' }元</span>
          </div>
        </div>
        <div className={styles.bigTitle}>{isFirstHalfYear ? '上半年' : '下半年'}经销商奖金排名</div>
        <div className={styles.rankContent}>
          {dealerData && dealerData.dealerBonusRank && dealerData.dealerBonusRank.names && dealerData.dealerBonusRank.names.length > 0 &&
          dealerData.dealerBonusRank.names.map((item, index) => {
            //
            return (
              <div className={styles.saleContent} key={index}>
                <span style={{ marginLeft: '10px' }}>{item}</span>
                <span style={{ marginRight: '10px' }}>No.{index + 1}</span>
              </div>
            );
          })}
          {dealerData && dealerData.dealerBonusRank && dealerData.dealerBonusRank.total && <div className={styles.saleContent}>
            <span style={{ marginLeft: '10px' }}>我的排名</span>
            <span style={{ marginRight: '10px' }}>No.{dealerData.dealerBonusRank.total}</span>
          </div>}
        </div>
        <div style={{ width: '100%' }}>
          <ReactEcharts option={this.state.indexSelect2}/>
          <ReactEcharts option={this.state.indexSelect1}/>
          {/* <ReactEcharts option={this.state.indexSelect3}/> */}
          {/* <ReactEcharts option={this.state.indexSelect4}/> */}
          {/* <ReactEcharts option={this.state.indexSelect5}/> */}
          {/* <ReactEcharts option={this.state.indexSelect6}/> */}
          {/* <ReactEcharts option={this.state.indexSelect7}/> */}
        </div>
        <div className={styles.bigTitle}>毛利同区排名</div>
        <div className={styles.rankContent}>
          {dealerData && dealerData.marginByRegion && dealerData.marginByRegion.names && dealerData.marginByRegion.names.length > 0 &&
          dealerData.marginByRegion.names.map((item, index) => {
            //
            return (
              <div className={styles.saleContent} key={index}>
                <span style={{ marginLeft: '10px' }}>{item}</span>
                <span style={{ marginRight: '10px' }}>No.{index + 1}</span>
              </div>
            );
          })}
        </div>
        <div className={styles.bigTitle}>我的大区排名</div>
        <div className={styles.rankContent}>
          {dealerData && dealerData.regionRanking && dealerData.regionRanking.names && dealerData.regionRanking.names.length > 0 &&
          dealerData.regionRanking.names.map((item, index) => {
            //
            return (
              <div className={styles.saleContent} key={index}>
                <span style={{ marginLeft: '10px' }}>{item}</span>
                <span style={{ marginRight: '10px' }}>No.{dealerData.regionRanking.rank[index]}</span>
              </div>
            );
          })}
          <div className={styles.saleChampionTitleBg}>优秀经销商案例（本区）</div>
          <div className={styles.rankContent}>
            {dealerData.excellenceDealerByRegion &&
                dealerData.excellenceDealerByRegion.length > 0 &&
                dealerData.excellenceDealerByRegion.map((item, index) => {
                  return (
                    <div className={styles.saleContent} key={index}>
                      {/* <img className={styles[`rangeIcon${index + 1}`]}/> */}
                      <span className={`${styles.saleChampionTextBg} ${styles.flex4}`}>{item.title ? item.title : ''}</span>
                      <span className={`${styles.saleChampionTextBg} ${styles.flex15}`}>{item.name ? item.name : ''}</span>
                      <span className={`${styles.saleChampionTextBg} ${styles.flex4}`}>{item.value ? item.value : ''}{index === 0 || index === 1 ? '台' : '万'}</span>
                    </div>
                  );
                })}
          </div>
        </div>
        <div style={{ height: '30px' }}/>
        <div className={styles.bigTitle}>我的全国排名</div>
        <div className={styles.rankContent}>
          {dealerData && dealerData.nationalRanking && dealerData.nationalRanking.names && dealerData.nationalRanking.names.length > 0 &&
          dealerData.nationalRanking.names.map((item, index) => {
            //
            return (
              <div className={styles.saleContent} key={index}>
                <span style={{ marginLeft: '10px' }}>{item}</span>
                <span style={{ marginRight: '10px' }}>No.{dealerData.nationalRanking.rank[index]}</span>
              </div>
            );
          })}

          <div className={styles.saleChampionTitleBg}>优秀经销商案例（全国）</div>
          <div className={styles.rankContent}>
            {dealerData.excellenceDealerByNational &&
              dealerData.excellenceDealerByNational.length > 0 &&
              dealerData.excellenceDealerByNational.map((item, index) => {
                return (
                  <div className={styles.saleContent} key={index}>
                    {/* <img className={styles[`rangeIcon${index + 1}`]}/> */}
                    <span className={`${styles.saleChampionTextBg} ${styles.flex4}`}>{item.title ? item.title : ''}</span>
                    <span className={`${styles.saleChampionTextBg} ${styles.flex15}`}>{item.name ? item.name : ''}</span>
                    <span className={`${styles.saleChampionTextBg} ${styles.flex4}`}>{item.value ? item.value : ''}{index === 0 || index === 1 ? '台' : '万'}</span>
                  </div>
                );
              })}
        </div>
        </div>
        {/* <ReactEcharts option={this.state.indexSelect8}/> */}
        <div style={{ height: '30px' }}/>
        <ReactEcharts option={this.state.indexSelect9}/>
      </div>
    );
  }
}

export default DealerEcharts;
