export const defaultEcharts = {
  option1: {
    title: {
      text: '销量同区占比',
      // subtext: 'Fake Data',
      left: '10%',
      top: '10%',
      textStyle: {
        fontSize: 18
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return params.name + ' ' + params.value + '台';
      }
    },
    legend: {
      // // 图例组件
      orient: 'vertical',
      top: 'center',
      right: '2',
      itemHeight: 12,
      itemWidth: 12,
      formatter: function (name) {
        // 自定义图例的文字
        let total = 0;
        let target;
        const data = defaultEcharts.option1.series[0].data;
        for (let i = 0, l = data.length; i < l; i++) {
          total += Number(data[i].value);
          if (data[i].name === name) {
            target = data[i].value;
          }
        }
        let percent = 0;
        if (total === 0) {
          percent = 0;
        } else {
          percent = ((target / total) * 100).toFixed(2);
        }
        return (
          '{a|' + name + '(' + target + '台)}' + '   ' + '{b|' + percent + '%}'
        );
      },
      textStyle: {
        rich: {
          a: {
            width: 60,
            fontSize: 12,
            textAlign: 'center',
            color: '#999999'
          },
          b: {
            fontSize: 12,
            textAlign: 'center',
            color: '#333333'
          }
        }
      }
    },
    series: [
      {
        // name: 'Access From',
        type: 'pie',
        avoidLabelOverlap: true, // 是否启用防止标签重叠策略
        radius: '50%',
        center: ['30%', '50%'], // 饼图的上下左右位置
        itemStyle: {
          // item之间的留白间隔
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 2
        },
        data: [
          // { value: 1048, name: 'Search Engine' },
          // { value: 735, name: 'Direct' },
          // { value: 580, name: 'Email' },
          // { value: 484, name: 'Union Ads' },
          // { value: 300, name: 'Video Ads' }
        ],
        label: {
          // 饼图图形上的文本标签
          show: false
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  },

  option2: {
    title: {
      text: '销量分车系占比',
      // subtext: 'Fake Data',
      left: '10%',
      top: '10%',
      textStyle: {
        fontSize: 18
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return params.name + ' ' + params.value + '台';
      }
    },
    legend: {
      // // 图例组件
      orient: 'vertical',
      top: 'center',
      right: '2',
      itemHeight: 12,
      itemWidth: 12,
      formatter: function (name) {
        // 自定义图例的文字
        let total = 0;
        let target;
        const data = defaultEcharts.option2.series[0].data;
        for (let i = 0, l = data.length; i < l; i++) {
          total += Number(data[i].value);
          if (data[i].name === name) {
            target = data[i].value;
          }
        }
        let percent = 0;
        if (total === 0) {
          percent = 0;
        } else {
          percent = ((target / total) * 100).toFixed(2);
        }
        return (
          '{a|' + name + '(' + target + '台)}' + '   ' + '{b|' + percent + '%}'
        );
      },
      textStyle: {
        rich: {
          a: {
            width: 100,
            fontSize: 12,
            textAlign: 'center',
            color: '#999999'
          },
          b: {
            fontSize: 12,
            textAlign: 'center',
            color: '#333333'
          }
        }
      }
    },
    series: [
      {
        // name: 'Access From',
        type: 'pie',
        avoidLabelOverlap: true, // 是否启用防止标签重叠策略
        radius: '50%',
        center: ['30%', '50%'], // 饼图的上下左右位置
        itemStyle: {
          // item之间的留白间隔
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 2
        },
        data: [
          // { value: 1048, name: 'Search Engine' },
          // { value: 735, name: 'Direct' },
          // { value: 580, name: 'Email' },
          // { value: 484, name: 'Union Ads' },
          // { value: 300, name: 'Video Ads' }
        ],
        label: {
          // 饼图图形上的文本标签
          show: false
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  },

  option3: {
    title: {
      text: '车辆类别占比',
      // subtext: 'Fake Data',
      left: '10%',
      top: '10%',
      textStyle: {
        fontSize: 18
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return params.name + ' ' + params.value + '台';
      }
    },
    legend: {
      // // 图例组件
      orient: 'vertical',
      top: 'center',
      right: '10',
      itemHeight: 12,
      itemWidth: 12,
      formatter: function (name) {
        // 自定义图例的文字
        let total = 0;
        let target;
        const data = defaultEcharts.option3.series[0].data;
        for (let i = 0, l = data.length; i < l; i++) {
          total += Number(data[i].value);
          if (data[i].name === name) {
            target = data[i].value;
          }
        }
        let percent = 0;
        if (total === 0) {
          percent = 0;
        } else {
          percent = ((target / total) * 100).toFixed(2);
        }
        return (
          '{a|' + name + '(' + target + '台)}' + '   ' + '{b|' + percent + '%}'
        );
      },
      textStyle: {
        rich: {
          a: {
            width: 120,
            fontSize: 12,
            textAlign: 'center',
            color: '#999999'
          },
          b: {
            fontSize: 12,
            textAlign: 'center',
            color: '#333333'
          }
        }
      }
    },
    series: [
      {
        // name: 'Access From',
        type: 'pie',
        avoidLabelOverlap: true, // 是否启用防止标签重叠策略
        radius: '50%',
        center: ['30%', '50%'], // 饼图的上下左右位置
        itemStyle: {
          // item之间的留白间隔
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 2
        },
        data: [
          // { value: 1048, name: 'Search Engine' },
          // { value: 735, name: 'Direct' },
          // { value: 580, name: 'Email' },
          // { value: 484, name: 'Union Ads' },
          // { value: 300, name: 'Video Ads' }
        ],
        label: {
          // 饼图图形上的文本标签
          show: false
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  },

  option4: {
    title: {
      text: '毛利同区排名',
      // subtext: 'Fake Data',
      top: '0',
      left: '10%',
      textStyle: {
        fontSize: 18
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return params.name + ' ' + (params.value / 10000).toFixed(2) + '万';
      }
    },
    xAxis: {
      type: 'category',
      data: [],
      axisTick: false,
      axisLabel: {
        formatter: function (params) {
          // params为x轴文字内容
          let newParamsName = '';
          const paramsNameNumber = params.length;
          const provideNumber = 3; // 一行显示几个字
          const rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
            for (let p = 0; p < rowNumber; p++) {
              let tempStr = '';
              const start = p * provideNumber;
              const end = start + provideNumber;
              if (p === rowNumber - 1) {
                tempStr = params.substring(start, paramsNameNumber);
              } else {
                tempStr = params.substring(start, end) + '\n';
              }
              newParamsName += tempStr;
            }
          } else {
            newParamsName = params;
          }
          return newParamsName;
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function () {
          return '';
        }
      }
    },
    series: [
      {
        data: [],
        type: 'bar',
        barWidth: '14',
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'top',
              formatter: function (params) {
                if (params.value) {
                  // console.log("params:", params.value)
                  return (Number(params.value) / 10000).toFixed(2) + '万';
                } else {
                  return '';
                }
              },
              textStyle: {
                fontSize: '12'
                // color: "#8A1629"
              }
            },
            barBorderRadius: [7, 7, 0, 0]
          }
        }
      }
    ]
  },

  option5: {
    title: {
      text: '库融渗透率同区排名',
      // subtext: 'Fake Data',
      top: '0',
      left: '10%',
      textStyle: {
        fontSize: 18
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return params.name + ' ' + params.value + '%';
      }
    },
    xAxis: {
      type: 'category',
      data: [],
      axisTick: false,
      axisLabel: {
        formatter: function (params) {
          // params为x轴文字内容
          let newParamsName = '';
          const paramsNameNumber = params.length;
          const provideNumber = 4; // 一行显示几个字
          const rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
            for (let p = 0; p < rowNumber; p++) {
              let tempStr = '';
              const start = p * provideNumber;
              const end = start + provideNumber;
              if (p === rowNumber - 1) {
                tempStr = params.substring(start, paramsNameNumber);
              } else {
                tempStr = params.substring(start, end) + '\n';
              }
              newParamsName += tempStr;
            }
          } else {
            newParamsName = params;
          }
          return newParamsName;
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function () {
          return '';
        }
      }
    },
    series: [
      {
        data: [],
        type: 'bar',
        barWidth: '14',
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'top',
              formatter: '{c}' + '%',
              textStyle: {
                fontSize: '12'
                // color: "#8A1629"
              }
            },
            barBorderRadius: [7, 7, 0, 0]
          }
        }
      }
    ]
  },

  option6: {
    title: {
      text: '政策补贴同区占比',
      // subtext: 'Fake Data',
      left: '10%',
      top: '0',
      textStyle: {
        fontSize: 18
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        const str = params.name + ' ' + params.value + '元';
        console.log('str:', str);
        return str;
      }
    },
    legend: {
      // // 图例组件
      orient: 'vertical',
      top: 'center',
      right: '10',
      itemHeight: 12,
      itemWidth: 12,
      formatter: function (name) {
        // 自定义图例的文字
        let total = 0;
        let target;
        const data = defaultEcharts.option6.series[0].data;
        for (let i = 0, l = data.length; i < l; i++) {
          total += Number(data[i].value);
          if (data[i].name === name) {
            target = data[i].value;
          }
        }
        let percent = 0;
        if (total === 0) {
          percent = 0;
        } else {
          percent = ((target / total) * 100).toFixed(2);
        }
        return (
          '{a|' + name + '(' + target + '元)}' + '   ' + '{b|' + percent + '%}'
        );
      },
      textStyle: {
        rich: {
          a: {
            width: 140,
            fontSize: 12,
            textAlign: 'center',
            color: '#999999'
          },
          b: {
            fontSize: 12,
            textAlign: 'center',
            color: '#333333'
          }
        }
      }
    },
    series: [
      {
        // name: 'Access From',
        type: 'pie',
        avoidLabelOverlap: true, // 是否启用防止标签重叠策略
        radius: '50%',
        center: ['30%', '50%'], // 饼图的上下左右位置
        itemStyle: {
          // item之间的留白间隔
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 2
        },
        data: [
          // { value: 1048, name: 'Search Engine' },
          // { value: 735, name: 'Direct' },
          // { value: 580, name: 'Email' },
          // { value: 484, name: 'Union Ads' },
          // { value: 300, name: 'Video Ads' }
        ],
        label: {
          // 饼图图形上的文本标签
          show: false
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  },
  option7: {
    title: {
      text: '我的大区排名',
      // subtext: 'Fake Data',
      top: '0',
      left: '10%',
      textStyle: {
        fontSize: 18
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        const total = Number(defaultEcharts.option7.series[0].total);
        // console.log(total)
        // console.log("name", params.name)
        // console.log("value", params.value)
        return params.name + ' 第' + (total + 1 - params.value) + '名';
      }
    },
    xAxis: {
      type: 'category',
      data: [],
      axisTick: false,
      axisLabel: {
        formatter: function (params) {
          // params为x轴文字内容
          let newParamsName = '';
          const paramsNameNumber = params.length;
          const provideNumber = 4; // 一行显示几个字
          const rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
            for (let p = 0; p < rowNumber; p++) {
              let tempStr = '';
              const start = p * provideNumber;
              const end = start + provideNumber;
              if (p === rowNumber - 1) {
                tempStr = params.substring(start, paramsNameNumber);
              } else {
                tempStr = params.substring(start, end) + '\n';
              }
              newParamsName += tempStr;
            }
          } else {
            newParamsName = params;
          }
          return newParamsName;
        }
      }
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
      axisLabel: {
        formatter: function () {
          return '';
        }
      }
    },
    series: [
      {
        data: [], // 柱子高度
        type: 'bar',
        total: 0, // 总经销商数
        barWidth: '12',
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'top',
              formatter: function (params) {
                // 柱子上方的字
                // console.log("value:", params.value)
                const total = Number(defaultEcharts.option7.series[0].total);
                return 'NO.' + (total + 1 - params.value);
              },
              textStyle: {
                fontSize: '12'
                // color: "#8A1629"
              }
            },
            barBorderRadius: [7, 7, 0, 0]
          }
        }
      }
    ]
  },

  option8: {
    title: {
      text: '我的全国排名',
      // subtext: 'Fake Data',
      top: '0',
      left: '10%',
      textStyle: {
        fontSize: 18
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        const total = Number(defaultEcharts.option8.series[0].total);
        // console.log(total)
        // console.log("name", params.name)
        // console.log("value", params.value)
        return params.name + ' 第' + (total + 1 - params.value) + '名';
      }
    },
    xAxis: {
      type: 'category',
      data: [],
      axisTick: false,
      axisLabel: {
        formatter: function (params) {
          // params为x轴文字内容
          let newParamsName = '';
          const paramsNameNumber = params.length;
          const provideNumber = 4; // 一行显示几个字
          const rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
            for (let p = 0; p < rowNumber; p++) {
              let tempStr = '';
              const start = p * provideNumber;
              const end = start + provideNumber;
              if (p === rowNumber - 1) {
                tempStr = params.substring(start, paramsNameNumber);
              } else {
                tempStr = params.substring(start, end) + '\n';
              }
              newParamsName += tempStr;
            }
          } else {
            newParamsName = params;
          }
          return newParamsName;
        }
      }
    },
    yAxis: {
      type: 'value',
      minInterval: 1, // 整数
      axisLabel: {
        formatter: function () {
          return '';
        }
      }
    },
    series: [
      {
        data: [],
        type: 'bar',
        total: 0,
        barWidth: '12',
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'top',
              formatter: function (params) {
                // 柱子上方的字
                // console.log("value:", params.value)
                const total = Number(defaultEcharts.option8.series[0].total);
                return 'NO.' + (total + 1 - params.value);
              },
              textStyle: {
                fontSize: '12'
                // color: "#8A1629"
              }
            },
            barBorderRadius: [7, 7, 0, 0]
          }
        }
      }
    ]
  },
  option9: {
    title: {
      text: '经销商官方渗透率',
      // subtext: 'Fake Data',
      left: '10%',
      top: '0',
      textStyle: {
        fontSize: 18
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return params.name + ' ' + params.value + '台';
      }
    },
    legend: {
      // // 图例组件
      orient: 'vertical',
      top: 'center',
      right: '5',
      itemHeight: 12,
      itemWidth: 12,
      formatter: function (name) {
        // 自定义图例的文字
        let total = 0;
        let target;
        const data = defaultEcharts.option9.series[0].data;
        for (let i = 0, l = data.length; i < l; i++) {
          total += Number(data[i].value);
          if (data[i].name === name) {
            target = data[i].value;
          }
        }
        let percent = 0;
        if (total === 0) {
          percent = 0;
        } else {
          percent = ((target / total) * 100).toFixed(2);
        }
        return (
          '{a|' + name + '(' + target + '台)}'
        );
      },
      textStyle: {
        rich: {
          a: {
            width: 120,
            fontSize: 12,
            textAlign: 'center',
            color: '#999999'
          },
          b: {
            fontSize: 12,
            textAlign: 'center',
            color: '#333333'
          }
        }
      }
    },
    series: [
      {
        // name: 'Access From',
        type: 'pie',
        avoidLabelOverlap: true, // 是否启用防止标签重叠策略
        radius: ['25%', '40%'],
        center: ['25%', '45%'], // 饼图的上下左右位置
        itemStyle: {
          // item之间的留白间隔
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 2
        },
        data: [
          // { value: 1048, name: 'Search Engine' },
          // { value: 735, name: 'Direct' },
          // { value: 580, name: 'Email' },
          // { value: 484, name: 'Union Ads' },
          // { value: 300, name: 'Video Ads' }
        ],
        label: {
          // 饼图图形上的文本标签
          show: true,
          position: 'center',
          formatter: function () {
            let total = 0;
            let target;
            const data = defaultEcharts.option9.series[0].data;
            for (let i = 0, l = data.length; i < l; i++) {
              if (i === 0) {
                target = data[i].value;
              } else if (i === 1) {
                total = data[i].value;
              }
            }
            let percent = 0;
            if (total > 0) {
              percent = ((target / total) * 100).toFixed(2);
            }
            return '{a|官方渗透率\n}' + '{b|' + percent + '%}';
          },
          textStyle: {
            rich: {
              a: {
                fontSize: 12,
                textAlign: 'center',
                color: '#333333',
                lineHeight: 20
              },
              b: {
                fontSize: 12,
                textAlign: 'center',
                color: '#333333',
                fontWeight: 'bold'
              }
            }
          }
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
};
