/* eslint-disable multiline-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style/moreUpload.module.scss';
import { Toast } from 'antd-mobile';
import autobind from 'autobind-decorator';
import API from '@/api';
import { mountGlobalImagePopup, unMountGlobalImagePopup } from '@/components/imagePopup/RenderGlobalImagePopup';
import { AddImageCard, ImageCard } from '@/components';
import { changeFileToBlob } from '@/utils/image';
import { imageBaseUrl } from '@/api/http';
import DALCarImageState from '@/constants/valuation/carImageState';

class MoreUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // 上传图片
  @autobind
  onUpload(e) {
    const dealerId = window.sessionStorage.getItem('dealerId');
    if (e.target.files) {
      const _files = [...e.target.files];
      _files.forEach(async (fileItem, fileIndex) => {
        const _formData = new FormData();
        // _formData.append('file', fileItem);
        const _blob = await changeFileToBlob(fileItem);
        _formData.append('file', _blob, `${fileItem.name}`);
        _formData.append('type', _blob.type);
        _formData.append('size', _blob.size);
        _formData.append('dealerId', dealerId);
        const res = await API.CarSource.apiUploadFile(_formData);
        if (res.code === 200) {
          this.handleChangeImageList([...this.props.imageList, res.data]);
        } else {
          Toast.info('上传失败', 1);
        }
      });
    }
  }

  // 打开图片
  @autobind
  openImage(index, item) {
    const imageList = [];
    let currentIndex = 0;
    if (DALCarImageState.allPhotos && DALCarImageState.allPhotos.length > 0) {
      currentIndex = DALCarImageState.allPhotos.findIndex(el => el.url === item);
      DALCarImageState.allPhotos.map(val => {
        // imageList.push(imageBaseUrl + val);
        imageList.push({
          url: imageBaseUrl + val.url,
          code: val.code
        });
      });
    } else if (this.props.allPhotos && this.props.allPhotos.length > 0) {
      currentIndex = this.props.allPhotos.findIndex(el => el === item);
      this.props.allPhotos.map(item => {
        // imageList.push(imageBaseUrl + val);
        imageList.push({
          url: imageBaseUrl + item.url,
          code: this.props.code
        });
      });
    }
    mountGlobalImagePopup({
      imageList,
      currentIndex,
      onClose: () => {
        unMountGlobalImagePopup();
      }
    });
  }

  // 删除图片
  @autobind
  onRemoveImage(index) {
    const _list = [...this.props.imageList];
    _list.splice(index, 1);
    this.handleChangeImageList(_list);
  }

  @autobind
  handleChangeImageList(list = []) {
    if (list.length >= this.props.filesMaxCount) {
      list = list.slice(0, this.props.filesMaxCount);
    }
    this.props.changeImageList(list);
  }

  render() {
    return (
      <div className={`${styles.imageList} ${this.props.noMargin ? styles.noMargin : ''}`}>
        {this.props.imageList && this.props.imageList.length > 0 ? this.props.imageList.map((item, index) => (
          <ImageCard key={index} className={`${styles.imageItem} ${this.props.noMargin ? styles.noMarginItem : ''}`} imageUrl={item.url} onClickImage={() => this.openImage(index, item)} onClickRemove={() => { this.onRemoveImage(index); }} />
        )) : ''}
        {this.props.imageList.length < this.props.filesMaxCount && <AddImageCard className={styles.imageItem} onChange={this.onUpload} multiple={true} />}
      </div>
    );
  }
}

MoreUpload.propTypes = {
  // 图片集合
  imageList: PropTypes.array,
  // 文件最大数量
  filesMaxCount: PropTypes.number,
  // 修改图片列表
  changeImageList: PropTypes.func
};

MoreUpload.defaultProps = {
  imageList: [],
  filesMaxCount: 50,
  noMargin: false
};

export default MoreUpload;
