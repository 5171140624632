/*
 * @Descripttion:地区picker
 * @Author: zhongyuanrui
 * @Date: 2019-09-19 15:19:15
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-24 15:33:47
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LabelItem, PickerItem } from '@/components';
import autobind from 'autobind-decorator';
import API from '@/api';
import { changePickerFormat } from '@/utils/dataFormat';

class AreaPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: [],
      value: [],
      list: []
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.initData();
    }, 1000);
  }

  // 初始化数据
  @autobind
  async initData() {
    const res = await API.CarSource.getProvinceList();
    if (res.code === 200) {
      // console.log(res.data);
      const _list = [...res.data];
      // console.log(_list);
      this.setState({
        list: _list,
        value: this.props.defaultValue.length !== 0 ? this.props.defaultValue : [_list[0].value],
        label: this.props.defaultValue.length !== 0 ? this.props.defaultValue : [_list[0].label]
      }, () => {
        this.updateCityList();
      });
    }
  }

  // 更新城市列表
  @autobind
  async updateCityList() {
    if (this.props.cols < 2) {
      return;
    }
    const _value = [...this.state.value];
    console.log('_value+++++' + _value);
    const _label = [...this.state.label];
    const _list = this.state.list;
    // console.log(_value[0]);
    const res = await API.CarSource.getCityList({
      provinceId: _value[0]
    });
    if (res.code === 200) {
      _list.forEach(item => {
        if (_value[0] === item.value) {
          item.children = [...res.data];
          // item.children = changePickerFormat(res.data, 'cityName', 'id');
          // console.log('children0:' + item.children[0]);
          _value[1] = _value[1] || ((item.children && item.children.length > 0) ? item.children[0].value : '');
          _label[1] = _label[1] || ((item.children && item.children.length > 0) ? item.children[0].label : '');
          console.log('children0:' + _label[0]);
        }
      });
    }
    this.setState({
      list: _list,
      value: _value,
      label: _label
    }, () => {
      this.updateDistrictList();
    });
  }

  // 更新区域列表
  @autobind
  async updateDistrictList() {
    if (this.props.cols < 3) {
      return;
    }
    const _value = [...this.state.value];
    const _label = [...this.state.label];
    const _list = this.state.list;
    const res = await API.Common.apiSelectDistrict({
      cityId: _value[1]
    });
    if (res.code === 200) {
      _list.forEach(item => {
        if (_value[0] === item.value) {
          item.children.forEach(_item => {
            if (_value[1] === _item.value) {
              _item.children = changePickerFormat(res.data, 'districtName', 'id');
              _value[2] = _value[2] || _item.children[0].value;
              _label[2] = _label[2] || _item.children[0].label;
            }
          });
        }
      });
    }
    this.setState({
      list: _list,
      value: _value,
      label: _label
    });
  }

  // 每列数据选择变化后的回调函数
  @autobind
  onPickerChange(value) {
    if ((this.props.cols >= 3 && this.state.value[0] && this.state.value[1] && value[0] === this.state.value[0] && value[1] === this.state.value[1]) ||
      (this.props.cols >= 2 && this.props.cols < 3 && this.state.value[0] && value[0] === this.state.value[0] && this.state.value[1])) {
      this.setState({
        value
      });
    } else {
      this.setState({
        value
      }, () => {
        this.updateCityList();
      });
    }
    // console.log(value);
  }

  // 确认
  @autobind
  onOk() {
    this.props.onSelectArea(this.state.value);
  }

  render() {
    return (
      <LabelItem label={this.props.label} isRequisite={this.props.isRequisite} bottomBorderVisible={this.props.bottomBorderVisible}>
        <PickerItem pickerData={this.state.list} placeholder={this.props.placeholder}
          onPickerChange={this.onPickerChange} onOk={this.onOk} value={this.state.value} cols={this.props.cols} />
      </LabelItem>
    );
  }
}

AreaPicker.propTypes = {
  // cols列数, 1:只显示到省, 2:只显示到市, 3:只显示到区
  cols: PropTypes.number,
  // 标签
  label: PropTypes.string,
  // 占位符
  placeholder: PropTypes.string,
  // 选择的地区
  onSelectArea: PropTypes.func,
  // 初始默认值
  defaultValue: PropTypes.array,
  ondefaultValueChange: PropTypes.func,
  // 必填
  isRequisite: PropTypes.bool,
  bottomBorderVisible: PropTypes.bool
};
AreaPicker.defaultProps = {
  cols: 3,
  label: '',
  placeholder: '请选择地区',
  defaultValue: [],
  ondefaultValueChange: () => {
    this.initData();
  },
  isRequisite: false,
  bottomBorderVisible: true
};

export default AreaPicker;
