/*
* 车辆上架页面
*/
import React from 'react';
import { LabelItem, InputItem, Card, PickerItem, DatePickerItem, TopNavBar, Footer, Button } from '@/components';
import {
  mountGlobalLoading,
  unMountGlobalLoading
} from '@/components/loading/RenderGlobalLoading';
import { Toast } from 'antd-mobile';
import { Switch } from 'antd-mobile-v5';
import API from '@/api';
import DALCommon from '@/constants/common/state';
import { SaleStatusType } from '@/localData';
import styles from './style/CarOnShelve.module.scss';

class CarOnShelve extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicId: null,
      blocArr: '',
      blocName: '',
      contact: '',
      contactNum: '',
      purchasePirce: '',
      purchaseTotalPirce: '',
      salePrice: '',
      saleStatus: null,
      isCadillacOpen: false,
      isCarHomeOpen: false,
      isTianMaoOpen: false,
      isTikTokOpen: false,
      vin: ''
    };
  }

  componentDidMount() {
    // if (this.props.location.query && this.props.location.query.basicId) {
    // const basicId = this.props.location.query.basicId;
    // this.state.basicId = basicId;
    // console.log(basicId);
    // this.getData();
    // }
    this.state.basicId = DALCommon.basicId;
    this.getData();
  }

  getData() {
    API.CarSource.getCarOnShelve({
      basicId: this.state.basicId
    }).then(res => {
      if (res.code === 200 && res.data !== null) {
        //
        this.setState({
          blocArr: res.data.blocArr,
          blocName: res.data.blocName,
          contact: res.data.contact !== null ? res.data.contact : '',
          contactNum: res.data.contactNum !== null ? res.data.contactNum : '',
          purchasePirce: res.data.purchasePirce / 10000,
          purchaseTotalPirce: res.data.purchaseTotalPirce / 10000,
          salePrice: res.data.salePrice / 10000,
          saleStatus: res.data.saleStatus,
          isCadillacOpen: res.data.kdChannel === 1,
          isCarHomeOpen: res.data.ershouChannel === 1,
          isTianMaoOpen: res.data.tmailChannel === 1,
          isTikTokOpen: res.data.dyChannel === 1
        });
      } else {
        //
      }
    });
  }

  updateContactChange(value) {
    this.setState({
      contact: value
    });
  }

  updateContactNumChange(value) {
    this.setState({
      contactNum: value
    });
  }

  submitCarInfo = () => {
    if (!this.state.isCadillacOpen && !this.state.isCarHomeOpen && !this.state.isTianMaoOpen && !this.state.isTikTokOpen) {
      Toast.info('请至少选择一个展示渠道~');
      return;
    }
    if (!this.state.contact) {
      Toast.info('请填写联系人~');
      return;
    }
    if (!this.state.contactNum) {
      Toast.info('请填写联系人电话~');
      return;
    }
    if ((this.state.contactNum + '').length !== 11) {
      Toast.info('联系人电话必须为11位~');
      return;
    }
    mountGlobalLoading();
    // 提交
    const param = {
      basicId: this.state.basicId,
      contact: this.state.contact,
      contactNum: this.state.contactNum,
      kdChannel: this.state.isCadillacOpen ? 1 : 0,
      ershouChannel: this.state.isCarHomeOpen ? 1 : 0,
      tmailChannel: this.state.isTianMaoOpen ? 1 : 0,
      dyChannel: this.state.isTikTokOpen ? 1 : 0
    };
    API.CarSource.updateCarOnShelve(param).then(res => {
      unMountGlobalLoading();
      if (res.code === 200) {
        //
        Toast.info('提交成功');
        this.props.history.goBack();
      }
    });
  }

  render() {
    return (
      <div className={styles.CarOnShelve}>
        <TopNavBar title={'车辆上架'} />
        <div className={styles.cardWrap}>
          <Card>
            <div className={styles.title}>零售信息<span className={styles.secondTitle}></span></div>
            <LabelItem label="零售状态" isRequisite={true} bottomBorderVisible={true}>
              <InputItem value={SaleStatusType[this.state.saleStatus] ? SaleStatusType[this.state.saleStatus].label : ''} disabled={true} />
            </LabelItem>
            <LabelItem label="经销商名称" isRequisite={true} bottomBorderVisible={true}>
              <InputItem value={this.state.blocName} disabled={true} />
            </LabelItem>
            <LabelItem label="地址" isRequisite={true} bottomBorderVisible={true}>
              <InputItem value={this.state.blocArr} disabled={true} />
            </LabelItem>
            <LabelItem label="联系人" isRequisite={true} bottomBorderVisible={true}>
              <InputItem value={this.state.contact} placeholder="请输入联系人" onChange={(e) => this.updateContactChange(e.target.value)} />
            </LabelItem>
            <LabelItem label="联系电话" isRequisite={true} bottomBorderVisible={true}>
              <InputItem value={this.state.contactNum} placeholder="请输入联系人电话" onChange={(e) => this.updateContactNumChange(e.target.value)} maxLength={11} type='tel' />
            </LabelItem>
            <LabelItem label="入库总成本" isRequisite={true} bottomBorderVisible={true} handleContent="万元">
              <InputItem value={this.state.purchaseTotalPirce} disabled={true} />
            </LabelItem>
            <LabelItem label="收购价格" isRequisite={true} bottomBorderVisible={true} handleContent="万元">
              <InputItem value={this.state.purchasePirce} disabled={true} />
            </LabelItem>
            <LabelItem label="销售定价" isRequisite={true} bottomBorderVisible={false} handleContent="万元">
              <InputItem value={this.state.salePrice} disabled={true} />
            </LabelItem>
          </Card>
          <Card>
            <div className={styles.title}>展示渠道</div>
            <div className={styles.openBg}>
              <span className={styles.openTitle}>凯迪商城</span>
              <Switch
                checked={this.state.isCadillacOpen}
                className={styles.switchBg}
                checkedText='开'
                uncheckedText='关'
                onChange={(e) => {
                  this.setState({
                    isCadillacOpen: !this.state.isCadillacOpen
                  });
                }} />
            </div>
            <div className={styles.openBg}>
              <span className={styles.openTitle}>二手车之家</span>
              <Switch
                checked={this.state.isCarHomeOpen}
                className={styles.switchBg}
                checkedText='开'
                uncheckedText='关'
                onChange={(e) => {
                  this.setState({
                    isCarHomeOpen: !this.state.isCarHomeOpen
                  });
                }} />
            </div>
            <div className={styles.openBg}>
              <span className={styles.openTitle}>天猫商城</span>
              <Switch
                checked={this.state.isTianMaoOpen}
                className={styles.switchBg}
                checkedText='开'
                uncheckedText='关'
                onChange={(e) => {
                  this.setState({
                    isTianMaoOpen: !this.state.isTianMaoOpen
                  });
                }} />
            </div>
            <div className={styles.openLastBg}>
              <span className={styles.openTitle}>抖音商城</span>
              <Switch
                checked={this.state.isTikTokOpen}
                className={styles.switchBg}
                checkedText='开'
                uncheckedText='关'
                onChange={(e) => {
                  this.setState({
                    isTikTokOpen: !this.state.isTikTokOpen
                  });
                }} />
            </div>
          </Card>
          <Footer>
            <Button label="提交" type="primary" onClick={this.submitCarInfo} disabled={false} />
          </Footer>
        </div>
      </div>
    );
  }
}
export default CarOnShelve;