/*
 * @Descripttion:车辆配置 -变速箱
 * @Author: zhongyuanrui
 * @Date: 2019-09-17 11:40:07
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-23 18:16:02
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TitleCard } from '@/components';
import ConfigItem from '../configItem/ConfigItem';

function Gearbox(props) {
  const data = props.data;
  return (
    <TitleCard title="变速箱" showHeaderLine={false}>
      <ConfigItem label="变速箱类型" value={data && data.geartype ? data.geartype : ''} />
      <ConfigItem label="简称" value={data && data.gear ? data.gear : ''} />
      <ConfigItem label="挡位个数" value={data && data.gearnumber ? data.gearnumber : ''} />
      {/* <ConfigItem label="换档拨片" value={data.a} /> */}
    </TitleCard>
  );
}

Gearbox.propTypes = {
  data: PropTypes.object
};

export default Gearbox;
