/*
 * @Description: 图片弹窗
 * @Author: zhongyuanrui
 * @Date: 2019-09-18 23:20:18
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-10-24 18:54:55
 */
import React from 'react';
import ReactDOM from 'react-dom';
import NewImagePopup from './NewImagePopup';
// function mountGlobalImagePopup({ imageList, currentIndex }) {
//   if (!window.isOpenImagePopup) {
//     ReactDOM.render(<ImagePopup imageList={imageList} currentIndex={currentIndex}/>, document.getElementById('frame-image'));
//     window.isOpenImagePopup = true;
//   }
// }

// function unMountGlobalImagePopup() {
//   if (window.isOpenImagePopup) {
//     ReactDOM.unmountComponentAtNode(document.getElementById('frame-image'));
//     window.isOpenImagePopup = false;
//   }
// }

function mountGlobalImagePopup({ imageList, currentIndex, onClose }) {
  if (!window.isOpenImagePopup) {
    ReactDOM.render(<NewImagePopup urls={imageList} index={currentIndex} onClose={onClose}/>, document.getElementById('frame-image'));
    window.isOpenImagePopup = true;
  }
}

function unMountGlobalImagePopup() {
  if (window.isOpenImagePopup) {
    ReactDOM.unmountComponentAtNode(document.getElementById('frame-image'));
    window.isOpenImagePopup = false;
  }
}

export { mountGlobalImagePopup, unMountGlobalImagePopup };
