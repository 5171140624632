import React from 'react';
import styles from './style/cadillacRadioGroup.module.scss';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { withRouter } from 'react-router-dom';
import { data } from 'autoprefixer';
import iconSelect from '../../assets/image/icon_radio_selected.png';

@withRouter
@autobind
class CadillacRadioGroup extends React.PureComponent {
  @autobind
  onCarItemClick(index) {
    const isChecked = this.props.data[index].isChecked;
    if (!this.props.isMulti) {
      if (!isChecked) {
        this.props.onCheckedFun(index, false);
      }
    } else {
      this.props.onCheckedFun(index, true);
    }
  }

  render() {
    return (
      <div className={styles.topBg}>
        <div className={styles.title}>{this.props.title}</div>
        {this.props.data && <div className={styles.contentBg}>
          {this.props.data.map((item, index) => (
            <div className={styles.item} key={index}>
              <div className={`${styles.itemBg} ${item.label ? '' : styles.labelVisibel} ${item.isChecked ? styles.itemBgSelected : styles.itemBgUnselect}`} onClick={() => this.onCarItemClick(index)}>{item.label}</div>
              {item.isChecked && <img className={styles.imgBg} src={iconSelect} />}
            </div>
          ))}
        </div>}
      </div>
    );
  }
}

CadillacRadioGroup.propTypes = {
  // 标题
  title: PropTypes.string,
  // 选中事件
  onCheckedFun: PropTypes.func,
  // Radio列表
  data: PropTypes.array,
  // 是否多选
  isMulti: PropTypes.bool
};
CadillacRadioGroup.defaultProps = {
  title: '',
  data: [],
  isMulti: false
};

export default CadillacRadioGroup;
