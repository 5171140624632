import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NewLabelItem, NewTitleCard, NoData } from '@/components';
import { OrderStatus } from '@/localData';
import { formatThousands } from '@/utils/dataFormat';
// import { purchaseBusinessTypeList, paymentTypeList, purchaseStatus } from '@/localData';
// import { getItemByValue, formatThousands } from '@/utils/dataFormat';

function orderInfo(props) {
  const baseInfo = props.baseInfo;
  return (
    <NewTitleCard title="订单信息">
      <NewLabelItem label="成交状态" handleContent={<div>{(baseInfo && baseInfo.orderStatus !== null && OrderStatus[baseInfo.orderStatus]) ? OrderStatus[baseInfo.orderStatus].label : ''}</div>} />
      <NewLabelItem label="成交金额" handleContent={<div>{(baseInfo && baseInfo.realPrice !== null) ? formatThousands(baseInfo.realPrice) + ' 元' : ''}</div>} />
    </NewTitleCard>
  );
}
orderInfo.propTypes = {
  baseInfo: PropTypes.object
};
export default withRouter(orderInfo);
