/*
 * @Descripttion: Card
 * @Author: zhongyuanrui
 * @Date: 2019-05-21 14:45:29
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-16 17:57:10
 */
import React from 'react';
import styles from './style/card.module.scss';
import PropTypes from 'prop-types';

function Card(props) {
  return (
    <div
      className={`
          ${styles.card}
          ${props.showShadow ? styles.boxShadow : ''}
          ${props.isBorderRadius ? styles.borderRadius : ''}
          ${props.className}
        `}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

Card.propTypes = {
  // 点击事件
  onClick: PropTypes.func,
  // 最外层className
  className: PropTypes.string,
  // 是否显示阴影
  showShadow: PropTypes.bool,
  // 是否圆角边框
  isBorderRadius: PropTypes.bool
};

Card.defaultProps = {
  className: '',
  showShadow: false,
  isBorderRadius: true
};

export default Card;