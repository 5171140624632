/* eslint-disable no-return-assign */
/* eslint-disable multiline-ternary */
import React from 'react';
import { Dropdown, Selector, Popup, Input } from 'antd-mobile-v5';
import { ListView, Toast, Badge } from 'antd-mobile';
import { LabelItem, InputItem, Card, PickerItem, DatePickerItem, TopNavBar, Footer, Button, CadillacRadioGroup } from '@/components';
import {
  nativeApi
} from '@/jsBridge';
import { compareSimpleArray } from '@/utils/date';
import API from '@/api';
import DALCommon from '@/constants/common/state';
import addImg from '@/assets/image/icon_add_menu.png';
import moreImg from '@/assets/image/icon_more.png';
import msgImg from '@/assets/image/icon_xiaoxi.png';
import styles from './style/CarList.module.scss';
import noListImg from '@/assets/image/noList.png';
import iconDown from '@/assets/image/icon_arrow_down.png';
import iconUp from '@/assets/image/icon_arrow_up.png';
import iconCar from '@/assets/image/icon_car_bg.png';
import iconSelect from '@/assets/image/icon_select.png';
import { imageBaseUrl } from '@/api/http';
import { AuthType, CarType, CarSourceType, SaleStatusType, SaleStatus } from '@/localData';

class CarList extends React.Component {
  constructor(props) {
    super(props);
    this.drop = React.createRef();
    this.state = {
      visible: false,
      ableFilterClick: true,
      isLoading: false,
      hasMore: false,
      page: 1,
      pageSize: 10,
      basicId: '',
      familyId: null,
      vin: '', // 筛选的车架号
      plateNumber: '', // 筛选的车牌号
      kdChannel: 0,
      ershouChannel: 0,
      tmailChannel: 0,
      dyChannel: 0,
      isShowMoreCon: false,

      // 逻辑控制字段
      isVehiclePopupVisible: false,
      isMorePopupVisible: false,
      isFrom: props.location.query ? props.location.query.isFrom : undefined,
      vehicleList: [
        // {
        // name: 'CT4',
        // id: '1',
        // isChecked: true
        // }
      ],
      // 车源筛选表
      sourceList: [
        {
          label: '全部类型',
          value: null,
          isChecked: true
        },
        {
          label: '厂家下发',
          value: 0,
          isChecked: false
        },
        {
          label: '车源自采',
          value: 1,
          isChecked: false
        }
      ],
      // 车源等级 0-优选一年车 1-精品车
      carTypeList: [
        {
          label: '全部类型',
          value: null,
          isChecked: true
        },
        {
          label: '未确认',
          value: -1,
          isChecked: false
        },
        {
          label: '优选车',
          value: 0,
          isChecked: false
        },
        {
          label: '精品车',
          value: 1,
          isChecked: false
        }
      ],
      // 认证类型 -1 未认证 0-安吉星认证 1-诚新认证
      authTypeList: [
        {
          label: '全部类型',
          value: null,
          isChecked: true
        },
        {
          label: '未认证',
          value: -1,
          isChecked: false
        },
        {
          label: '安吉星认证',
          value: 0,
          isChecked: false
        },
        {
          label: '诚新认证',
          value: 1,
          isChecked: false
        }
      ],
      // 申请状态 申请状态 -1 全部 0-待申请 1-已申请 2-申请通过 3-申请驳回
      applicationTypeList: [
        {
          label: '全部',
          value: null,
          isChecked: true
        },
        {
          label: '待申请',
          value: -1,
          isChecked: false
        },
        {
          label: '已申请',
          value: 0,
          isChecked: false
        },
        {
          label: '申请通过',
          value: 1,
          isChecked: false
        },
        {
          label: '申请驳回',
          value: 2,
          isChecked: false
        }
      ],
      // 上下架状态(零售状态) -1 全部 0-待上架 1-已上架 2-已下架
      saleStatusList: [
        {
          label: '全部',
          value: null,
          isChecked: true
        },
        {
          label: '待上架',
          value: 0,
          isChecked: false
        },
        {
          label: '已上架',
          value: 1,
          isChecked: false
        },
        {
          label: '已下架',
          value: 2,
          isChecked: false
        }
      ],
      // 上架渠道（多选）
      channelList: [
        {
          label: '凯迪商城',
          isChecked: false
        },
        {
          label: '二手车商城',
          isChecked: false
        },
        {
          label: '天猫商城',
          isChecked: false
        },
        {
          label: '抖音商城',
          isChecked: false
        }
      ],
      // 转换后的list
      list: new ListView.DataSource({
        rowHasChanged: (row1, row2) => {
          return row1 !== row2;
        }
      }),
      list1: [], // 接口获取的
      moreFilterOrginalList: [0, 0, 0, 0, 0, 0, 0, 0, 0, '', ''], // 前0-4 5个单选的选中index 中间5-8 4个多选框对应的check状态（1true）9-10两个输入框的值
      moreFilterList: [0, 0, 0, 0, 0, 0, 0, 0, 0, '', '']
    };
  }

  componentDidMount() {
    // if (this.props.location.query && this.props.location.query.blocNum) {
    // const blocNum = this.props.location.query.blocNum;
    // console.log(blocNum);
    // DALCommon.blocNum = blocNum;
    // }
    this.getData();
    this.getVehicleList();
    console.log(this.props.location.query);
    // this.setState({
    // list: this.state.list.cloneWithRows({ ...this.state.carList })
    // }, () => { console.log(this.state.list); });
  }

  getVehicleList = () => {
    API.CarSource.getFamilyList().then(res => {
      if (res.code === 200) {
        let list = res.data;
        list = [{ name: '全部车系', id: null }, ...list];
        if (list) {
          list.map((item, index) => {
            item.isChecked = index === 0;
          });
          this.setState({
            vehicleList: [...list]
          });
        }
      }
    });
  }

  getData() {
    //
    if (DALCommon.blocNum === null) {
      Toast.info('经销商信息为空');
      return;
    }
    const familyId = this.state.familyId;
    const indexList = this.state.moreFilterOrginalList;
    const carSource = this.state.sourceList[indexList[0]].value;
    const carType = this.state.carTypeList[indexList[1]].value;
    const authType = this.state.authTypeList[indexList[2]].value;
    const applicationType = this.state.applicationTypeList[indexList[3]].value;
    const saleStatus = this.state.saleStatusList[indexList[4]].value;
    const kdChannel = this.state.channelList[0].isChecked ? 1 : 0;
    const ershouChannel = this.state.channelList[1].isChecked ? 1 : 0;
    const tmailChannel = this.state.channelList[2].isChecked ? 1 : 0;
    const dyChannel = this.state.channelList[3].isChecked ? 1 : 0;
    const params = {
      blocNum: DALCommon.blocNum,
      page: this.state.page,
      pageSize: this.state.pageSize,
      plateNumber: this.state.plateNumber,
      vin: this.state.vin,
      familyId: familyId,
      carSource: carSource,
      carType: carType,
      authType: authType,
      applicationType: applicationType,
      saleStatus: saleStatus,
      kdChannel: kdChannel === 0 ? null : kdChannel,
      ershouChannel: ershouChannel === 0 ? null : ershouChannel,
      tmailChannel: tmailChannel === 0 ? null : tmailChannel,
      dyChannel: dyChannel === 0 ? null : dyChannel
    };
    API.CarSource.getCarList(
      params
    ).then(res => {
      if (res.code === 200) {
        //
        if (this.state.page !== 1) {
          this.state.list1 = [...this.state.list1, ...res.data.list];
        } else {
          this.state.list1 = [...res.data.list];
        }
        const list = this.state.list.cloneWithRows({ ...this.state.list1 });
        this.setState({
          list: list,
          isLoading: false,
          hasMore: res.data.hasNextPage
        });
      }
    });
  }

  // 加载更多
  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (!this.state.isLoading && !this.state.hasMore) {
      Toast.info('没有更多数据了');
      return;
    }
    console.log('reach end', event);
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.state.page++;
      this.getData();
    }, 1000);
  }

  // 列表点击事件
  onCarItemClick(id) {
    if (id !== null) {
      window.sessionStorage.setItem('basicId', id);
      DALCommon.basicId = id;
      this.props.history.push({
        pathname: '/carDetail',
        query: {
          isFrom: 'h5'
        }
      });
    } else {
      Toast.info('车辆信息id不存在');
    }
  }

  // 车系筛选item点击事件
  vehicleSelect(index) {
    if (this.state.vehicleList[index].isChecked) {
      return;
    }
    this.state.vehicleList.map((item, index1) => {
      item.isChecked = index === index1;
    });
    const a = this.state.vehicleList[index].id;
    this.setState({
      vehicleList: this.state.vehicleList,
      isVehiclePopupVisible: false,
      familyId: a,
      page: 1
    }, () => {
      this.getData();
    });
  }

  // vin input事件
  updateVinChange(e, type) {
    this.state.moreFilterList[9] = e.target.value;
    this.setState({
      [type]: e.target.value
    });
  }

  // PlateNumber筛选框 input事件
  updatePlateNumberChange(e, type) {
    this.state.moreFilterList[10] = e.target.value;
    this.setState({
      [type]: e.target.value
    });
  }

  /*
  * 筛选框选中事件
  * @param index
  * @param isMulti true 多选
  * @param filterIndex
  */
  updateRadioChange(index, isMulti, filterIndex, type) {
    //
    console.log('radio选中事件');
    if (!isMulti) {
      // 单选
      this.state[type][this.state.moreFilterList[filterIndex]].isChecked = false;
      this.state[type][index].isChecked = true;
      this.state.moreFilterList[filterIndex] = index;
      this.setState({
        [type]: this.state[type]
      });
    } else {
      // 多选
      if (!this.state.channelList[index].isChecked) {
        // 选中
        this.state.moreFilterList[index + 5] = 1;
      } else {
        // 未选中
        this.state.moreFilterList[index + 5] = 0;
        // const select_index = this.state.moreFilterList[5].indexOf(index);
        // this.state.moreFilterList[5].splice(select_index, select_index + 1);
      }
      this.state.channelList[index].isChecked = !this.state.channelList[index].isChecked;
      this.setState({
        channelList: this.state.channelList
      });
    }
    // console.log(this.state[type]);
    // console.log(this.state.moreFilterList);
    // console.log(this.state.moreFilterOrginalList);
  }

  // 筛选框展开叠起事件
  onMoreClick = key => (e) => {
    const isShow = this.state.isMorePopupVisible;
    const moreFilterOrginalList = this.state.moreFilterOrginalList;
    if (!isShow) {
      this.setState({
        isVehiclePopupVisible: false
      });
    }
    // 关闭更多筛选弹窗时
    if (isShow) {
      this.state.sourceList.map((item, index) => {
        item.isChecked = index === moreFilterOrginalList[0];
      });
      this.state.carTypeList.map((item, index) => {
        item.isChecked = index === moreFilterOrginalList[1];
      });
      this.state.authTypeList.map((item, index) => {
        item.isChecked = index === moreFilterOrginalList[2];
      });
      this.state.applicationTypeList.map((item, index) => {
        item.isChecked = index === moreFilterOrginalList[3];
      });
      this.state.saleStatusList.map((item, index) => {
        item.isChecked = index === moreFilterOrginalList[4];
      });
      this.state.channelList.map((item, index) => {
        item.isChecked = moreFilterOrginalList[index + 5] === 1;
      });
      this.setState({
        sourceList: this.state.sourceList,
        carTypeList: this.state.carTypeList,
        authTypeList: this.state.authTypeList,
        applicationTypeList: this.state.applicationTypeList,
        saleStatusList: this.state.saleStatusList,
        channelList: this.state.channelList,
        vin: this.state.moreFilterOrginalList[9],
        plateNumber: this.state.moreFilterOrginalList[10],
        moreFilterList: [...moreFilterOrginalList]
      });
    }
    // console.log(this.state.channelList);
    // console.log(this.state.moreFilterList);
    // console.log(this.state.moreFilterOrginalList);
    this.setState({
      [key]: !isShow
    });
  }

  setMorePopupVisible() {
    const moreFilterOrginalList = this.state.moreFilterOrginalList;
    this.state.sourceList.map((item, index) => {
      item.isChecked = index === moreFilterOrginalList[0];
    });
    this.state.carTypeList.map((item, index) => {
      item.isChecked = index === moreFilterOrginalList[1];
    });
    this.state.authTypeList.map((item, index) => {
      item.isChecked = index === moreFilterOrginalList[2];
    });
    this.state.applicationTypeList.map((item, index) => {
      item.isChecked = index === moreFilterOrginalList[3];
    });
    this.state.saleStatusList.map((item, index) => {
      item.isChecked = index === moreFilterOrginalList[4];
    });
    this.state.channelList.map((item, index) => {
      item.isChecked = moreFilterOrginalList[index + 5] === 1;
    });
    this.setState({
      sourceList: this.state.sourceList,
      carTypeList: this.state.carTypeList,
      authTypeList: this.state.authTypeList,
      applicationTypeList: this.state.applicationTypeList,
      saleStatusList: this.state.saleStatusList,
      channelList: this.state.channelList,
      vin: this.state.moreFilterOrginalList[9],
      plateNumber: this.state.moreFilterOrginalList[10],
      moreFilterList: [...moreFilterOrginalList]
    });
    this.setState({
      isMorePopupVisible: false
    });
  }

  // 蒙层点击
  onVehicleMaskClick() {
    this.setState({
      isVehiclePopupVisible: false
    });
  }

  // 车系按钮点击事件
  onVehicleClick = key => (shouldVisible) => {
    const isShow = this.state.isVehiclePopupVisible;
    const isMoreShow = this.state.isMorePopupVisible;
    const moreFilterOrginalList = this.state.moreFilterOrginalList;
    if (isMoreShow) {
      this.state.sourceList.map((item, index) => {
        item.isChecked = index === moreFilterOrginalList[0];
      });
      this.state.carTypeList.map((item, index) => {
        item.isChecked = index === moreFilterOrginalList[1];
      });
      this.state.authTypeList.map((item, index) => {
        item.isChecked = index === moreFilterOrginalList[2];
      });
      this.state.applicationTypeList.map((item, index) => {
        item.isChecked = index === moreFilterOrginalList[3];
      });
      this.state.saleStatusList.map((item, index) => {
        item.isChecked = index === moreFilterOrginalList[4];
      });
      this.state.channelList.map((item, index) => {
        item.isChecked = moreFilterOrginalList[index + 5] === 1;
      });
      this.setState({
        sourceList: this.state.sourceList,
        carTypeList: this.state.carTypeList,
        authTypeList: this.state.authTypeList,
        applicationTypeList: this.state.applicationTypeList,
        saleStatusList: this.state.saleStatusList,
        channelList: this.state.channelList,
        vin: this.state.moreFilterOrginalList[9],
        plateNumber: this.state.moreFilterOrginalList[10],
        moreFilterList: [...moreFilterOrginalList]
      });
    }
    // console.log(this.state.channelList);
    // console.log(this.state.moreFilterList);
    // console.log(this.state.moreFilterOrginalList);
    if (!isShow) {
      this.setState({
        isMorePopupVisible: false
      });
    }
    this.setState({
      isVehiclePopupVisible: !isShow
    });
  }

  onBottomLeftClick() {
    // 重置
    const { sourceList, carTypeList, authTypeList, applicationTypeList, saleStatusList, channelList } = this.state;
    sourceList.map((item, index) => {
      item.isChecked = index === 0;
    });
    carTypeList.map((item, index) => {
      item.isChecked = index === 0;
    });
    authTypeList.map((item, index) => {
      item.isChecked = index === 0;
    });
    applicationTypeList.map((item, index) => {
      item.isChecked = index === 0;
    });
    saleStatusList.map((item, index) => {
      item.isChecked = index === 0;
    });
    channelList.map((item, index) => {
      item.isChecked = false;
    });
    this.setState({
      sourceList: this.state.sourceList,
      carTypeList: this.state.carTypeList,
      authTypeList: this.state.authTypeList,
      applicationTypeList: this.state.applicationTypeList,
      saleStatusList: this.state.saleStatusList,
      channelList: this.state.channelList,
      isMorePopupVisible: false,
      vin: '',
      plateNumber: '',
      moreFilterOrginalList: [0, 0, 0, 0, 0, 0, 0, 0, 0, '', ''],
      moreFilterList: [0, 0, 0, 0, 0, 0, 0, 0, 0, '', ''],
      page: 1,
      list1: [],
      list: new ListView.DataSource({
        rowHasChanged: (row1, row2) => {
          return row1 !== row2;
        }
      })
    }, () => {
      console.log(sourceList);
      this.getData();
    });
    console.log('重置');
  }

  onBottomRightClick() {
    // 提交
    console.log('提交事件事件');
    const moreFilterList = this.state.moreFilterList;
    this.state.moreFilterOrginalList = [...moreFilterList];
    this.setState({
      isMorePopupVisible: false,
      page: 1,
      list1: [],
      list: new ListView.DataSource({
        rowHasChanged: (row1, row2) => {
          console.log('row1', row1);
          console.log('row2', row2);
          return row1 !== row2;
        }
      })
    }, () => {
      this.getData();
    });
  }

  // 路由地址
  routeJump(pathname) {
    this.props.history.push({
      pathname,
      query: {
        isFrom: 'h5'
      }
    });
  }

  showMore = () => {
    this.setState({
      isShowMoreCon: !this.state.isShowMoreCon
    });
  }

  // 添加车源
  addMore() {
    if (DALCommon.blocNum !== null) {
      this.routeJump('/carAdd');
    } else {
      Toast.info('经销商信息为空');
    }
  }

  // 经销商看板
  onDealerEcharts() {
    if (DALCommon.blocNum !== null) {
      this.routeJump('/dealerEcharts');
    } else {
      Toast.info('经销商信息为空');
    }
  }

  // 消息列表
  showMsgList() {
    if (DALCommon.blocNum !== null) {
      this.routeJump('/carMsgList');
    } else {
      Toast.info('经销商信息为空');
    }
  }

  // 右上角操作按钮
  renderRightHandle() {
    return (
      <div className={styles.topLeft}>
        <div className={styles.detailMore} onClick={this.showMore}>
          <img src={moreImg} />
        </div>
        {this.state.isShowMoreCon && <div className={styles.moreContent}>
          {<span onClick={() => this.onDealerEcharts()}>经销商看板</span>}
        </div>}
      </div>
    );
  }

  render() {
    const {
      list, isVehiclePopupVisible, isMorePopupVisible, vehicleList, sourceList, carTypeList, authTypeList, applicationTypeList, saleStatusList,
      channelList, list1
    } = this.state;

    const row = (item, sectionID, rowID) => {
      let labels = '';
      if (item.carSource !== null && CarSourceType[item.carSource]) {
        labels = CarSourceType[item.carSource].label;
      }
      if (item.carType !== null && CarType[item.carType + 1]) {
        if (labels !== '') {
          labels += '/' + CarType[item.carType + 1].label;
        } else {
          labels = CarType[item.carType + 1].label;
        }
      }
      if (item.authType !== null && AuthType[item.authType + 1]) {
        if (labels !== '') {
          labels += '/' + AuthType[item.authType + 1].label;
        } else {
          labels = AuthType[item.authType + 1].label;
        }
      }
      let channels = '';
      if (item.kdChannel !== null && item.kdChannel === 1) {
        channels = '凯迪商城';
      }
      if (item.ershouChannel !== null && item.ershouChannel === 1) {
        if (channels !== '') {
          channels += '|二手车之家';
        } else {
          channels = '二手车之家';
        }
      }
      if (item.tmailChannel !== null && item.tmailChannel === 1) {
        if (channels !== '') {
          channels += '|天猫商城';
        } else {
          channels = '天猫商城';
        }
      }
      if (item.dyChannel !== null && item.dyChannel === 1) {
        if (channels !== '') {
          channels += '|抖音商城';
        } else {
          channels = '抖音商城';
        }
      }
      const photos = item.photos;
      let url = '';
      if (photos && photos.length > 0) {
        if (photos[0].photoUrl) {
          url = photos[0].photoUrl;
        }
      }
      return (
        <div className={`${styles.listViewBg}`} key={rowID} onClick={() => this.onCarItemClick(item.id)}>
          <div className={styles.leftContent}>
            <img className={styles.carImg} src={url !== '' ? (imageBaseUrl + url) : iconCar} />
            {/* <img className={styles.carNumberImg} src={item.carNumberImage}/> */}
            {/* <span className={styles.carNumber}>{item.plateNumber}</span> */}
          </div>
          <div className={styles.rightContent}>
            <span className={styles.title}>{item.vehicleName}</span>
            <div className={styles.contentBg}>
              {/* <span className={styles.content}>{item.carSource !== null && CarSourceType[item.carSource] ? CarSourceType[item.carSource].label : ''}</span> */}
              {/* <span className={styles.content}>{(item.carType !== null && CarType[item.carType + 1]) ? CarType[item.carType + 1].label : ''}</span> */}
              {/* <span className={styles.content}>{(item.authType !== null && AuthType[item.authType + 1]) ? AuthType[item.authType + 1].label : ''}</span> */}
              <span className={styles.content}>{labels}</span>
              {item.status !== null && <span className={styles.saleContentBg}>/{SaleStatus[item.status + 1].label}</span>}
            </div>
            {channels !== '' && <div className={styles.mallBg}>{channels}</div>}
            <span className={`${styles.onSale} ${(item.saleStatus !== null && item.saleStatus === 1) ? styles.isSale : styles.notSale}`}>{(item.saleStatus !== null && SaleStatusType[item.saleStatus]) ? SaleStatusType[item.saleStatus].label : ''}</span>
          </div>
        </div>
      );
    };

    return (
      <div className={styles.CarList}>
        <TopNavBar title={'车源管理'} rightHandle={this.renderRightHandle()} />
        <div className={styles.topSelect}>
          <div className={`${styles.topSelectLeft} ${isVehiclePopupVisible ? styles.topSelectHighLight : ''}`} onClick={this.onVehicleClick(!this.state.isVehiclePopupVisible)}>
            <span className={`${styles.selectText} ${isVehiclePopupVisible ? styles.selectTextWhite : ''}`}>车系</span>
            <img style={{ width: '14px', height: '8px', marginLeft: '5px' }} src={isVehiclePopupVisible ? iconUp : iconDown} />
          </div>
          <div className={`${styles.topSelectRight} ${isMorePopupVisible ? styles.topSelectHighLight : ''}`} onClick={this.onMoreClick('isMorePopupVisible')}>
            <span className={`${styles.selectText} ${isMorePopupVisible ? styles.selectTextWhite : ''}`}>筛选</span>
            <img style={{ width: '14px', height: '8px', marginLeft: '5px' }} src={isMorePopupVisible ? iconUp : iconDown} />
          </div>
        </div>
        <div id='bottomCarView' className='bottomCarView'>
          {list1 && list1.length > 0 ? <ListView
            dataSource={list}
            renderRow={row}
            renderBodyComponent={() => <div style={{ backgroundColor: '#F5F5F5' }} />}
            useBodyScroll
            ref={el => this.lv = el}
            onEndReached={this.onEndReached}
            onEndReachedThreshold={10}
          /> : <div className={styles.noImgParentBg}>
            <img className={styles.noImg} src={noListImg} />
          </div>}
        </div>
        <Popup
          className={`${styles.popupHeader} ${this.state.isVehiclePopupVisible ? styles.popupVisible : styles.popupNone}`}
          bodyClassName={`${styles.popup} ${this.state.isVehiclePopupVisible ? styles.popupVisible : styles.popupNone}`}
          maskClassName={`${styles.maskPopup} ${this.state.isVehiclePopupVisible ? styles.popupVisible : styles.popupNone}`}
          visible={this.state.isVehiclePopupVisible}
          onMaskClick={() => {
            this.onVehicleMaskClick();
          }}
          position='top'
        >
          {vehicleList.map((item, index) => (
            <div className={`${styles.vehiclePopupBg} ${item.isChecked ? styles.vehiclePopupBgSelect : ''}`} key={index} onClick={() => this.vehicleSelect(index)}>
              <span className={styles.vehicleNameBg}>{item.name}</span>
              {item.isChecked && <img style={{ width: '15px', height: '10px', marginRight: '10px' }} src={iconSelect} />}
            </div>
          ))}
        </Popup>
        <Popup
          className={`${styles.popupHeader} ${this.state.isMorePopupVisible ? styles.popupVisible : styles.popupNone}`}
          bodyClassName={`${styles.popup} ${this.state.isMorePopupVisible ? styles.popupVisible : styles.popupNone}`}
          maskClassName={`${styles.maskPopup} ${this.state.isMorePopupVisible ? styles.popupVisible : styles.popupNone}`}
          visible={this.state.isMorePopupVisible}
          onMaskClick={() => {
            this.setMorePopupVisible();
          }}
          position='top'
        >
          <div className={styles.popupTitleBg}>车架号查询</div>
          <InputItem className={styles.vinInputBg} placeholder="请输入车架号模糊查询" value={this.state.vin} onChange={(e) => this.updateVinChange(e, 'vin')} />
          <div className={styles.popupTitleBg}>车牌查询</div>
          <InputItem className={styles.vinInputBg} placeholder="请输入车牌号模糊查询" value={this.state.plateNumber} onChange={(e) => this.updatePlateNumberChange(e, 'plateNumber')} />
          <CadillacRadioGroup title='车辆来源' data={sourceList} onCheckedFun={(index, isSingle) => this.updateRadioChange(index, isSingle, 0, 'sourceList')} />
          <CadillacRadioGroup title='车源等级' data={carTypeList} onCheckedFun={(index, isSingle) => this.updateRadioChange(index, isSingle, 1, 'carTypeList')} />
          <CadillacRadioGroup title='认证类型' data={authTypeList} onCheckedFun={(index, isSingle) => this.updateRadioChange(index, isSingle, 2, 'authTypeList')} />
          <CadillacRadioGroup title='申请状态' data={applicationTypeList} onCheckedFun={(index, isSingle) => this.updateRadioChange(index, isSingle, 3, 'applicationTypeList')} />
          <CadillacRadioGroup title='上下架状态' data={saleStatusList} onCheckedFun={(index, isSingle) => this.updateRadioChange(index, isSingle, 4, 'saleStatusList')} />
          <CadillacRadioGroup title='上架渠道' data={channelList} onCheckedFun={(index, isSingle) => this.updateRadioChange(index, isSingle, 5, 'channelList')} isMulti={true} />
          <div className={styles.bottomButtonBg}>
            <div className={styles.bottomButtonLeft} onClick={this.onBottomLeftClick.bind(this)}>重置</div>
            <div className={styles.bottomButtonRight} onClick={this.onBottomRightClick.bind(this)}>完成</div>
          </div>
          <div style={{ width: '100%', height: '20px' }} />
        </Popup>
      </div>
    );
  }
}

export default CarList;