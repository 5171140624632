/*
 * @Descripttion: 数据格式的转换
 * @version:
 * @Author: zhongyuanrui
 * @Date: 2019-05-16 15:48:49
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-10-23 15:14:39
 */
import {
  mountGlobalLoading,
  unMountGlobalLoading
} from '@/components/loading/RenderGlobalLoading';

// 转成picker格式
export function changePickerFormat(arr, labelKey, valueKey) {
  if (!Array.isArray(arr)) {
    return arr;
  }
  return [...arr.map(item => {
    item.label = item[labelKey];
    item.value = item[valueKey];
    return item;
  })];
}

// 数组中找到对象根据属性值
export function getItemByValue(arr, value, key = 'value') {
  if (!Array.isArray(arr)) {
    return;
  }
  let _item = {};
  arr.forEach(item => {
    if (item[key] === value) {
      _item = item;
    }
  });
  return _item;
}

// 数组中找到对象根据属性值根据递归
export function recursiveItemByValue(arr, valueList, key = 'value', maxNumber = 3, currentNumber = 0, itemList = []) {
  if (!Array.isArray(arr)) {
    return arr;
  }
  if (arr.length <= 0) {
    return arr;
  }

  const _arr = itemList;
  let _item = {};
  let _currentNumber = currentNumber;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === valueList[_currentNumber]) {
      _item = arr[i];
      _currentNumber++;
      break;
    }
  }
  _arr.push(_item);
  // console.log('_currentNumber', _currentNumber);
  // console.log('maxNumber', maxNumber);
  // console.log('_arr', _arr);

  if (_currentNumber >= maxNumber && !_arr[_currentNumber - 1].children) {
    return _arr;
  } else {
    return recursiveItemByValue(_arr[_currentNumber - 1].children, valueList, key, maxNumber, _currentNumber, _arr);
  }
}
// 拼接字符串
export function spliceStringByFormat(arr, key = 'label', format = '/') {
  if (!Array.isArray(arr)) {
    return '';
  }
  if (arr.length <= 0) {
    return '';
  }
  let _string = '';
  arr.forEach((item, index) => {
    // console.log('item.key' + item[key]);
    if (index === arr.length - 1) {
      _string += `${item && item[key] ? item[key] : ''}`;
    } else {
      _string += `${item[key]}/`;
    }
  });
  return _string;
}

/**
 * @description 根据number创建1到number的数组
 * @export getArrByNumber
 * @param {Number} number
 * @returns {Array} arr
 */
export function getArrByNumber(number) {
  if (typeof number !== 'number') {
    return [];
  }
  const _arr = Array.from(Array(number), (v, k) => k + 1);
  return _arr;
}

// 把文件流格式的图片转换成Blob（暂时限定为图片类型）
export function changeFileToBlob(file) {
  mountGlobalLoading();
  let src;
  const fileSize = parseFloat(parseInt(file.size) / 1024 / 1024).toFixed(2);
  const read = new FileReader();
  return new Promise(function (resolve, reject) {
    read.readAsDataURL(file);
    read.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        // 默认按比例压缩
        const w = this.width;
        const h = this.height;
        // 生成canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let base64;
        // 创建属性节点
        canvas.setAttribute('width', w);
        canvas.setAttribute('height', h);
        ctx.drawImage(this, 0, 0, w, h);
        // 进行图片压缩，小于1M不压缩原图上传；1-4M轻量压缩(0.7)，提高上传时间；大于4M尽量控制再4M以内，保证识别率
        if (fileSize <= 1) {
          base64 = canvas.toDataURL(file.type);
        } else if (fileSize > 1 && fileSize <= 4) {
          base64 = canvas.toDataURL(file.type, 0.7);
        } else {
          base64 = canvas.toDataURL(file.type, ((4 / fileSize) - 0.1));
        }
        // 这里对base64串进行操作，去掉url头，并转换为byte
        const bytes = window.atob(base64.split(',')[1]);
        const array = [];
        for (let i = 0; i < bytes.length; i++) {
          array.push(bytes.charCodeAt(i));
        }
        const blob = new Blob([new Uint8Array(array)], {
          type: file.type
        });
        unMountGlobalLoading();
        resolve(blob);
      };
    };
  });
};

/**
 * @description 判断数据类型
 * @export checkDataType
 * @param {*} data
 * @returns {String} 类型值
 */
export function checkDataType(data) {
  return Object.prototype.toString.call(data).split('[')[1].split(']')[0].split(' ')[1];
}

/**
 * @description 获取连接'?'后面的参数
 * @export getQueryString
 * @param {String} name
 * @returns {String} value
 */
export function getQueryString(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = decodeURIComponent(window.location.search).substr(1).match(reg);
  if (r !== null) return unescape(r[2]);
  return null;
}

/**
 * @description 把日期格式中的 -换成 /
 * @export changeFormatDate
 * @param {String} date
 * @returns {String} _data
 */
export function changeFormatDate(date) {
  const _date = date.replace(/-/g, '/');
  return _date;
}

/**
 * @description 千分位格式化
 * @export formatThousands
 * @param {*} number
 * @returns {string} 数字
 */
export function formatThousands(number) {
  const reg = /\d{1,3}(?=(\d{3})+$)/g;
  return (number + '').replace(reg, '$&,');
}

/**
 * @description 合并两图片数组中相同code对象
 * @export mergePhotoArr
 * @param {Array} originalList
 * @param {Array} mergedList
 *  @returns {Array} 数组
 */
export function mergePhotoArr(originalList, mergedList) {
  originalList.map(item => {
    mergedList.map(item2 => {
      if (item.code === item2.code) {
        item.url = item2.url;
      }
    });
  });
  return originalList;
}

/**
 * @description 合并两图片数组中相同code对象
 * @export mergePhotoArr
 * @param {Array} originalList
 * @param {Array} mergedList
 *  @returns {Array} 数组
 */
export function mergePhotosArr(originalList, mergedList) {
  originalList.map(item => {
    mergedList.map(item2 => {
      if (item.code === item2.photoTypeDetailCode) {
        item.url = item2.photoUrl;
        item.qczj = item2.qczjUrl;
      }
    });
  });
  return originalList;
}

/**
 * @description 合并两图片数组中相同code对象
 * @export mergePhotoArr
 * @param {Array} originalList
 * @param {Array} mergedList
 *  @returns {Array} 数组
 */
export function mergePhotosArrByCode(originalList, mergedList) {
  originalList.map(item => {
    mergedList.map(item2 => {
      if (item.code === item2.code) {
        item.url = item2.url;
        item.qczj = item2.qczj;
      }
    });
  });
  return originalList;
}
