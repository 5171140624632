/*
 * @Descripttion:单文件上传
 * @Author: zhongyuanrui
 * @Date: 2019-09-19 20:25:46
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-10-24 19:08:34
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'antd-mobile';
import autobind from 'autobind-decorator';
import API from '@/api';
import { mountGlobalImagePopup, unMountGlobalImagePopup } from '@/components/imagePopup/RenderGlobalImagePopup';
import { imageBaseUrl } from '@/api/http';
import { AddImageCard, ImageCard } from '@/components';
import { changeFileToBlob } from '@/utils/image';
import DALCarImageState from '@/constants/valuation/carImageState';

@autobind
class SingleUpload extends Component {
  // 上传
  async onUpload(e) {
    const dealerId = window.sessionStorage.getItem('dealerId');
    if (e.target.files) {
      const _file = e.target.files[0];
      const _formData = new FormData();
      // _formData.append('file', _file);
      const _blob = await changeFileToBlob(_file);
      _formData.append('file', _blob, `${_file.name}`);
      _formData.append('type', _blob.type);
      _formData.append('size', _blob.size);
      _formData.append('dealerId', dealerId);
      const res = await API.CarSource.apiUploadFile(_formData);
      if (res.code === 200) {
        this.handleChange(res.data, this.props.type, this.props.photoType);
      } else {
        Toast.info('上传失败', 1);
      }
    }
  }

  openImage() {
    if (this.props.file) {
      const imageList = [];
      let currentIndex = 0;
      if (DALCarImageState.allPhotos && DALCarImageState.allPhotos.length > 0) {
        currentIndex = DALCarImageState.allPhotos.findIndex(el => el.url === this.props.file);
        DALCarImageState.allPhotos.map(item => {
          // imageList.push(imageBaseUrl + item);
          imageList.push({
            url: imageBaseUrl + item.url,
            code: item.code
          });
        });
      } else if (this.props.allPhotos && this.props.allPhotos.length > 0) {
        currentIndex = this.props.allPhotos.findIndex(el => el.url === this.props.file);
        this.props.allPhotos.map(item => {
          // imageList.push(imageBaseUrl + item);
          imageList.push({
            url: imageBaseUrl + item.url,
            code: item.code
          });
        });
      }
      mountGlobalImagePopup({
        imageList,
        currentIndex,
        onClose: () => {
          unMountGlobalImagePopup();
        }
      });
    }
  }

  onRemoveImage() {
    this.handleChange({ url: '', qczj: '' }, this.props.type, this.props.photoType);
  }

  handleChange(file, type, photoType = '') {
    this.props.onChange(file, type, photoType);
  }

  render() {
    return (
      <Fragment>
        {this.props.file
          ? <ImageCard imageUrl={imageBaseUrl + this.props.file} onClickImage={this.openImage} onClickRemove={this.onRemoveImage} ableRemoveImg={this.props.ableRemoveImg}/>
          : <AddImageCard onChange={this.onUpload} bgImage={this.props.bgImage} ableUploadImg={this.props.ableUploadImg} />}
      </Fragment>
    );
  }
}

SingleUpload.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  // 文件
  file: PropTypes.string,
  // 删除图片
  onChange: PropTypes.func,
  ableUploadImg: PropTypes.bool,
  ableRemoveImg: PropTypes.bool
};

SingleUpload.defaultProps = {
  type: '',
  file: ''
};

export default SingleUpload;
