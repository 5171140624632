/*
 * @Descripttion:车辆配置 -安全装备
 * @Author: zhongyuanrui
 * @Date: 2019-09-17 11:40:07
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-23 18:26:57
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TitleCard } from '@/components';
import ConfigItem from '../configItem/ConfigItem';

function SafetyEquipment(props) {
  const data = props.data;
  return (
    <TitleCard title="安全装备" showHeaderLine={false}>
      <ConfigItem label="主驾驶座安全气囊" value={data && data.bn ? data.bn : ''} />
      <ConfigItem label="副驾驶座安全气囊" value={data && data.bo ? data.bo : ''} />
      <ConfigItem label="后排头部气囊(气帘)" value={data && data.bp ? data.bp : ''} />
      <ConfigItem label="发动机电子防盗" value={data && data.engineElectronicControlUnit ? data.engineElectronicControlUnit : ''} />
      {/* <ConfigItem label="后排中间三点式安全带" value={data.a} /> */}
      {/* <ConfigItem label="安全带预收紧功能" value={data.a} /> */}
      <ConfigItem label="零胎压继续行驶" value={data && data.zeroTirePressure ? data.zeroTirePressure : ''} />
      <ConfigItem label="膝部气囊" value={data && data.kneeAirbag ? data.kneeAirbag : ''} />
      <ConfigItem label="无钥匙启动系统" value={data && data.keylessStartSystem ? data.keylessStartSystem : ''} />
      {/* <ConfigItem label="后排安全带气囊" value={data.a} /> */}
      <ConfigItem label="ISOFIX儿童座椅接口" value={data && data.isofixChildSeatInterface ? data.isofixChildSeatInterface : ''} />
      <ConfigItem label="前排头部气囊(气帘)" value={data && data.bs ? data.bs : ''} />
      {/* <ConfigItem label="后排安全带" value={data.a} /> */}
      <ConfigItem label="后排侧气囊" value={data && data.br ? data.br : ''} />
      {/* <ConfigItem label="安全带限力功能" value={data.a} /> */}
      <ConfigItem label="遥控钥匙" value={data && data.remoteControlKey ? data.remoteControlKey : ''} />
      <ConfigItem label="胎压监测装置" value={data && data.tirePressureMonitoring ? data.tirePressureMonitoring : ''} />
      {/* <ConfigItem label="防爆轮胎" value={data.a} /> */}
      <ConfigItem label="安全带未系提示" value={data && data.safetyBeltIsNotPrompt ? data.safetyBeltIsNotPrompt : ''} />
      {/* <ConfigItem label="第三排头部气囊(气帘)" value={data.a} /> */}
      {/* <ConfigItem label="儿童锁" value={data.a} /> */}
      {/* <ConfigItem label="前安全带调节" value={data.a} /> */}
      <ConfigItem label="前排侧气囊" value={data && data.bq ? data.bq : ''} />
      <ConfigItem label="车内中控锁" value={data && data.centralLock ? data.centralLock : ''} />
      <ConfigItem label="无钥匙进入系统" value={data && data.pke ? data.pke : ''} />
      {/* <ConfigItem label="疲劳驾驶提示" value={data.a} /> */}
    </TitleCard>
  );
}

SafetyEquipment.propTypes = {
  data: PropTypes.object
};

export default SafetyEquipment;
