/*
 * @Descripttion: 日期picker
 * @Author: zhongyuanrui
 * @Date: 2019-09-19 14:01:09
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-24 15:06:05
 */
import React, { Component } from 'react';
import SelectItem from './SelectItem';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd-mobile';
import autobind from 'autobind-decorator';
import {
  formatDateString,
  formatDateObject
} from '@/utils/date';
import './style/datePickerItem.css';

@autobind
class DatePickerItem extends Component {
  onOk(value) {
    this.props.onOk(formatDateString(value, this.props.dateFormat), this.props.field ? this.props.field : '');
  }

  render() {
    return (
      <DatePicker
        mode={this.props.mode}
        title={this.props.pickerTitle}
        value={formatDateObject(this.props.value)}
        disabled={this.props.disabled}
        maxDate={formatDateObject(this.props.maxDate)}
        minDate={formatDateObject(this.props.minDate)}
        onPickerChange={this.props.onPickerChange}
        onOk={this.onOk}
        onDismiss={this.props.onDismiss}
        className={this.props.showMinute && this.props.showMinute === 'hide' ? 'hide' : ''}
      >
        <SelectItem label={formatDateString(this.props.value, this.props.dateFormat)} placeholder={this.props.placeholder} />
      </DatePicker>
    );
  }
}

DatePickerItem.propTypes = {
  dateFormat: PropTypes.string,
  // 日期类型
  mode: PropTypes.string,
  // picker标题
  pickerTitle: PropTypes.string,
  // value
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  // 最大日期
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  // 最小日期
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  // 占位符
  placeholder: PropTypes.string,
  // 修改数据
  onPickerChange: PropTypes.func,
  // 点击ok
  onOk: PropTypes.func,
  // 点击取消
  onDismiss: PropTypes.func,
  // 是否可用
  disabled: PropTypes.bool
};

DatePickerItem.defaultProps = {
  dateFormat: 'YYYY-MM-DD',
  mode: 'date',
  // picker标题
  pickerTitle: '',
  // picker列数
  cols: 3,
  // value
  value: new Date(),
  // value
  maxDate: '2025-12-31',
  // value
  minDate: '2000-1-1',
  // 占位符
  placeholder: '',
  // 是否不可用
  disabled: false
};

export default DatePickerItem;
