/*
 * @Descripttion:车辆配置 -车体
 * @Author: zhongyuanrui
 * @Date: 2019-09-17 11:40:07
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-24 19:09:24
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TitleCard } from '@/components';
import ConfigItem from '../configItem/ConfigItem';

function CarBody(props) {
  const data = props.data;
  return (
    <TitleCard title="车体" showHeaderLine={false}>
      {/* <ConfigItem label="车篷型式" value={data.a} /> */}
      {/* <ConfigItem label="车身" value={data.bodystyle} /> */}
      <ConfigItem label="颜色" value={data && data.exteriorColor !== null ? data.exteriorColor : ''} />
      {/* <ConfigItem label="车顶型式" value={data.a} /> */}
      <ConfigItem label="内饰颜色" value={data && data.interiorColor !== null ? data.interiorColor : ''} />
      <ConfigItem label="车顶行李箱架" value={data && data.roofRack !== null ? data.roofRack : ''} />
    </TitleCard>
  );
}

CarBody.propTypes = {
  data: PropTypes.object
};

export default CarBody;
