/*
 * @Descripttion: 设备信息
 * @Author: zhongyuanrui
 * @Date: 2019-06-11 15:11:06
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-06-11 17:56:07
 */

const u = navigator.userAgent;

// android终端
export const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;

// ios终端
export const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

// 移动终端
export const isMobile = !!u.match(/AppleWebKit.*Mobile.*/); // 是否为移动终端