/*
 * @Descripttion: selectItem
 * @Author: zhongyuanrui
 * @Date: 2019-09-19 14:16:43
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-27 09:35:41
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './style/selectItem.module.scss';

function SelectItem(props) {
  return (
    <div className={props.isRight ? `${styles.pickerItem} ${styles.textR}` : styles.pickerItem} onClick={props.onClick}>
      <div className={`${styles.itemContent} ${!props.label ? styles.isNoValue : ''}`}>
        {props.label || props.placeholder}
      </div>
      <i className={`iconfont iconarrow ${styles.iconArrow}`}></i>
    </div>
  );
}

SelectItem.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  // 文字是否右对齐
  isRight: PropTypes.bool
};
SelectItem.defaultProps = {
  placeholder: '',
  label: '',
  isRight: false
};

export default SelectItem;
