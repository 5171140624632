import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes, { oneOfType } from 'prop-types';
import { NewLabelItem, NewTitleCard } from '@/components';
import { ColorStatus } from '@/localData';
import moment from 'moment';
import API from '@/api';
// import { getAuthority } from '@/utils/getAuthority';

function carBaseInfo(props) {
  const baseInfo = props.baseInfo;
  const basicId = props.basicId;
  const carInfoOperateList = [
    {
      label: '车型配置',
      fun: () => {
        props.history.push({ pathname: '/carConfigure', query: { basicId: basicId } });
      }
    }
  ];

  return (
    <NewTitleCard title="车辆信息" carInfoOperateList={carInfoOperateList} buttonStyle={true}>
      <NewLabelItem label="品牌" handleContent={<div>{(baseInfo && baseInfo.brandName) ? baseInfo.brandName : ''}</div>} />
      <NewLabelItem label="车系" handleContent={<div>{(baseInfo && baseInfo.familyName) ? baseInfo.familyName : ''}</div>} />
      <NewLabelItem label="具体车型" handleContent={<div>{(baseInfo && baseInfo.vehicleName) ? baseInfo.vehicleName : ''}</div>} />
      <NewLabelItem label="车牌号" handleContent={<div>{(baseInfo && baseInfo.plateNumber) ? baseInfo.plateNumber : ''}</div>} />
      <NewLabelItem label="VIN码" handleContent={<div>{(baseInfo && baseInfo.vin) ? baseInfo.vin : ''}</div>} />
      <NewLabelItem label="发动机编号" handleContent={<div>{(baseInfo && baseInfo.engineNumber) ? baseInfo.engineNumber : ''}</div>} />
      <NewLabelItem label="表显里程（万公里）" handleContent={<div>{(baseInfo && baseInfo.mileage) ? baseInfo.mileage / 10000 : ''}</div>} />
      <NewLabelItem label="车辆所在地" handleContent={<div>{(baseInfo && baseInfo.sourceProvinceName && baseInfo.sourceCityName) ? baseInfo.sourceProvinceName + baseInfo.sourceCityName : ''}</div>} />
      <NewLabelItem label="上牌时间" handleContent={<div>{baseInfo && baseInfo.carLicenseDate ? moment(baseInfo.carLicenseDate).format('YYYY-MM-DD') : ''}</div>} />
      <NewLabelItem label="年审时间" handleContent={<div>{baseInfo && baseInfo.carYearDate ? moment(baseInfo.carYearDate).format('YYYY-MM-DD') : ''}</div>} />
      <NewLabelItem label="车身颜色" bottomBorderVisible={true} handleContent={<div>{(baseInfo && baseInfo.carColor && ColorStatus[baseInfo.carColor]) ? ColorStatus[baseInfo.carColor] : ''}</div>} />
      <NewLabelItem label="交强险到期日" handleContent={<div>{(baseInfo && baseInfo.compulsoryLicenseDate) ? baseInfo.compulsoryLicenseDate : ''}</div>} />
      <NewLabelItem label="商业险到期日" handleContent={<div>{(baseInfo && baseInfo.businesssLicenseDate) ? baseInfo.businesssLicenseDate : ''}</div>} />
      <NewLabelItem label="车船税到期日" bottomBorderVisible={false} handleContent={<div>{(baseInfo && baseInfo.vehicleTaxDate) ? baseInfo.vehicleTaxDate : ''}</div>} />
    </NewTitleCard>
  );
}
carBaseInfo.propTypes = {
  baseInfo: PropTypes.object,
  basicId: oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

carBaseInfo.defaultProps = {
  basicId: null
};

export default withRouter(carBaseInfo);
