import axios from 'axios';
import {
  mountGlobalLoading,
  unMountGlobalLoading
} from '@/components/loading/RenderGlobalLoading';
// import DALCommonState from '@/dal/common/state';
import { Toast } from 'antd-mobile';
import {
  nativeApi
} from '@/jsBridge';
// import QS from 'qs';

export const QS = require('qs');

// json请求头
export const oXHRHeadrs = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
};
// url序列化请求头
export const oXHRHeadrsUrlencoded = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};
// formData表单请求头
export const oXHRHeadrsFormData = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};

// `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。 192.168.2.222:20300
// 测试
// const localAppApiUrl = 'http://192.168.1.190:8899'; // 测试服务器地址
// const localAppApiUrl = 'http://192.168.3.28:8899';
// const localAppApiUrl = 'http://192.168.3.35:8899';// 张玄
// const localAppApiUrl = 'http://192.168.3.66:8899';// 彭尚幸

// const localCommonApiUrl = 'http://192.168.1.190:8899/public/public';

// const localImageUrl = 'http://192.168.1.190:23500/still';

// 正式
const localAppApiUrl = 'https://zdche.unicdata.com/app/app';
const localCommonApiUrl = 'https://zdche.unicdata.com/public/public';
const localImageUrl = 'https://zdche.unicdata.com/still';
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? localAppApiUrl : process.env.REACT_APP_API_URL;
export const commonBaseUrl = process.env.NODE_ENV === 'development' ? localAppApiUrl : process.env.REACT_APP_API_URL;
export const commonApiUrl = process.env.NODE_ENV === 'development' ? localCommonApiUrl : process.env.REACT_COMMON_API_URL;
export const imageBaseUrl = process.env.NODE_ENV === 'development' ? localImageUrl : process.env.REACT_IMAGE_URL;
export const indentifyApiUrl = 'https://service.unicdata.com/';

// `timeout` 指定请求超时的毫秒数(0 表示无超时时间)，如果请求话费了超过 `timeout` 的时间，请求将被中断
// axios.defaults.timeout = 10000;
// 全局设定请求类型
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

/**
 * http request 拦截器
 */
// axios.interceptors.request.use(
// config => {
// 在发送请求之前做些什么
// if (DALCommonState.token) {
// config.headers['Authorization'] = DALCommonState.token;
// }
// mountGlobalLoading();
// return config;
// },
// error => {
// unMountGlobalLoading();
// 对请求错误做些什么
// return Promise.reject(error);
// }
// );

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  response => {
    unMountGlobalLoading();
    // console.log(response);
    // 对响应数据做点什么
    if (response.data.code === '200' || response.data.code === 200) {
      return response.data;
    } else if (response.data.code === 998) {
      // 未登录或者登录过期
      nativeApi.closeWebView();
      return response.data;
    } else {
      Toast.info(response.data.msg, 1);
      return response.data;
    }
  },
  error => {
    unMountGlobalLoading();
    if (error.response && error.response.status === 413) {
      Toast.info('上传文件过大', 1);
    } else {
      Toast.info('网络错误', 1);
    }
    // 对响应错误做点什么
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
);

/**
 * get请求
 * @param {String} url 请求地址
 * @param {Obejct} params 请求序列化参数
 * @returns {Promise} 返回一个promise
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params
    }).then(response => {
      resolve(response);
    }).catch(err => {
      reject(err);
    });
  });
}
/**
 * post请求
 * @param {String} url 请求地址
 * @param {Obejct} data 請求參數
 * @param {Obejct} headers 請求头部
 * @param {Obejct} params 请求序列化参数
 * @returns {Promise} 返回一个promise
 */
export function post(url, data = {}, headers, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, headers, params).then((response) => {
      resolve(response);
    }).catch(err => {
      reject(err);
    });
  });
}
/**
 * post序列化请求
 * @param {String} url 请求地址
 * @param {Obejct} data 請求參數
 * @param {Obejct} headers 請求头部
 * @param {Obejct} params 请求序列化参数
 * @returns {Promise} 返回一个promise
 */
export function postByUrlencoded(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, QS.stringify(data), oXHRHeadrsUrlencoded).then((response) => {
      resolve(response);
    }).catch(err => {
      reject(err);
    });
  });
}

/**
 * post序列化请求
 * @param {String} url 请求地址
 * @param {Obejct} data 請求參數
 * @param {Obejct} headers 請求头部
 * @param {Obejct} params 请求序列化参数
 * @returns {Promise} 返回一个promise
 */
export function postByJsonUrl(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, oXHRHeadrs).then((response) => {
      resolve(response);
    }).catch(err => {
      reject(err);
    });
  });
}

/**
 * patch请求
 * @param {String} url 请求地址
 * @param {Obejct} data 請求參數
 * @param {Obejct} params 请求序列化参数
 * @returns {Promise} 返回一个promise
 */
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data)
      .then(response => {
        resolve(response);
      }, err => {
        reject(err);
      });
  });
}
/**
 * put请求
 * @param {String} url 请求路径
 * @param {Object} data 请求参数
 * @returns {Promise} 返回调用
 */
export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data)
      .then(response => {
        resolve(response);
      }, err => {
        reject(err);
      });
  });
}
