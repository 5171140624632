import React from 'react';
import { observer } from 'mobx-react';
import { TopNavBar, Slider } from '@/components';
import {
  nativeApi
} from '@/jsBridge';
import API from '@/api';
import DALCommon from '@/constants/common/state';
import CarDetailInfo from '../../vehicle/carDetailInfo';
import styles from './style/CarDetail.module.scss';
import iconMore from '@/assets/image/icon_more.png';
import { Toast } from 'antd-mobile';

class CarDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicId: null,
      isShowMoreCon: false,
      approvalInfo: {}, // 审批信息
      carBasic: {}, // 车辆基本信息
      dealerBloc: {}, // 经销商信息
      makeReadyInfo: {}, // 整备信息
      orderInfo: {}, // 订单信息
      purchaseInfo: {}, // 采购信息
      retailInfo: {}, // 零售信息
      photos: [] //
    };
  }

  componentDidMount() {
    // if (this.props.location.query && this.props.location.query.basicId) {
    // const basicId = this.props.location.query.basicId;
    // }
    console.log('3333');
    this.state.basicId = DALCommon.basicId;
    this.getData();
    console.log(this.state.basicId);
  }

  getData = () => {
    API.CarSource.getCarDetail({
      basicId: this.state.basicId
    }).then(res => {
      if (res.code === 200) {
        //
        const info = res.data;
        console.log(res.data);
        window.sessionStorage.setItem('dealerId', res.data.dealerBloc.id); // 经销商id
        this.setState({
          approvalInfo: { ...res.data.approvalInfo },
          carBasic: { ...res.data.carBasic },
          dealerBloc: { ...res.data.dealerBloc },
          makeReadyInfo: { ...res.data.makeReadyInfo },
          orderInfo: { ...res.data.orderInfo },
          purchaseInfo: { ...res.data.purchaseInfo },
          retailInfo: { ...res.data.retailInfo },
          photos: [...res.data.photos]
        });
      } else {
        //
      }
    });
  }

  /**
  * 返回原生页面
  */
  goBack = () => {
    nativeApi.closeWebView();
  }

  /**
  * 菜单展示更多
  */
  showMore = () => {
    this.setState({
      isShowMoreCon: !this.state.isShowMoreCon
    });
  }

  // 路由地址
  routeJump(pathname) {
    this.props.history.push({
      pathname,
      query: {
        isFrom: 'h5',
        basicId: this.state.basicId
      }
    });
  }

  goOtherPage(types) {
    switch (types) {
      case '1':// 车辆入库
        this.routeJump('/carEnterStore');
        break;
      case '2': // 车辆整备
        this.routeJump('/carHostling');
        break;
      case '3': // 编辑信息
        this.routeJump('/carInfoEdit');
        break;
      case '4':// 上传图片
        this.routeJump('/carPhotos');
        break;
      case '5':// 申请零售
        this.routeJump('/carSaleApply');
        break;
      case '6':// 车辆上架
        if (this.state.approvalInfo.status && this.state.approvalInfo.status === 1) {
          // 申请状态为已通过时才可以进行上架操作
          this.routeJump('/carOnShelve');
        } else {
          Toast.info('该车尚未审批通过，不可上架');
        }

        break;
      case '7':// 车辆下架
        if (this.state.retailInfo.saleStatus && this.state.retailInfo.saleStatus === 1) {
          // 已上架才能下架
          this.routeJump('/carOffShelve');
        } else {
          Toast.info('该车尚未上架，不可下架');
        }
        break;
    }
  }

  // 右上角操作按钮
  renderRightHandle() {
    return (
      <div className={styles.topLeft}>
        <div className={styles.detailMore} onClick={this.showMore}>
          <img src={iconMore} />
        </div>
        {this.state.isShowMoreCon && <div className={styles.moreContent}>
          {<span onClick={() => this.goOtherPage('1')}>车辆入库</span>}
          {<span onClick={() => this.goOtherPage('2')}>车辆整备</span>}
          {<span onClick={() => this.goOtherPage('3')}>编辑信息</span>}
          {<span onClick={() => this.goOtherPage('4')}>上传图片</span>}
          {<span onClick={() => this.goOtherPage('5')}>申请零售</span>}
          {<span onClick={() => this.goOtherPage('6')}>车辆上架</span>}
          {<span onClick={() => this.goOtherPage('7')}>车辆下架</span>}
        </div>}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.CarDetail}>
        <TopNavBar title={'车辆详情'} rightHandle={this.renderRightHandle()} />
        <div className={styles.sliderCar}>
          <Slider sliders={this.state.photos} sliderClassName={styles.imgHeight} />
          <CarDetailInfo
            basicId={this.state.basicId}
            approvalInfo={this.state.approvalInfo}
            carBasic={this.state.carBasic}
            dealerBloc={this.state.dealerBloc}
            makeReadyInfo={this.state.makeReadyInfo}
            orderInfo={this.state.orderInfo}
            purchaseInfo={this.state.purchaseInfo}
            retailInfo={this.state.retailInfo}
          />
        </div>
      </div>
    );
  }
}

export default CarDetail;