import {
  post,
  postByUrlencoded,
  commonApiUrl
} from '../http';

// 文件上传
export const apiUploadFile = (data) => {
  return post(`${commonApiUrl}/upload/uploadFile`, data);
};
// 多文件上传
export const apiUploadFileMore = (data) => {
  return post(`${commonApiUrl}/upload/uploadFileMore`, data);
};
// 获取省份
export const apiSelectProvince = () => {
  return postByUrlencoded(`${commonApiUrl}/dropdown/selectProvince`);
};
// 获取城市
export const apiSelectCity = ({
  provinceId
}) => {
  return postByUrlencoded(`${commonApiUrl}/dropdown/selectCity`, {
    provinceId
  });
};
// 获取区域
export const apiSelectDistrict = ({
  cityId
}) => {
  return postByUrlencoded(`${commonApiUrl}/dropdown/selectDistrict`, {
    cityId
  });
};
