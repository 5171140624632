import React from 'react';
import {
  BaseConfig,
  CarBody,
  Carrosserie,
  ChassisRotation,
  ControlConfig,
  Engine,
  Gearbox,
  InsideConfig,
  OutsideConfig,
  SafetyEquipment,
  WheelBraking
} from '@/containers/carConfig';
import PropTypes from 'prop-types';

export default function carConfigureInfo(props) {
  return (
    <div>
      <BaseConfig data={props.data.carBasicParameter} />
      <CarBody data={props.data.carBody} />
      <Carrosserie data={props.data.carBodyInfo} />
      <ChassisRotation data={props.data.chassisSpin} />
      <ControlConfig data={props.data.controlConfig} />
      <Engine data={props.data.engine} />
      <Gearbox data={props.data.gearbox} />
      <InsideConfig data={props.data.interiorConfig} />
      <OutsideConfig data={props.data.externalConfig} />
      <SafetyEquipment data={props.data.safetyItem} />
      <WheelBraking data={props.data.wheelBrake} />
    </div>
  );
}
carConfigureInfo.propTypes = {
  data: PropTypes.object
};
carConfigureInfo.defaultProps = {
  data: {}
};
