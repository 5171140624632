import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NewLabelItem, NewTitleCard, NoData } from '@/components';
import { ChannelTypeStatus, ErshouStatus } from '@/localData';
// import { purchaseBusinessTypeList, paymentTypeList, purchaseStatus } from '@/localData';
// import { getItemByValue, formatThousands } from '@/utils/dataFormat';

function channelInfo(props) {
  const retailInfo = props.retailInfo;
  return (
    <NewTitleCard title="展示渠道">
      <NewLabelItem label="凯迪商城" handleContent={<div>{(retailInfo && retailInfo.kdChannel !== null && ChannelTypeStatus[retailInfo.kdChannel]) ? ChannelTypeStatus[retailInfo.kdChannel].label : ''}</div>} />
      <NewLabelItem label="二手车之家" handleContent={<div>{(retailInfo && retailInfo.ershouChannel !== null && ChannelTypeStatus[retailInfo.ershouChannel]) ? ChannelTypeStatus[retailInfo.ershouChannel].label + ((retailInfo.qczjStatus !== null && ErshouStatus[retailInfo.qczjStatus]) ? ErshouStatus[retailInfo.qczjStatus] : '') : ''}</div>} />
      <NewLabelItem label="天猫商城" handleContent={<div>{(retailInfo && retailInfo.tmailChannel !== null && ChannelTypeStatus[retailInfo.tmailChannel]) ? ChannelTypeStatus[retailInfo.tmailChannel].label : ''}</div>} />
      <NewLabelItem label="抖音商城" handleContent={<div>{(retailInfo && retailInfo.dyChannel !== null && ChannelTypeStatus[retailInfo.dyChannel]) ? ChannelTypeStatus[retailInfo.dyChannel].label : ''}</div>} />
    </NewTitleCard>
  );
}
channelInfo.propTypes = {
  retailInfo: PropTypes.object
};
export default withRouter(channelInfo);
