/*
 * @Descripttion:配置列表 - 配置项
 * @Author: zhongyuanrui
 * @Date: 2019-09-17 13:28:12
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-20 19:14:46
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './style/config.module.scss';

function ConfigItem(props) {
  return (
    <div className={styles.configItem}>
      <div className={styles.configItemLabel}>{props.label}</div>
      <div className={styles.configItemValue}>{props.value ? props.value : ''}</div>
    </div>
  );
}

ConfigItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any
};

export default ConfigItem;
