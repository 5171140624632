import React from 'react';
import PropTypes from 'prop-types';
import styles from './style/carConfigureHeader.module.scss';

export default function carConfigureHeader(props) {
  return (
    <div className={styles.CarConfigureInfo}>
        <div className={styles.carType}>{props.vehicleName}</div>
        <div className={styles.configExplain}>
          配置说明
          <span></span>
          标配
          <span></span>
          选配
          <span></span>
          无
        </div>
    </div>
  );
}
carConfigureHeader.propTypes = {
  vehicleName: PropTypes.string
};
carConfigureHeader.defaultProps = {
  vehicleName: ''
};
