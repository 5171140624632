/*
* 车辆图片上传页面
*/
import React from 'react';
import { LabelItem, InputItem, Card, PickerItem, DatePickerItem, TopNavBar, Footer, Button } from '@/components';
import {
  MoreUpload,
  NewUpload
} from '@/containers/common';
import autobind from 'autobind-decorator';
import { mergePhotosArr } from '@/utils/dataFormat';
import API from '@/api';
import DALCommon from '@/constants/common/state';
import styles from './style/CarPhotos.module.scss';
import { Toast } from 'antd-mobile';

class CarPhotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicId: null,
      photos1: [
        {
          code: '1-1',
          name: '45度角',
          url: '',
          bgImage: 'zq45',
          qczj: '',
          isRequire: true
        },
        {
          code: '1-2',
          name: '正前方',
          url: '',
          bgImage: 'yh45',
          qczj: '',
          isRequire: true
        },
        {
          code: '1-3',
          name: '正后方',
          url: '',
          bgImage: 'zqm',
          qczj: '',
          isRequire: true
        },
        {
          code: '1-4',
          name: '正侧面',
          url: '',
          bgImage: 'yqm',
          qczj: '',
          isRequire: true
        },
        {
          code: '1-5',
          name: '发动机舱',
          url: '',
          bgImage: 'zhm',
          qczj: '',
          isRequire: true
        },
        {
          code: '1-6',
          name: '后备箱',
          url: '',
          bgImage: 'zkt',
          qczj: '',
          isRequire: true
        },
        {
          code: '1-7',
          name: '仪表盘',
          url: '',
          bgImage: 'fdjc',
          qczj: '',
          isRequire: true
        },
        {
          code: '1-8',
          name: '中控',
          url: '',
          bgImage: 'clmp',
          qczj: '',
          isRequire: true
        },
        {
          code: '1-9',
          name: '前排（A柱B柱）',
          url: '',
          bgImage: 'clgls',
          qczj: '',
          isRequire: true
        },
        {
          code: '1-10',
          name: '后排（B柱C柱）',
          url: '',
          bgImage: 'cz12',
          qczj: ''
        },
        {
          code: '1-11',
          name: '备胎（其他附件）',
          url: '',
          qczj: '',
          bgImage: 'cz12'
        },
        {
          code: '1-12',
          name: '车钥匙',
          url: '',
          qczj: '',
          bgImage: 'cz34'
        }
      ],
      photos2: [
        {
          code: '3-1',
          name: '认证图片',
          url: '',
          qczj: '',
          bgImage: 'zq45'
        },
        {
          code: '2-2',
          name: '行驶证照片',
          url: '',
          bgImage: 'yh45',
          qczj: '',
          isRequire: true
        },
        {
          code: '2-7',
          name: '车辆铭牌照片',
          url: '',
          bgImage: 'yh45',
          qczj: '',
          isRequire: true
        },
        {
          code: '2-3',
          name: '产证1-2页',
          url: '',
          qczj: '',
          bgImage: 'zqm'
        },
        {
          code: '2-4',
          name: '产证过户页',
          url: '',
          qczj: '',
          bgImage: 'yqm'
        },
        {
          code: '2-5',
          name: '交强险截图',
          url: '',
          qczj: '',
          bgImage: 'zhm'
        },
        {
          code: '2-6',
          name: '商业险截图',
          url: '',
          qczj: '',
          bgImage: 'zkt'
        }
      ],
      otherPhotos: [],
      photos11: []
    };
  }

  async componentDidMount() {
    this.state.basicId = DALCommon.basicId;
    this.getValuationResultData();
  }

  async getValuationResultData() {
    const res = await API.CarSource.getCarPhotos({
      basicId: this.state.basicId
      // carId: 46
    });
    if (res.code === 200 && res.data) {
      // 清空图片
      this.otherPhotos = [];
      if (this.state.photos1 && this.state.photos1.length > 0) {
        this.state.photos1.map(item => {
          item.url = '';
          item.qczj = '';
        });
      }
      if (this.state.photos2 && this.state.photos2.length > 0) {
        this.state.photos2.map(item => {
          item.url = '';
          item.qczj = '';
        });
      }
      if (res.data && res.data.length > 0) {
        const list1 = mergePhotosArr(this.state.photos1, [...res.data]);
        this.setState({
          photos1: [...list1]
        });
        const list2 = mergePhotosArr(this.state.photos2, [...res.data]);
        this.setState({
          photos2: [...list2]
        });
        const list3 = [];
        res.data.map(item => {
          if (item.photoTypeDetailCode === '4-1') {
            list3.push({
              url: item.photoUrl,
              qczj: item.qczjUrl
            });
          }
        });
        this.setState({
          otherPhotos: [...list3]
        });
        // console.log(this.state.photos1);
        // console.log(this.state.photos2);
        // console.log(list3);
        // console.log(this.state.otherPhotos);
      }
    }
  }

  // 车辆照片
  onChangeImage = (file, type, photoType) => {
    const photoList = this.state['photos' + photoType];
    const imgIndex = photoList.findIndex(item => item.code === type);
    photoList[imgIndex].url = file.url;
    photoList[imgIndex].qczj = file.qczj;
    this.setState({
      ['photos' + photoType]: photoList
    });
  }

  @autobind
  otherImageListChange(file) {
    this.setState({
      otherPhotos: file
    });
  }

  async submitCarInfo() {
    // 提交
    if (this.state.photos2[1].url === '') {
      // 必传照片
      Toast.info('请上传车辆行驶证照片');
      return;
    }
    if (this.state.photos2[2].url === '') {
      // 必传照片
      Toast.info('请上传车辆铭牌照片');
      return;
    }
    for (const item of this.state.photos1) {
      if (item.isRequire && item.url === '') {
        Toast.info('请上传车辆' + item.name + '照片');
        return;
      }
    }
    if (this.state.otherPhotos && this.state.otherPhotos.length > 0) {
      this.photos11 = [];
      this.state.otherPhotos.map(item => {
        this.state.photos11.push({
          url: item.url,
          qczj: item.qczj,
          code: '4-1'
        });
      });
    }
    const params = {
      basicId: this.state.basicId,
      photos: [...this.state.photos1, ...this.state.photos2, ...this.state.photos11]
    };
    const res = await API.CarSource.updateCarPhotos(params);
    if (res.code === 200) {
      Toast.info('提交成功');
      this.props.history.goBack();
    }
  }

  render() {
    return (
      <div className={styles.CarPhotos}>
        <TopNavBar title={'上传车辆图片'}></TopNavBar>
        <div className={styles.baseParamsWrap}>
          <NewUpload
            title='车辆照片'
            photoList={this.state.photos1}
            photoType={1}
            onChange={this.onChangeImage}
          />
          <NewUpload
            title='牌证照片'
            photoList={this.state.photos2}
            photoType={2}
            onChange={this.onChangeImage}
          />
          <div className={styles.baseParamsTitle}>其他照片</div>
          <div>
            <MoreUpload imageList={this.state.otherPhotos} changeImageList={this.otherImageListChange} allPhotos={this.state.otherPhotos} code={'4-1'} />
          </div>
        </div>
        <Footer>
          <Button label="提交" type="primary" onClick={this.submitCarInfo.bind(this)} disabled={false} />
        </Footer>
      </div>
    );
  }
}
export default CarPhotos;