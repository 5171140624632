import React from 'react';
import { CarAddInfo } from '../../../containers/carAdd';
import DALCarAddInfo from '@/constants/carAdd/DALCarAddInfo';
import { Provider, observer } from 'mobx-react';

class CarAddNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Provider DALCarAddInfo={DALCarAddInfo}>
        <div>
          <CarAddInfo />
        </div>
      </Provider>
    );
  }
}

export default CarAddNew;