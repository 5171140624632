/*
 * @Descripttion: 顶部导航栏返回键
 * @version:
 * @Author: zhongyuanrui
 * @Date: 2019-05-15 11:13:01
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-12 11:17:04
 */
import React from 'react';
import styles from './style/backButton.module.scss';
import PropTypes from 'prop-types';
import backIcon from '@/assets/image/icon_back.png';

function BackButton(props) {
  return (
    <div className={styles.backButton} onClick={props.onBack}>
      <i className="iconfont iconback"></i>
    </div>
  );
}

BackButton.propTypes = {
  onBack: PropTypes.func
};

export default BackButton;