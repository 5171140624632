// 车身颜色
export const ColorStatus = {
  1: '棕色',
  2: '橙色',
  3: '深灰色',
  4: '白色',
  5: '紫色',
  6: '红色',
  7: '绿色',
  8: '蓝色',
  9: '银灰色',
  10: '香槟色',
  11: '黄色',
  12: '黑色',
  13: '其他'
};

// 使用性质
export const OperationTypeStatus = {
  1: '非营运',
  2: '租赁非营运',
  3: '营运',
  4: '营转非'
};

// 车辆用途
export const UseTypeStatus = {
  1: '家用',
  2: '运营转私人',
  3: '单位用车'
};

// 过户次数
export const TransferTimesStatus = {
  0: '0次',
  1: '1次',
  2: '2次',
  3: '3次',
  4: '4次',
  5: '5次',
  6: '5次以上'
};

// 二手车之家上下架状态
export const ErshouStatus = {
  1: '（在售）',
  2: '（已售）',
  3: '（审核中）',
  4: '（审核未通过）',
  5: '（已过期）'
};

// 车辆使用性质
export const UseNatureList = [
  {
    label: '非营运',
    value: 1
  },
  {
    label: '租赁非营运',
    value: 2
  },
  {
    label: '营运',
    value: 3
  },
  {
    label: '营转非',
    value: 4
  }
];

// 车辆使用性质
export const UseTypeList = [
  {
    label: '家用',
    value: 1
  },
  {
    label: '运营转私人',
    value: 2
  },
  {
    label: '单位用车',
    value: 3
  }
];

// 车辆过户次数
export const TransferTimesList = [
  {
    label: '0次',
    value: 0
  },
  {
    label: '1次',
    value: 1
  },
  {
    label: '2次',
    value: 2
  },
  {
    label: '3次',
    value: 3
  },
  {
    label: '4次',
    value: 4
  },
  {
    label: '5次',
    value: 5
  },
  {
    label: '5次以上',
    value: 6
  }
];

// 车辆颜色
export const CarColorList = [
  {
    label: '棕色',
    value: 1
  },
  {
    label: '橙色',
    value: 2
  },
  {
    label: '深灰色',
    value: 3
  },
  {
    label: '白色',
    value: 4
  },
  {
    label: '紫色',
    value: 5
  },
  {
    label: '红色',
    value: 6
  },
  {
    label: '绿色',
    value: 7
  },
  {
    label: '蓝色',
    value: 8
  },
  {
    label: '银灰色',
    value: 9
  },
  {
    label: '香槟色',
    value: 10
  },
  {
    label: '黄色',
    value: 11
  },
  {
    label: '黑色',
    value: 12
  },
  {
    label: '其他',
    value: 13
  }
];

export const SaleStatusType = [
  {
    label: '待上架',
    value: 0
  },
  {
    label: '已上架',
    value: 1
  },
  {
    label: '已下架',
    value: 2
  }
];

export const AuthType = [
  {
    label: '未认证',
    value: -1
  },
  {
    label: '安吉星认证',
    value: 0
  },
  {
    label: '诚新认证',
    value: 1
  }
];

export const ChannelTypeStatus = [
  {
    label: '未上架',
    value: 0
  },
  {
    label: '已上架',
    value: 1
  },
  {
    label: '已下架',
    value: 2
  }
];

export const OrderStatus = [
  {
    label: '未成交',
    value: 0
  },
  {
    label: '已成交',
    value: 1
  }
];

export const StackFinacingStatus = [
  {
    label: '否',
    value: 0
  },
  {
    label: '是',
    value: 1
  }
];

export const ApplyStatus = [
  {
    label: '否',
    value: 0
  },
  {
    label: '是',
    value: 1
  }
];

export const SaleStatus = [
  {
    label: '待申请',
    value: -1
  },
  {
    label: '已申请',
    value: 0
  },
  {
    label: '已通过',
    value: 1
  },
  {
    label: '已驳回',
    value: 2
  }
];

export const CarType = [
  {
    label: '未确认',
    value: -1
  },
  {
    label: '优选车',
    value: 0
  },
  {
    label: '精品车',
    value: 1
  }
];

export const CarSourceType = [
  {
    label: '厂家下发',
    value: 0
  },
  {
    label: '车源自采',
    value: 1
  }
];

// 照片类型
export const photoType = {};

export const CarTypeSelect = {
  0: '优选车',
  1: '精品车'
};

export const AuthTypeSelect = {
  0: '安吉星认证',
  1: '诚新认证'
};

export const CarApplyAuthType = [
  {
    label: '安吉星认证',
    value: 0
  },
  {
    label: '诚新认证',
    value: 1
  }
];

export const CarApplyCarType = [
  {
    label: '优选车',
    value: 0
  },
  {
    label: '精品车',
    value: 1
  }
];
