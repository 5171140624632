import React from 'react';
import { ActivityIndicator } from 'antd-mobile';
import PropTypes from 'prop-types';

function Loading(props) {
  return (
    <ActivityIndicator
      toast
      text={props.text}
      animating={props.animating}
    />
  );
}

Loading.propTypes = {
  animating: PropTypes.bool,
  text: PropTypes.string
};
Loading.defaultProps = {
  animating: true,
  text: 'Loading...'
};

export default Loading;
