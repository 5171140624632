/*
 * @Descripttion:车辆配置 -外部配置
 * @Author: zhongyuanrui
 * @Date: 2019-09-17 11:40:07
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-23 18:24:22
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TitleCard } from '@/components';
import ConfigItem from '../configItem/ConfigItem';

function OutsideConfig(props) {
  const data = props.data;
  return (
    <TitleCard title="外部配置" showHeaderLine={false}>
      <ConfigItem label="运动外观套件" value={data && data.sportAppearanceSuite ? data.sportAppearanceSuite : ''} />
      <ConfigItem label="电动后备厢" value={data && data.electricTrunk ? data.electricTrunk : ''} />
      {/* <ConfigItem label="尾翼/扰流板" value={data.a} /> */}
      {/* <ConfigItem label="金属漆" value={data.a} /> */}
      {/* <ConfigItem label="多天窗" value={data.a} /> */}
      <ConfigItem label="电动天窗" value={data && data.electricSunroof ? data.electricSunroof : ''} />
      <ConfigItem label="全景天窗" value={data && data.panoramicSunroof ? data.panoramicSunroof : ''} />
      {/* <ConfigItem label="外侧踏板" value={data.a} /> */}
      {/* <ConfigItem label="遥控后备厢" value={data.a} /> */}
      <ConfigItem label="铝合金轮圈" value={data && data.hubtype ? data.hubtype : ''} />
      <ConfigItem label="电动吸合门" value={data && data.electricDoor ? data.electricDoor : ''} />
      {/* <ConfigItem label="主动进气格栅" value={data.a} /> */}
      <ConfigItem label="侧滑门" value={data && data.slidingDoor ? data.slidingDoor : ''} />
      {/* <ConfigItem label="天窗尺寸(mm)" value={data.a} /> */}
      <ConfigItem label="感应后备厢" value={data && data.inductionTrunk ? data.inductionTrunk : ''} />
    </TitleCard>
  );
}

OutsideConfig.propTypes = {
  data: PropTypes.object
};

export default OutsideConfig;
