/*
 * @Descripttion:车辆配置 -车身
 * @Author: zhongyuanrui
 * @Date: 2019-09-17 11:40:07
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-24 19:10:16
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TitleCard } from '@/components';
import ConfigItem from '../configItem/ConfigItem';

function Carrosserie(props) {
  const data = props.data;
  return (
    <TitleCard title="车身" showHeaderLine={false}>
      <ConfigItem label="轴距(mm)" value={data && data.wheelbase ? data.wheelbase : ''} />
      {/* <ConfigItem label="开门方式" value={data.a} /> */}
      <ConfigItem label="行李厢容积(L)" value={data && data.baggage ? data.baggage : ''} />
      <ConfigItem label="最大载重质量(kg)" value={data && data.maxload ? data.maxload : ''} />
      {/* <ConfigItem label="后备厢内部尺寸(mm)" value={data.a} /> */}
      <ConfigItem label="宽度(mm)" value={data && data.width ? data.width : ''} />
      <ConfigItem label="前轮距(mm)" value={data && data.frontgauge ? data.frontgauge : ''} />
      {/* <ConfigItem label="行李箱盖开合方式" value={data.a} /> */}
      <ConfigItem label="高度(mm)" value={data && data.height ? data.height : ''} />
      <ConfigItem label="车门数(个)" value={data && data.doors ? data.doors : ''} />
      <ConfigItem label="油箱容积(L)" value={data && data.fulevolumn ? data.fulevolumn : ''} />
      <ConfigItem label="整备质量(kg)" value={data && data.weigth ? data.weigth : ''} />
      {/* <ConfigItem label="第二油箱(L)" value={data.a} /> */}
      <ConfigItem label="后轮距(mm)" value={data && data.backgauge ? data.backgauge : ''} />
      <ConfigItem label="长度(mm)" value={data && data.length ? data.length : ''} />
      {/* <ConfigItem label="行李箱电动吸合门" value={data.a} /> */}
      {/* <ConfigItem label="货箱尺寸(mm)" value={data.a} /> */}
      {/* <ConfigItem label="后备厢最大容积(L)" value={data.a} /> */}
      <ConfigItem label="座位数(个)" value={data && data.seats ? data.seats : ''} />
      {/* <ConfigItem label="车身材质" value={data.a} /> */}
      {/* <ConfigItem label="后排车门开启方式" value={data.a} /> */}
      {/* <ConfigItem label="行李箱打开方式" value={data.a} /> */}
    </TitleCard>
  );
}

Carrosserie.propTypes = {
  data: PropTypes.object
};

export default Carrosserie;
