/* eslint-disable multiline-ternary */
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  TitleSingleUpload
} from '@/containers/common';
import './style/newUpload.scss';

class NewUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoList: props.photoList || []
    };
  }

  moreUploadFun = () => {
    this.refs.fileIptRef.click();
  }

  modifyLabel = () => {
    this.props.history.push({
      pathname: '/modifyPhotoLabel',
      query: this.state.photoList
    });
  }

  render() {
    const { photoList } = this.state;
    return (
      <div className='new-moreUpload'>
        <div className='new-moreUpload_info'>
          <div className='new-moreUpload_title'>{this.props.title}</div>
          <div>
            {this.props.modifyVal && <div className='new-moreUpload_optVal new-moreUpload_modify' onClick={this.modifyLabel}>{this.props.modifyVal}</div>}
            {this.props.optVal && <div className='new-moreUpload_optVal' onClick={this.moreUploadFun}>{this.props.optVal}</div>}
          </div>
        </div>
        {photoList && photoList.length > 0 ? photoList.map((item, index) => (
          <div className='new-moreUpload_item' key={index}><TitleSingleUpload allPhotos={photoList} isRequire={item.isRequire || false}
        type={item.code} file={item.url} title={item.name} key={index} photoType={this.props.photoType || ''} onChange={this.props.onChange}
         bgImage={item.bgImage || ''} ableUploadImg={item.ableUploadImg} ableRemoveImg={item.ableRemoveImg}/></div>
        )) : ''}
        <input
          ref="fileIptRef"
          type="file"
          accept="image/*"
          className='file-ipt'
          multiple={true}
          onChange={this.props.onChangeMoreImg}
        />
      </div>
    );
  }
}

export default withRouter(NewUpload);
