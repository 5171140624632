import React from 'react';
import styles from './noData.module.scss';

function Nodata(props) {
  return (
    <div className={styles.noData}>
      <img src={require('@/assets/image/see.png')} />
      <span>{props.text}</span>
    </div>
  );
}

export default Nodata;
