import React from 'react';
import ReactDOM from 'react-dom';
import Loading from './Loading';
function mountGlobalLoading(text) {
  if (!window.globalLoading) {
    ReactDOM.render(<Loading text={text}/>, document.getElementById('frame-loading'));
    window.globalLoading = true;
  }
}

function unMountGlobalLoading() {
  if (window.globalLoading) {
    ReactDOM.unmountComponentAtNode(document.getElementById('frame-loading'));
    window.globalLoading = false;
  }
}

export { mountGlobalLoading, unMountGlobalLoading };
