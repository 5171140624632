/*
 * @Descripttion: jsBridge
 * @Author: zhongyuanrui
 * @Date: 2019-06-11 15:58:19
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-10-28 11:08:20
 */
import {
  isAndroid,
  isiOS,
  isMobile
} from '@/utils/device';
import {
  Toast
} from 'antd-mobile';

// 这是必须要写的，用来创建一些设置
function setupWebViewJavascriptBridge(callback) {
  // Android使用
  if (isAndroid) {
    if (window.WebViewJavascriptBridge) {
      callback(window.WebViewJavascriptBridge);
    } else {
      document.addEventListener(
        'WebViewJavascriptBridgeReady',
        function () {
          callback(window.WebViewJavascriptBridge);
        },
        false
      );
    }
    console.log('tag', '安卓');
  }

  // iOS使用
  if (isiOS) {
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge);
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback);
    }
    window.WVJBCallbacks = [callback];
    const WVJBIframe = document.createElement('iframe');
    WVJBIframe.style.display = 'none';
    WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
    document.documentElement.appendChild(WVJBIframe);
    setTimeout(function () {
      document.documentElement.removeChild(WVJBIframe);
    }, 0);
    console.log('tag', 'ios');
  }
}
// 注册回调函数，第一次连接时调用 初始化函数(android需要初始化,ios不用)
setupWebViewJavascriptBridge(function (bridge) {
  if (isAndroid) {
    // 初始化
    bridge.init(function (message, responseCallback) {
      console.log('JS got a message', message);
      const data = {
        'Javascript Responds': '测试中文!'
      };
      console.log('JS responding with', data);
      responseCallback(data);
    });
  }
});

const jsBridge = {
  /**
   * @description js调APP方法
   * @param {String} name app提供的方法名
   * @param {Object} data 传给app的数据
   * @returns {Object} Promise
   */
  callHandler(name, data) {
    // if (!isMobile) {
    //   return;
    // }
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge(function (bridge) {
        bridge.callHandler(name, JSON.stringify(data), response => {
          resolve(JSON.parse(response));
        });
      });
    });
  },
  // APP调js方法
  registerHandler(name, callback) {
    // if (!isMobile) {
    //   return;
    // }
    setupWebViewJavascriptBridge(function (bridge) {
      bridge.registerHandler(name, function (data, responseCallback) {
        callback(data, responseCallback);
      });
    });
  }
};

export default jsBridge;
