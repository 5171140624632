/*
* 车辆下架页面
*/
import React from 'react';
import { LabelItem, InputItem, InputNumberItem, Card, PickerItem, DatePickerItem, TopNavBar, Footer, Button, NewInputItem } from '@/components';
import {
  TitleSingleUpload
} from '@/containers/common';
import {
  mountGlobalLoading,
  unMountGlobalLoading
} from '@/components/loading/RenderGlobalLoading';
import { Toast } from 'antd-mobile';
import { Switch } from 'antd-mobile-v5';
import API from '@/api';
import DALCommon from '@/constants/common/state';
import styles from './style/CarOffShelve.module.scss';
import autobind from 'autobind-decorator';
import {
  formatDateString,
  formatDateObject
} from '@/utils/date';
import { convertUnitNumPrice } from '@/utils/moneyFormat';

class CarOffShelve extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicId: null,
      isCadillacOpen: false,
      isCarHomeOpen: false,
      isTianMaoOpen: false,
      isTikTokOpen: false,
      kdChannel: 0, // 接口返回的渠道0关闭 打开
      ershouChannel: 0, // 接口返回的渠道0关闭 打开
      tmailChannel: 0, // 接口返回的渠道0关闭 打开
      dyChannel: 0, // 接口返回的渠道0关闭 打开
      transactionStatus: [0],
      realPrice: '', // 实际成交金额
      saleRealPrice: '', // 销售成本
      financeRetailNum: '', // 金融零售合同编号
      invoiceTransactionCode: '',
      invoiceNum: '',
      makeInvoiceDate: null, // 交易发票开票时间
      makeInvoiceImage:
      {
        code: '2-8',
        url: '',
        bgImage: 'yh45',
        qczj: ''
      },
      transactionStatusList: [
        {
          label: '未成交',
          value: 0
        },
        {
          label: '已成交',
          value: 1
        }
      ]
    };
  }

  componentDidMount() {
    // if (this.props.location.query && this.props.location.query.basicId) {
    // const basicId = this.props.location.query.basicId;
    // this.state.basicId = basicId;
    // console.log(basicId);
    // this.getData();
    // }
    this.state.basicId = DALCommon.basicId;
    this.getData();
  }

  getData() {
    API.CarSource.getCarOffShelve({
      basicId: this.state.basicId
    }).then(res => {
      if (res.code === 200 && res.data !== null) {
        //
        this.setState({
          transactionStatus: [res.data.orderStatus],
          realPrice: res.data.realPrice,
          saleRealPrice: res.data.saleRealPrice,
          financeRetailNum: res.data.financeRetailNum,
          invoiceTransactionCode: res.data.invoiceTransactionCode,
          invoiceNum: res.data.invoiceNum,
          isCadillacOpen: res.data.kdChannel === 1,
          isCarHomeOpen: res.data.ershouChannel === 1,
          isTianMaoOpen: res.data.tmailChannel === 1,
          isTikTokOpen: res.data.dyChannel === 1,
          kdChannel: res.data.kdChannel !== null ? res.data.kdChannel : 0,
          ershouChannel: res.data.ershouChannel !== null ? res.data.ershouChannel : 0,
          tmailChannel: res.data.tmailChannel !== null ? res.data.tmailChannel : 0,
          dyChannel: res.data.dyChannel !== null ? res.data.dyChannel : 0
        });
      }
      // console.log(this.state.transactionStatus);
    });
  }

  @autobind
  updateSoureChange(val, type) {
    if (val[0] === 0) {
      this.setState({
        [type]: val,
        realPrice: '',
        saleRealPrice: '',
        financeRetailNum: '',
        invoiceNum: '',
        invoiceTransactionCode: '',
        makeInvoiceDate: null,
        makeInvoiceImage: {
          code: '2-8',
          url: '',
          bgImage: 'yh45',
          qczj: ''
        },
        isCadillacOpen: this.state.kdChannel === 1,
        isCarHomeOpen: this.state.ershouChannel === 1,
        isTianMaoOpen: this.state.tmailChannel === 1,
        isTikTokOpen: this.state.dyChannel === 1
      });
    } else if (val[0] === 1) {
      this.setState({
        [type]: val,
        realPrice: '',
        saleRealPrice: '',
        financeRetailNum: '',
        invoiceTransactionCode: '',
        invoiceNum: '',
        makeInvoiceDate: null,
        makeInvoiceImage: {
          code: '2-8',
          url: '',
          bgImage: 'yh45',
          qczj: ''
        },
        isCadillacOpen: false,
        isCarHomeOpen: false,
        isTianMaoOpen: false,
        isTikTokOpen: false
      });
    }
  }

  @autobind
  updateTimeChange(val) {
    this.setState({
      makeInvoiceDate: val
    });
  }

  onImageChange = (file, type, photoType) => {
    this.setState({
      makeInvoiceImage: {
        code: '2-8',
        url: file.url,
        bgImage: 'yh45',
        qczj: file.qczj
      }
    });
    console.log('11:', file);
  }

  submitCarInfo = () => {
    if (this.state.transactionStatus[0] === 1) {
      if (!this.state.realPrice || this.state.realPrice === '') {
        Toast.info('请输入实际成交金额');
        return;
      }
      if (!this.state.makeInvoiceDate || this.state.makeInvoiceDate === '') {
        Toast.info('请输入交易发票开票时间');
        return;
      }
      if (!this.state.makeInvoiceImage.url || this.state.makeInvoiceImage.url === '') {
        Toast.info('请上传交易发票照片');
        return;
      }
      if (!this.state.invoiceNum || this.state.invoiceNum === '') {
        Toast.info('请输入发票号码');
        return;
      }
      if (!this.state.invoiceTransactionCode || this.state.invoiceTransactionCode === '') {
        Toast.info('请输入发票交易代码');
        return;
      }
    }

    mountGlobalLoading();
    // 提交
    const param = {
      basicId: this.state.basicId,
      kdChannel: this.state.isCadillacOpen ? 1 : 0,
      ershouChannel: this.state.isCarHomeOpen ? 1 : 0,
      tmailChannel: this.state.isTianMaoOpen ? 1 : 0,
      dyChannel: this.state.isTikTokOpen ? 1 : 0,
      orderStatus: (this.state.transactionStatus && this.state.transactionStatus.length > 0) ? this.state.transactionStatus[0] : '',
      realPrice: this.state.realPrice,
      saleRealPrice: this.state.saleRealPrice,
      financeRetailNum: this.state.financeRetailNum,
      invoiceNum: this.state.invoiceNum,
      invoiceTransactionCode: this.state.invoiceTransactionCode,
      makeInvoiceDate: formatDateString(this.state.makeInvoiceDate),
      makeInvoiceImage: this.state.makeInvoiceImage
    };
    API.CarSource.updateCarOffShelve(param).then(res => {
      unMountGlobalLoading();
      if (res.code === 200) {
        //
        Toast.info('提交成功');
        this.props.history.goBack();
      }
    });
  }

  updateChange(e, type) {
    this.setState({
      [type]: e.target.value
    });
  }

  render() {
    return (
      <div className={styles.CarOffShelve}>
        <TopNavBar title={'车辆下架'} />
        <div className={styles.cardWrap}>
          <Card>
            <div className={styles.title}>订单信息</div>
            <LabelItem label="成交状态">
              <PickerItem pickerData={this.state.transactionStatusList} placeholder="请选择成交状态" type='transactionStatus' onOk={this.updateSoureChange.bind(this)} value={this.state.transactionStatus} />
            </LabelItem>
            {this.state.transactionStatus[0] === 1 && <NewInputItem title='实际成交金额' isRequisite={true} placeholder="请输入实际成交金额"
              onChange={(e) => this.updateChange(e, 'realPrice')}
              value={this.state.realPrice} type="number"
              pattern="\d*"
              moneyCapitals={convertUnitNumPrice(this.state.realPrice)} />}
            {this.state.transactionStatus[0] === 1 && <NewInputItem title='销售成本' isRequisite={false} placeholder="请输入销售成本"
              onChange={(e) => this.updateChange(e, 'saleRealPrice')}
              value={this.state.saleRealPrice} type="number"
              pattern="\d*"
              moneyCapitals={convertUnitNumPrice(this.state.saleRealPrice)} />}
            {this.state.transactionStatus[0] === 1 && <LabelItem label="金融零售合同编号" bottomBorderVisible={true} handleContent="" isRequisite={false}>
              <InputNumberItem placeholder="请输入合同编号" value={this.state.financeRetailNum} onChange={(e) => this.updateChange(e, 'financeRetailNum')} />
            </LabelItem>}
            {this.state.transactionStatus[0] === 1 && <LabelItem label="发票交易代码" isRequisite={true} bottomBorderVisible={true} handleContent="">
              <InputNumberItem placeholder="请输入发票交易代码" value={this.state.invoiceTransactionCode} onChange={(e) => this.updateChange(e, 'invoiceTransactionCode')} />
            </LabelItem>}
            {this.state.transactionStatus[0] === 1 && <LabelItem label="发票号码" bottomBorderVisible={true} isRequisite={true} handleContent="">
              <InputNumberItem placeholder="请输入发票号码" value={this.state.invoiceNum} onChange={(e) => this.updateChange(e, 'invoiceNum')} />
            </LabelItem>}
            {this.state.transactionStatus[0] === 1 && <LabelItem label="开票时间" isRequisite={true}>
              <DatePickerItem placeholder="请选择交易发票开票时间" onOk={this.updateTimeChange} value={this.state.makeInvoiceDate} minDate={this.props.year ? this.props.year : '2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>}
            {this.state.transactionStatus[0] === 1 && <div className={styles.imageView}><TitleSingleUpload title="交易发票照片" isRequire={true} onChange={this.onImageChange} file={this.state.makeInvoiceImage.url} /></div>}
          </Card>
          <Card>
            <div className={styles.title}>展示渠道<div class={styles.tips}>（按钮为关代表从该渠道下架）</div></div>
            <div className={styles.openBg}>
              <span className={styles.openTitle}>凯迪商城</span>
              <Switch
                checked={this.state.isCadillacOpen}
                className={styles.switchBg}
                checkedText='开'
                uncheckedText='关'
                disabled={this.state.transactionStatus[0] === 1}
                onChange={(e) => {
                  this.setState({
                    isCadillacOpen: !this.state.isCadillacOpen
                  });
                }} />
            </div>
            <div className={styles.openBg}>
              <span className={styles.openTitle}>二手车之家</span>
              <Switch
                checked={this.state.isCarHomeOpen}
                className={styles.switchBg}
                checkedText='开'
                uncheckedText='关'
                disabled={this.state.transactionStatus[0] === 1}
                onChange={(e) => {
                  this.setState({
                    isCarHomeOpen: !this.state.isCarHomeOpen
                  });
                }} />
            </div>
            <div className={styles.openBg}>
              <span className={styles.openTitle}>天猫商城</span>
              <Switch
                checked={this.state.isTianMaoOpen}
                className={styles.switchBg}
                checkedText='开'
                uncheckedText='关'
                disabled={this.state.transactionStatus[0] === 1}
                onChange={(e) => {
                  this.setState({
                    isTianMaoOpen: !this.state.isTianMaoOpen
                  });
                }} />
            </div>
            <div className={styles.openLastBg}>
              <span className={styles.openTitle}>抖音商城</span>
              <Switch
                checked={this.state.isTikTokOpen}
                className={styles.switchBg}
                checkedText='开'
                uncheckedText='关'
                disabled={this.state.transactionStatus[0] === 1}
                onChange={(e) => {
                  this.setState({
                    isTikTokOpen: !this.state.isTikTokOpen
                  });
                }} />
            </div>
          </Card>
          <Footer>
            <Button label="提交" type="primary" onClick={this.submitCarInfo} disabled={false} />
          </Footer>
        </div>
      </div>
    );
  }
}
export default CarOffShelve;