import {
  decorate,
  observable,
  action,
  computed
} from 'mobx';
import API from '@/api';
import {
  Toast
} from 'antd-mobile';
import DALCommon from '@/constants/common/state';
import {
  formatDateString
} from '@/utils/date';

class DALCarImage {
  static getInstance() {
    if (!DALCarImage.instance) {
      DALCarImage.instance = new DALCarImage();
    }
    return DALCarImage.instance;
  }

  // 驾驶证
  '2-1' = '';
  // 产权证1-2
  '2-6' = '';
  // 产权证3-4
  '2-7' = '';
  // 营业执照
  '2-3' = '';
  // 身份证（正面）
  '2-4' = '';
  // 身份证（反面）
  '2-5' = '';
  // 新车发票
  '2-2' = '';
  // 其他照片
  '3-1' = [];

  // 左前45°照
  '1-1' = '';
  // 副驾座
  '1-4' = '';
  // 右后45°照
  '1-2' = '';
  // 后排座椅照
  '1-5' = '';
  // 中控照
  '1-6' = '';
  // 主驾座椅照
  '1-3' = '';
  // 车辆公里数
  '1-9' = '';
  // 发动机舱
  '1-7' = '';
  // 车辆铭牌
  '1-8' = '';
  // 车辆的VIN码照片
  '1-30' = '';
  // 正前照
  '1-18' = '';
  // 左侧身照
  '1-16' = '';
  // 左后45°照
  '1-15' = '';
  // 正后照
  '1-22' = '';
  // 右侧身照
  '1-17' = '';
  // 右前45°照
  '1-10' = '';
  // 后备箱门打开照
  '1-11' = '';
  // 行李箱照
  '1-12' = '';
  // 主驾车门照
  '1-13' = '';
  // 副驾门照
  '1-14' = '';
  // 音响系统照
  '1-19' = '';
  // 空调系统照
  '1-20' = '';
  // 车顶棚照
  '1-21' = '';
  // 地盘照
  '1-23' = '';
  // 轮胎状态照
  '1-24' = '';
  // 备胎照
  '1-25' = '';
  // 充电线照
  '1-26' = '';
  // 诊断数据照
  '1-27' = '';
  // 保养历史照
  '1-28' = '';
  // 高压电池包健康度照（SOH）
  '1-29' = '';
  // 其他缺陷照片
  '11-1' = [];

  // 图片类型
  type = '';
  // 所有图片集合
  allPhotos = []

  // 更新数据
  @action.bound
  async updateData(key, value, isUpdate = 1) {
    this[key] = value;
    this.type = key;
    if (isUpdate === 1) {
      await this.updateImage(key, value);
      await this.getImageData();
    }
  }

  // 更新图片
  @action.bound
  async getImageData() {
    const res = await API.Valuation.apiSelectPhoto({
      carId: DALCommon.carId
    });

    if (res.code === 200) {
      !!res.data.otherPhotos && this.updateData('11-1', res.data.otherPhotos.split(','), 2);
      !!res.data.otherPicture && this.updateData('3-1', res.data.otherPicture.split(','), 2);
      if (res.data.papersPictureDTO) {
        !!res.data.papersPictureDTO.driverPicture && this.updateData('2-1', res.data.papersPictureDTO.driverPicture, 2);
        !!res.data.papersPictureDTO.propertyFirst && this.updateData('2-6', res.data.papersPictureDTO.propertyFirst, 2);
        !!res.data.papersPictureDTO.propertyLast && this.updateData('2-7', res.data.papersPictureDTO.propertyLast, 2);
        !!res.data.papersPictureDTO.businessPicture && this.updateData('2-3', res.data.papersPictureDTO.businessPicture, 2);
        !!res.data.papersPictureDTO.idCardByFront && this.updateData('2-4', res.data.papersPictureDTO.idCardByFront, 2);
        !!res.data.papersPictureDTO.idCarByTail && this.updateData('2-5', res.data.papersPictureDTO.idCarByTail, 2);
        !!res.data.papersPictureDTO.invPicture && this.updateData('2-2', res.data.papersPictureDTO.invPicture, 2);
      }
      if (res.data.carPhotoDTO) {
        // 左前45°照
        !!res.data.carPhotoDTO.leftFront && this.updateData('1-1', res.data.carPhotoDTO.leftFront, 2);
        // 副驾座
        !!res.data.carPhotoDTO.rightFrontDoor && this.updateData('1-4', res.data.carPhotoDTO.rightFrontDoor, 2);
        // 右后45°照
        !!res.data.carPhotoDTO.rightRear && this.updateData('1-2', res.data.carPhotoDTO.rightRear, 2);
        // 后排座椅照
        !!res.data.carPhotoDTO.leftRearDoor && this.updateData('1-5', res.data.carPhotoDTO.leftRearDoor, 2);
        // 中控照
        !!res.data.carPhotoDTO.centerConsole && this.updateData('1-6', res.data.carPhotoDTO.centerConsole, 2);
        // 主驾座椅照
        !!res.data.carPhotoDTO.leftFrontDoor && this.updateData('1-3', res.data.carPhotoDTO.leftFrontDoor, 2);
        // 车辆公里数
        !!res.data.carPhotoDTO.carKilometres && this.updateData('1-9', res.data.carPhotoDTO.carKilometres, 2);
        // 发动机舱
        !!res.data.carPhotoDTO.engineWarehouse && this.updateData('1-7', res.data.carPhotoDTO.engineWarehouse, 2);
        // 车辆铭牌
        !!res.data.carPhotoDTO.nameplate && this.updateData('1-8', res.data.carPhotoDTO.nameplate, 2);
        // 车辆的VIN码照片
        !!res.data.carPhotoDTO.vin && this.updateData('1-30', res.data.carPhotoDTO.vin, 2);
        // 正前照
        !!res.data.carPhotoDTO.former && this.updateData('1-18', res.data.carPhotoDTO.former, 2);
        // 左侧身照
        !!res.data.carPhotoDTO.leftSide && this.updateData('1-16', res.data.carPhotoDTO.leftSide, 2);
        // 左后45°照
        !!res.data.carPhotoDTO.leftRear && this.updateData('1-15', res.data.carPhotoDTO.leftRear, 2);
        // 正后照
        !!res.data.carPhotoDTO.positiveAfter && this.updateData('1-22', res.data.carPhotoDTO.positiveAfter, 2);
        // 右侧身照
        !!res.data.carPhotoDTO.rightSide && this.updateData('1-17', res.data.carPhotoDTO.rightSide, 2);
        // 右前45°照
        !!res.data.carPhotoDTO.rightFront && this.updateData('1-10', res.data.carPhotoDTO.rightFront, 2);
        // 后备箱门打开照
        !!res.data.carPhotoDTO.bootOpen && this.updateData('1-11', res.data.carPhotoDTO.bootOpen, 2);
        // 行李箱照
        !!res.data.carPhotoDTO.boot && this.updateData('1-12', res.data.carPhotoDTO.boot, 2);
        // 主驾车门照
        !!res.data.carPhotoDTO.driverDoor && this.updateData('1-13', res.data.carPhotoDTO.driverDoor, 2);
        // 副驾门照
        !!res.data.carPhotoDTO.passengerDor && this.updateData('1-14', res.data.carPhotoDTO.passengerDor, 2);
        // 音响系统照
        !!res.data.carPhotoDTO.soundSystem && this.updateData('1-19', res.data.carPhotoDTO.soundSystem, 2);
        // 空调系统照
        !!res.data.carPhotoDTO.airConditioning && this.updateData('1-20', res.data.carPhotoDTO.airConditioning, 2);
        // 车顶棚照
        !!res.data.carPhotoDTO.roof && this.updateData('1-21', res.data.carPhotoDTO.roof, 2);
        // 地盘照
        !!res.data.carPhotoDTO.underpan && this.updateData('1-23', res.data.carPhotoDTO.underpan, 2);
        // 轮胎状态照
        !!res.data.carPhotoDTO.tyre && this.updateData('1-24', res.data.carPhotoDTO.tyre, 2);
        // 备胎照
        !!res.data.carPhotoDTO.spareTyre && this.updateData('1-25', res.data.carPhotoDTO.spareTyre, 2);
        // 充电线照
        !!res.data.carPhotoDTO.chargingLine && this.updateData('1-26', res.data.carPhotoDTO.chargingLine, 2);
        // 诊断数据照
        !!res.data.carPhotoDTO.diagnosticData && this.updateData('1-27', res.data.carPhotoDTO.diagnosticData, 2);
        // 保养历史照
        !!res.data.carPhotoDTO.maintainHistory && this.updateData('1-28', res.data.carPhotoDTO.maintainHistory, 2);
        // 高压电池包健康度照（SOH）
        !!res.data.carPhotoDTO.batteryHealth && this.updateData('1-29', res.data.carPhotoDTO.batteryHealth, 2);
      }
      if (res.data.allPhotos && res.data.allPhotos.length > 0) {
        this.updateData('allPhotos', res.data.allPhotos, 2);
      }
    }
  }

  // 上传保存
  @action.bound
  async updateImage(type, value) {
    const _data = {
      carId: DALCommon.carId,
      type,
      photoList: typeof value === 'string' ? [value] : value
    };
    const res = await API.Valuation.apiSumbitApplyAuction(_data);
    return res.code === 200;
  }
}

decorate(DALCarImage, {
  // 驾驶证
  '2-1': observable,
  // 产权证1-2
  '2-6': observable,
  // 产权证3-4
  '2-7': observable,
  // 营业执照
  '2-3': observable,
  // 身份证（正面）
  '2-4': observable,
  // 身份证（反面）
  '2-5': observable,
  // 新车发票
  '2-2': observable,
  // 其他照片
  '3-1': observable,

  // 左前45°照
  '1-1': observable,
  // 副驾座
  '1-4': observable,
  // 右后45°照
  '1-2': observable,
  // 后排座椅照
  '1-5': observable,
  // 中控照
  '1-6': observable,
  // 主驾座椅照
  '1-3': observable,
  // 车辆公里数
  '1-9': observable,
  // 发动机舱
  '1-7': observable,
  // 车辆铭牌
  '1-8': observable,
  // 车辆的VIN码照片
  '1-30': observable,
  // 正前照
  '1-18': observable,
  // 左侧身照
  '1-16': observable,
  // 左后45°照
  '1-15': observable,
  // 正后照
  '1-22': observable,
  // 右侧身照
  '1-17': observable,
  // 右前45°照
  '1-10': observable,
  // 后备箱门打开照
  '1-11': observable,
  // 行李箱照
  '1-12': observable,
  // 主驾车门照
  '1-13': observable,
  // 副驾门照
  '1-14': observable,
  // 音响系统照
  '1-19': observable,
  // 空调系统照
  '1-20': observable,
  // 车顶棚照
  '1-21': observable,
  // 地盘照
  '1-23': observable,
  // 轮胎状态照
  '1-24': observable,
  // 备胎照
  '1-25': observable,
  // 充电线照
  '1-26': observable,
  // 诊断数据照
  '1-27': observable,
  // 保养历史照
  '1-28': observable,
  // 高压电池包健康度照（SOH）
  '1-29': observable,
  // 其他缺陷照片
  '11-1': observable
});

export default DALCarImage.getInstance();
