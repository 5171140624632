/*
 * @Descripttion:车辆配置 -内部配置
 * @Author: zhongyuanrui
 * @Date: 2019-09-17 11:40:07
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-23 18:21:55
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TitleCard } from '@/components';
import ConfigItem from '../configItem/ConfigItem';

function InsideConfig(props) {
  const data = props.data;
  return (
    <TitleCard title="内部配置" showHeaderLine={false}>
      {/* <ConfigItem label="后倒车雷达" value={data.a} /> */}
      {/* <ConfigItem label="手机无线充电" value={data.a} /> */}
      {/* <ConfigItem label="方向盘记忆设置" value={data.a} /> */}
      {/* <ConfigItem label="内饰材质" value={data.a} /> */}
      {/* <ConfigItem label="ECO节能模式" value={data.a} /> */}
      <ConfigItem label="后驻车雷达" value={data && data.backingRadar ? data.backingRadar : ''} />
      <ConfigItem label="行车电脑显示屏" value={data && data.computerScreenOfDriving ? data.computerScreenOfDriving : ''} />
      <ConfigItem label="方向盘电动调节" value={data && data.steeringWheelElectricAdjustment ? data.steeringWheelElectricAdjustment : ''} />
      <ConfigItem label="定速巡航" value={data && data.cruiseControl ? data.cruiseControl : ''} />
      {/* <ConfigItem label="香氛系统" value={data.a} /> */}
      <ConfigItem label="倒车视频影像" value={data && data.rearVideoMonitor ? data.rearVideoMonitor : ''} />
      {/* <ConfigItem label="全液晶仪表盘尺寸" value={data.a} /> */}
      {/* <ConfigItem label="车载空气净化器" value={data.a} /> */}
      <ConfigItem label="前驻车雷达" value={data && data.backingRadar ? data.backingRadar : ''} />
      {/* <ConfigItem label="皮质方向盘" value={data.a} /> */}
      {/* <ConfigItem label="主动降噪" value={data.a} /> */}
      <ConfigItem label="方向盘换挡" value={data && data.steeringWheelShift ? data.steeringWheelShift : ''} />
      <ConfigItem label="多功能方向盘" value={data && data.multiFunctionSteeringWheel ? data.multiFunctionSteeringWheel : ''} />
      {/* <ConfigItem label="踏板阻尼可调" value={data.a} /> */}
      <ConfigItem label="HUD抬头数字显示" value={data && data.headsUpDisplay ? data.headsUpDisplay : ''} />
      <ConfigItem label="全液晶仪表盘" value={data && data.lcdPanel ? data.lcdPanel : ''} />
      {/* <ConfigItem label="车载行车记录仪" value={data.a} /> */}
      <ConfigItem label="方向盘调节" value={data && data.steeringWheelAdjustment ? data.steeringWheelAdjustment : ''} />
      {/* <ConfigItem label="指南针" value={data.a} /> */}
      <ConfigItem label="方向盘加热" value={data && data.lhz ? data.lhz : ''} />
    </TitleCard>
  );
}

InsideConfig.propTypes = {
  data: PropTypes.object
};

export default InsideConfig;
