/*
 * @Descripttion:车辆配置 - 底盘旋转
 * @Author: zhongyuanrui
 * @Date: 2019-09-17 11:40:07
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-23 18:04:35
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TitleCard } from '@/components';
import ConfigItem from '../configItem/ConfigItem';

function ChassisRotation(props) {
  const data = props.data;
  return (
    <TitleCard title="底盘旋转" showHeaderLine={false}>
      <ConfigItem label="车体结构" value={data && data.bodyStructure ? data.bodyStructure : ''} />
      <ConfigItem label="助力类型" value={data && data.assistanttype ? data.assistanttype : ''} />
      {/* <ConfigItem label="四驱形式" value={data.a} /> */}
      <ConfigItem label="前悬架类型" value={data && data.fronthang ? data.fronthang : ''} />
      {/* <ConfigItem label="四驱转换方式" value={data.a} /> */}
      <ConfigItem label="驱动方式" value={data && data.driveway ? data.driveway : ''} />
      <ConfigItem label="后悬架类型" value={data && data.backhang ? data.backhang : ''} />
      {/* <ConfigItem label="中央差速器结构" value={data.a} /> */}
    </TitleCard>
  );
}

ChassisRotation.propTypes = {
  data: PropTypes.object
};

export default ChassisRotation;
