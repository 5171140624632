import {
  get,
  post,
  postByJsonUrl,
  postByUrlencoded,
  indentifyApiUrl
} from '../http';

// 文件上传
export const apiUploadFile = (data) => {
  return post('/photomanager/uploadFile', data);
};

// 识别行驶证
export const identifyLicense = (data) => {
  return post(`${indentifyApiUrl}thirdPartyService/baidu/vehicle_license`, data);
};

// 识别vin
export const identifyVin = ({
  vin
}) => {
  return postByUrlencoded('/vehiclebasic/getVehicleInfo', {
    vin
  });
};

// 识别上牌地区
export const identifyLineArea = ({
  plateNumber
}) => {
  return postByUrlencoded('/vehiclebasic/getLineArea', {
    plateNumber
  });
};

// 获取城市列表
export const getProvinceList = () => {
  return get('/area/areaProvince');
};

// 获取城市列表
export const getCityList = ({
  provinceId
}) => {
  return get('/area/areaCity', {
    provinceId
  });
};

// 获取车系列表
export const getFamilyList = () => {
  return postByUrlencoded('/vehiclebasic/familyList');
};

// 获取车型列表
export const getVehicleList = ({
  familyId
}) => {
  return postByUrlencoded('/vehiclebasic/vehicleBasicList', {
    familyId
  });
};

// 获取车源列表
export const getCarList = (params) => {
  return postByJsonUrl('/app/carsource/list', params);
};

// 添加车源
export const addCar = (params) => {
  return postByJsonUrl('/app/carsource/select/add/carbasicinfo', params);
};

// 获取车源详情
export const getCarDetail = ({
  basicId
}) => {
  return postByUrlencoded('/app/carsource/list/select/detail', {
    basicId
  });
};

// 获取车源所有图片
export const getCarPhotos = ({
  basicId
}) => {
  return postByUrlencoded('/app/carsource/list/select/all/photos', {
    basicId
  });
};

// 更新车源所有图片
export const updateCarPhotos = (params) => {
  return postByJsonUrl('/app/carsource/select/update/photos',
    params
  );
};

// 编辑车辆信息 (查询)
export const getCarInfo = ({
  basicId
}) => {
  return postByUrlencoded('/app/carsource/list/select/carbasicinfo', {
    basicId
  });
};

// 编辑车辆信息（提交）
export const updateCarInfo = (params) => {
  return postByJsonUrl('/app/carsource/select/update/carbasicinfo',
    params
  );
};

// 车辆整备信息 (查询)
export const getMakereadyinfo = ({
  basicId
}) => {
  return postByUrlencoded('/app/carsource/list/select/makereadyinfo', {
    basicId
  });
};

// 车辆整备信息（提交）
export const updateMakereadyinfo = (params) => {
  return postByJsonUrl('/app/carsource/select/update/makereadyinfo',
    params
  );
};

// 车辆零售信息 (查询)
export const getRetailinfo = ({
  basicId
}) => {
  return postByUrlencoded('/app/carsource/list/select/retailinfo', {
    basicId
  });
};

// 车辆零售信息（提交）
export const updateRetailinfo = (params) => {
  return postByJsonUrl('/app/carsource/select/update/retailinfo',
    params
  );
};

// 车辆零售 查询标品45度角照片
export const getFamilyPhotos = ({
  basicId
}) => {
  return postByUrlencoded('/app/carsource/list/select/familyphotos', {
    basicId
  });
};

// 车辆零售 查询标品图片和销售价格
export const getCarFamilyPhotos = ({
  basicId
}) => {
  return postByUrlencoded('/app/carsource/list/select/standardPhotosAndPrice', {
    basicId
  });
};

// 车辆配置信息 (查询)
export const getSettinginfo = ({
  basicId
}) => {
  return postByUrlencoded('/app/carsource/setting', {
    basicId
  });
};

// 车辆入库 (查询)
export const getCarStoreinfo = ({
  basicId
}) => {
  return get('/app/carsource/queryPurchaseInfo', {
    basicId
  });
};

// 车辆入库查询买方单位信息
export const getBuyerNameList = ({
  blocNum
}) => {
  return get('/area/getBuyerInfo', {
    blocNum
  });
};

// 车辆入库（提交）
export const updateCarStoreinfo = (params) => {
  return postByJsonUrl('/app/carsource/updatePurchaseInfo',
    params
  );
};

// 车辆上架 (查询)
export const getCarOnShelve = ({
  basicId
}) => {
  return postByUrlencoded('/app/carsource/list/select/carsalesinfo/in', {
    basicId
  });
};

// 车辆上架 (提交)
export const updateCarOnShelve = (params) => {
  return postByJsonUrl('/app/carsource/select/update/carsalesinfo/in',
    params
  );
};

// 车辆下架 (查询)
export const getCarOffShelve = ({
  basicId
}) => {
  return postByUrlencoded('/app/carsource/list/select/carsalesinfo/out', {
    basicId
  });
};

// 车辆下架 (提交)
export const updateCarOffShelve = (params) => {
  return postByJsonUrl('/app/carsource/select/update/carsalesinfo/out',
    params
  );
};

// 根据经销商code 查询经销商信息
export const getDealerData = (params) => {
  return postByUrlencoded('/pc/dealerReport/reportDataByDealerH5',
    params
  );
};