/*
* 车辆整备页面
*/
import React from 'react';
import { LabelItem, InputItem, Card, PickerItem, DatePickerItem, TopNavBar, Footer, Button, NewInputItem } from '@/components';
import { Toast } from 'antd-mobile';
import { Switch } from 'antd-mobile-v5';
import autobind from 'autobind-decorator';
import API from '@/api';
import DALCommon from '@/constants/common/state';
import styles from './style/CarHostling.module.scss';
import { convertUnitNumPrice } from '@/utils/moneyFormat';

class CarHostling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicId: null,
      enterDate: null,
      outDate: null,
      isCleanPolish: false,
      isCleanCarIndoor: false,
      isCleanEngine: false,
      isCleanOther: false,
      cleanPolishFee: '0',
      cleanIndoorFee: '0',
      cleanEngineFee: '0',
      cleanOtherFee: '0',
      totalPrice: '0',
      totalPriceTip: ''
    };
  }

  componentDidMount() {
    // if (this.props.location.query && this.props.location.query.basicId) {
    // const basicId = this.props.location.query.basicId;
    // this.state.basicId = basicId;
    // this.getData();
    // console.log(basicId);
    // }
    this.state.basicId = DALCommon.basicId;
    this.getData();
  }

  componentWillUnmount() {
    this.state.basicId = null;
    this.state.enterDate = null;
    this.state.outDate = null;
    this.state.isCleanPolish = false;
    this.state.isCleanCarIndoor = false;
    this.state.isCleanEngine = false;
    this.state.isCleanOther = false;
    this.state.cleanPolishFee = '0';
    this.state.cleanIndoorFee = '0';
    this.state.cleanEngineFee = '0';
    this.state.cleanOtherFee = '0';
    this.state.totalPrice = '0';
    this.state.totalPriceTip = '';
  }

  getData = () => {
    //
    API.CarSource.getMakereadyinfo({
      basicId: this.state.basicId
    }).then(res => {
      if (res.code === 200) {
        //
        if (res.data) {
          this.setState({
            enterDate: res.data.makeInDate !== null ? res.data.makeInDate : null,
            outDate: res.data.makeOutDate !== null ? res.data.makeOutDate : null,
            isCleanPolish: (res.data.polisingType !== null && res.data.polisingType === 1),
            isCleanCarIndoor: (res.data.cleanInType !== null && res.data.cleanInType === 1),
            isCleanEngine: (res.data.cleanEngineType !== null && res.data.cleanEngineType === 1),
            isCleanOther: (res.data.otherType !== null && res.data.otherType === 1),
            totalPrice: res.data.makePirce !== null ? res.data.makePirce + '' : 0,
            totalPriceTip: res.data.makeDetail !== null ? res.data.makeDetail : '',
            cleanPolishFee: res.data.polisingPrice !== null ? res.data.polisingPrice + '' : 0,
            cleanIndoorFee: res.data.cleanInPrice !== null ? res.data.cleanInPrice + '' : 0,
            cleanEngineFee: res.data.cleanEnginePrice !== null ? res.data.cleanEnginePrice + '' : 0,
            cleanOtherFee: res.data.makeOtherPrice !== null ? res.data.makeOtherPrice + '' : 0
          });
        }
      } else {
        //
      }
    });
  }

  updatePolishChange(e, type) {
    this.state[type] = e.target.value;
    const price1 = Number(e.target.value);
    const price2 = Number(this.state.cleanIndoorFee);
    const price3 = Number(this.state.cleanEngineFee);
    const price4 = Number(this.state.cleanOtherFee);
    let total = price1 + price2 + price3 + price4;
    // console.log('1:' + price1);
    // console.log('1:' + price2);
    // console.log('1:' + price3);
    // console.log('1:' + price4);
    // console.log('2:' + total);
    if (total === 0) {
      total += '';
    }
    const totalTip = '全车抛光打蜡' + price1 + '元;' + '清洗内饰' + price2 + '元;' + '清洗发动机' + price3 + '元;' + '其他售后工单' + price4 + '元;';
    this.setState({
      totalPrice: total,
      totalPriceTip: totalTip,
      [type]: e.target.value
    });
  }

  updateIndoorChange(e, type) {
    this.state[type] = e.target.value;
    const price1 = Number(this.state.cleanPolishFee);
    const price2 = Number(e.target.value);
    const price3 = Number(this.state.cleanEngineFee);
    const price4 = Number(this.state.cleanOtherFee);
    let total = price1 + price2 + price3 + price4;
    if (total === 0) {
      total += '';
    }
    const totalTip = '全车抛光打蜡' + price1 + '元;' + '清洗内饰' + price2 + '元;' + '清洗发动机' + price3 + '元;' + '其他售后工单' + price4 + '元;';
    this.setState({
      totalPrice: total,
      totalPriceTip: totalTip,
      [type]: e.target.value
    });
  }

  updateEngineChange(e, type) {
    this.state[type] = e.target.value;
    const price1 = Number(this.state.cleanPolishFee);
    const price2 = Number(this.state.cleanIndoorFee);
    const price3 = Number(e.target.value);
    const price4 = Number(this.state.cleanOtherFee);
    let total = price1 + price2 + price3 + price4;
    if (total === 0) {
      total += '';
    }
    const totalTip = '全车抛光打蜡' + price1 + '元;' + '清洗内饰' + price2 + '元;' + '清洗发动机' + price3 + '元;' + '其他售后工单' + price4 + '元;';
    this.setState({
      totalPrice: total,
      totalPriceTip: totalTip,
      [type]: e.target.value
    });
  }

  updateOtherChange(e, type) {
    this.state[type] = e.target.value;
    const price1 = Number(this.state.cleanPolishFee);
    const price2 = Number(this.state.cleanIndoorFee);
    const price3 = Number(this.state.cleanEngineFee);
    const price4 = Number(e.target.value);
    let total = price1 + price2 + price3 + price4;
    if (total === 0) {
      total += '';
    }
    const totalTip = '全车抛光打蜡' + price1 + '元;' + '清洗内饰' + price2 + '元;' + '清洗发动机' + price3 + '元;' + '其他售后工单' + price4 + '元;';
    this.setState({
      totalPrice: total,
      totalPriceTip: totalTip,
      [type]: e.target.value
    });
  }

  @autobind
  updateEnterTimeChange(val) {
    this.setState({
      enterDate: val
    });
  }

  @autobind
  updateOutTimeChange(val) {
    this.setState({
      outDate: val
    });
  }

  submitCarInfo = () => {
    // 提交
    if (!this.state.enterDate) {
      Toast.info('请选择入场时间');
      return;
    }
    if (!this.state.outDate) {
      Toast.info('请选择出场时间');
      return;
    }
    if (!this.state.isCleanPolish && !this.state.isCleanCarIndoor && !this.state.isCleanEngine && !this.state.isCleanOther) {
      Toast.info('请至少填写一项整备明细');
      return;
    }
    if (this.state.isCleanPolish) {
      if (this.state.cleanPolishFee === null || this.state.cleanPolishFee === '') {
        Toast.info('请输入全车抛光打蜡金额');
        return;
      }
    }
    if (this.state.isCleanCarIndoor) {
      if (this.state.cleanIndoorFee === null || this.state.cleanIndoorFee === '') {
        Toast.info('请输入全车抛光打蜡金额');
        return;
      }
    }
    if (this.state.isCleanEngine) {
      if (this.state.cleanEngineFee === null || this.state.cleanEngineFee === '') {
        Toast.info('请输入全车抛光打蜡金额');
        return;
      }
    }
    if (this.state.isCleanOther) {
      if (this.state.cleanOtherFee === null || this.state.cleanOtherFee === '') {
        Toast.info('请输入全车抛光打蜡金额');
        return;
      }
    }
    const params = {
      basicId: this.state.basicId,
      makeInDate: this.state.enterDate,
      makeOutDate: this.state.outDate,
      polisingType: (this.state.isCleanPolish) ? 1 : 0,
      cleanInType: (this.state.isCleanCarIndoor) ? 1 : 0,
      cleanEngineType: (this.state.isCleanEngine) ? 1 : 0,
      otherType: (this.state.isCleanOther) ? 1 : 0,
      polisingPrice: this.state.cleanPolishFee,
      cleanInPrice: this.state.cleanIndoorFee,
      cleanEnginePrice: this.state.cleanEngineFee,
      makeOtherPrice: this.state.cleanOtherFee,
      makePirce: this.state.totalPrice,
      makeDetail: this.state.totalPriceTip
    };
    const res = API.CarSource.updateMakereadyinfo(params).then(res => {
      if (res.code === 200) {
        Toast.info('提交成功');
        this.props.history.goBack();
      }
    });
  }

  render() {
    return (
      <div className={styles.CarHostling}>
        <TopNavBar title={'车辆整备'}></TopNavBar>
        <div className={styles.cardWrap}>
          <Card>
            <div className={styles.title}>整备信息</div>
            <LabelItem label="入场时间" isRequisite={true}>
              <DatePickerItem placeholder="请选择入场时间" onOk={this.updateEnterTimeChange} value={this.state.enterDate} minDate={this.props.year ? this.props.year : '2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
            <LabelItem label="出场时间" isRequisite={true}>
              <DatePickerItem placeholder="请选择出场时间" onOk={this.updateOutTimeChange} value={this.state.outDate} minDate={this.props.year ? this.props.year : '2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
            <NewInputItem title='整备成本' isRequisite={true} placeholder="请输入整备成本" onChange={(e) => this.updateChange(e, 'totalPrice')}
              value={this.state.totalPrice} type="number"
              pattern="\d*"
              disabled={true}
              moneyCapitals={convertUnitNumPrice(this.state.totalPrice)} />
            <LabelItem label="整备内容" isRequisite={true} bottomBorderVisible={true}>
              {/* <InputItem placeholder="整备内容" value={this.state.totalPriceTip} disabled={true} /> */}
              <span>{this.state.totalPriceTip}</span>
            </LabelItem>
          </Card>
          <Card>
            <div className={styles.title}>整备明细</div>
            <div className={styles.isHasPolishedBg}>
              <Switch
                checked={this.state.isCleanPolish}
                className={styles.switchBg}
                checkedText='开'
                uncheckedText='关'
                onChange={(e) => {
                  if (!e) {
                    // 关闭
                    const price1 = Number('');
                    const price2 = Number(this.state.cleanIndoorFee);
                    const price3 = Number(this.state.cleanEngineFee);
                    const price4 = Number(this.state.cleanOtherFee);
                    const total = price1 + price2 + price3 + price4;
                    const totalTip = '全车抛光打蜡' + price1 + '元;' + '清洗内饰' + price2 + '元;' + '清洗发动机' + price3 + '元;' + '其他售后工单' + price4 + '元;';
                    this.setState({
                      cleanPolishFee: 0,
                      totalPrice: total + '',
                      totalPriceTip: totalTip
                    });
                  };
                  this.setState({
                    isCleanPolish: !this.state.isCleanPolish
                  });
                }} />
              <NewInputItem title='全车抛光打蜡' isRequisite={true} placeholder="请输入" onChange={(e) => this.updatePolishChange(e, 'cleanPolishFee')}
                value={this.state.cleanPolishFee} type="number"
                pattern="\d*"
                disabled={!this.state.isCleanPolish}
                moneyCapitals={convertUnitNumPrice(this.state.cleanPolishFee)} />
            </div>
            <div className={styles.isHasPolishedBg}>
              <Switch
                checked={this.state.isCleanCarIndoor}
                className={styles.switchBg}
                checkedText='开'
                uncheckedText='关'
                onChange={(e) => {
                  if (!e) {
                    // 关闭
                    const price1 = Number(this.state.cleanPolishFee);
                    const price2 = Number('');
                    const price3 = Number(this.state.cleanEngineFee);
                    const price4 = Number(this.state.cleanOtherFee);
                    const total = price1 + price2 + price3 + price4;
                    const totalTip = '全车抛光打蜡' + price1 + '元;' + '清洗内饰' + price2 + '元;' + '清洗发动机' + price3 + '元;' + '其他售后工单' + price4 + '元;';
                    this.setState({
                      cleanIndoorFee: 0,
                      totalPrice: total + '',
                      totalPriceTip: totalTip
                    });
                  };
                  this.setState({
                    isCleanCarIndoor: !this.state.isCleanCarIndoor
                  });
                }} />
              <NewInputItem title='清洗内饰' isRequisite={true} placeholder="请输入" onChange={(e) => this.updateIndoorChange(e, 'cleanIndoorFee')}
                value={this.state.cleanIndoorFee} type="number"
                pattern="\d*"
                disabled={!this.state.isCleanCarIndoor}
                moneyCapitals={convertUnitNumPrice(this.state.cleanIndoorFee)} />
            </div>
            <div className={styles.isHasPolishedBg}>
              <Switch
                checked={this.state.isCleanEngine}
                className={styles.switchBg}
                checkedText='开'
                uncheckedText='关'
                onChange={(e) => {
                  if (!e) {
                    // 关闭
                    const price1 = Number(this.state.cleanPolishFee);
                    const price2 = Number(this.state.cleanIndoorFee);
                    const price3 = Number('');
                    const price4 = Number(this.state.cleanOtherFee);
                    const total = price1 + price2 + price3 + price4;
                    const totalTip = '全车抛光打蜡' + price1 + '元;' + '清洗内饰' + price2 + '元;' + '清洗发动机' + price3 + '元;' + '其他售后工单' + price4 + '元;';
                    this.setState({
                      cleanEngineFee: 0,
                      totalPrice: total + '',
                      totalPriceTip: totalTip
                    });
                  };
                  this.setState({
                    isCleanEngine: !this.state.isCleanEngine
                  });
                }} />
              <NewInputItem title='清洗发动机' isRequisite={true} placeholder="请输入" onChange={(e) => this.updateEngineChange(e, 'cleanEngineFee')}
                value={this.state.cleanEngineFee} type="number"
                pattern="\d*"
                disabled={!this.state.isCleanEngine}
                moneyCapitals={convertUnitNumPrice(this.state.cleanEngineFee)} />
            </div>
            <div className={styles.isHasPolishedBg}>
              <Switch
                checked={this.state.isCleanOther}
                className={styles.switchBg}
                checkedText='开'
                uncheckedText='关'
                onChange={(e) => {
                  if (!e) {
                    // 关闭
                    const price1 = Number(this.state.cleanPolishFee);
                    const price2 = Number(this.state.cleanIndoorFee);
                    const price3 = Number(this.state.cleanEngineFee);
                    const price4 = Number('');
                    const total = price1 + price2 + price3 + price4;
                    const totalTip = '全车抛光打蜡' + price1 + '元;' + '清洗内饰' + price2 + '元;' + '清洗发动机' + price3 + '元;' + '其他售后工单' + price4 + '元;';
                    this.setState({
                      cleanOtherFee: 0,
                      totalPrice: total + '',
                      totalPriceTip: totalTip
                    });
                  };
                  this.setState({
                    isCleanOther: !this.state.isCleanOther
                  });
                }} />
              <NewInputItem title='其他售后工单' isRequisite={true} placeholder="请输入" onChange={(e) => this.updateOtherChange(e, 'cleanOtherFee')}
                value={this.state.cleanOtherFee} type="number"
                pattern="\d*"
                disabled={!this.state.isCleanOther}
                moneyCapitals={convertUnitNumPrice(this.state.cleanOtherFee)} />
            </div>
            <Footer>
              <Button label="提交" type="primary" onClick={this.submitCarInfo} disabled={false} />
            </Footer>
          </Card>
        </div>
      </div>
    );
  }
}
export default CarHostling;