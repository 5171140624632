import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  MoreUpload,
  NewUpload
} from '@/containers/common';
import styles from './style/carBaseParams.module.scss';
import autobind from 'autobind-decorator';
import { observer, inject } from 'mobx-react';
import { changeFileToBlob } from '@/utils/image';
import API from '@/api';
import { Toast } from 'antd-mobile';

@inject('DALCarValuation')
@observer
class CarBaseParams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos1: props.DALCarValuation.photos1 // 车辆照片
    };
    console.log(this.state.photos1[0]);
  }

  @autobind
  onChange(file, type) {
    this.props.DALCarValuation.updateData(type, file);
  }

  @autobind
  otherImageListChange(file) {
    this.props.DALCarValuation.updateData('otherPhotos', file);
  }

  // 车辆照片
  onChangeImage = (file, type, photoType) => {
    const photoList = this.props.DALCarValuation['photos' + photoType];
    const imgIndex = photoList.findIndex(item => item.code === type);
    photoList[imgIndex].url = file.url;
    photoList[imgIndex].qczj = file.qczj;
    this.setState({
      ['photos' + photoType]: photoList
    });
  }

  onChangeMoreImg = (e, list, type = '') => {
    const dealerId = window.sessionStorage.getItem('dealerId');
    if (e.target.files) {
      const _files = [...e.target.files];
      let count = _files.length > list.length ? list.length : _files.length;
      _files.forEach(async (fileItem, fileIndex) => {
        const _formData = new FormData();
        const _blob = await changeFileToBlob(fileItem);
        _formData.append('file', _blob, `${fileItem.name}`);
        _formData.append('type', _blob.type);
        _formData.append('size', _blob.size);
        _formData.append('dealerId', dealerId);
        const res = await API.CarSource.apiUploadFile(_formData);
        if (res.code === 200) {
          const imgIndex = list.findIndex(item => !item.url);
          if (imgIndex !== -1) {
            list[imgIndex].url = res.data.url;
            list[imgIndex].qczj = res.data.qczj;
          }
          this.setState({
            ['photos' + type]: list
          }, () => {
            count--;
            if (count === 0) {
              // this.props.history.replace({
              // pathname: '/modifyPhotoLabel',
              // query: {
              // photoList: this.state['photos' + type],
              // type,
              // isFrom: this.props.isFrom
              // }
              // });
              Toast.info('上传成功', 1);
            }
          });
        } else {
          Toast.info('上传失败', 1);
        }
      });
    }
  }

  render() {
    return (
      <div className={styles.baseParamsWrap}>
        {this.props.DALCarValuation.isRenderPic && <div>
          <NewUpload
            title='车辆照片（不可编辑）'
            photoList={this.props.DALCarValuation.photos1}
            photoType={1}
            onChange={this.onChangeImage}
          />
          <div className={styles.baseParamsTitle}>认证图片</div>
          <div>
            <MoreUpload imageList={this.props.DALCarValuation.otherPhotos} changeImageList={this.otherImageListChange} allPhotos={this.props.DALCarValuation.otherPhotos} code={'3-1'} filesMaxCount={1} />
          </div>
        </div>}
      </div>
    );
  }
}

CarBaseParams.propTypes = {
};

export default withRouter(CarBaseParams);
