/**
 * 适配input中type=number IOS手机依然可以输入非数字的输入框
 */
import React from 'react';
import styles from './style/inputNumberItem.module.scss';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

@autobind
class InputNumberItem extends React.PureComponent {
  onChange(e) {
    if (this.props.maxLength) {
      //
    }
    this.props.onChange(e);
  }

  render() {
    return (
      <input
        className={`${styles.inputItem} ${this.props.className || ''}`}
        type={'number'}
        value={(this.props.value && this.props.value !== null) ? this.props.value : ''}
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        onInput={this.props.onInput}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
        disabled={this.props.disabled}
        pattern={this.props.pattern}
        maxLength={this.props.maxLength}
      />
    );
  }
}
InputNumberItem.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  pattern: PropTypes.string
};
InputNumberItem.defaultProps = {
  className: '',
  placeholder: '',
  disabled: false,
  type: 'text',
  pattern: '[0-9]*' // \d*
};

export default InputNumberItem;
