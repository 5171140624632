/*
* 车辆申请零售页面
*/
import React, { Component } from 'react';
import { LabelItem, InputItem, Card, PickerItem, DatePickerItem, TopNavBar, Footer, Button, NewInputItem } from '@/components';
import { CarBaseParams } from '@/containers/carPhotos';
import { withRouter } from 'react-router-dom';
import { observer, Provider } from 'mobx-react';
import autobind from 'autobind-decorator';
import { Toast } from 'antd-mobile';
import API from '@/api';
import DALCommon from '@/constants/common/state';
import { CarApplyAuthType, CarApplyCarType } from '@/localData';
import DALCarValuation from '@/constants/valuation/carValuationState';
import styles from './style/CarSaleApply.module.scss';
import { convertUnitNumPrice } from '@/utils/moneyFormat';

@withRouter
@observer
class CarSaleApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authNum: '',
      authType: [],
      carLicenseDate: null,
      carType: [],
      mileage: '',
      purchasePirce: '',
      salePrice: '',
      vin: '',
      photos: []
    };
  }

  componentDidMount() {
    // if (this.props.location.query && this.props.location.query.basicId) {
    // const basicId = this.props.location.query.basicId;
    // DALCarValuation.updateData('basicId', basicId);
    // console.log(basicId);
    // DALCarValuation.getData();
    // }
    DALCarValuation.updateData('basicId', DALCommon.basicId);
    DALCarValuation.getData();
  }

  componentWillUnmount() {
    DALCarValuation.updateData('basicId', null);
    DALCarValuation.updateData('authNum', '');
    DALCarValuation.updateData('authType', []);
    DALCarValuation.updateData('carLicenseDate', null);
    DALCarValuation.updateData('carType', []);
    DALCarValuation.updateData('mileage', '');
    DALCarValuation.updateData('purchasePirce', '');
    DALCarValuation.updateData('salePrice', '');
    DALCarValuation.updateData('vin', '');
    DALCarValuation.updateData('otherPhotos', []);
    DALCarValuation.updateData('photos11', []);
    DALCarValuation.updateData('photos2', []);
    DALCarValuation.photos1.map(item => {
      item.url = '';
      item.qczj = '';
    });
    DALCarValuation.updateData('photos1', [...DALCarValuation.photos1]);
  }

  @autobind
  updateEnterTimeChange(val) {
    DALCarValuation.updateData('carLicenseDate', val);
  }

  @autobind
  updateSoureChange(val, type) {
    console.log('11111:');
    DALCarValuation.updateData('authType', val);
    DALCarValuation.updateData('carType', val);
    if (val[0] === 0) {
      // 优选一年车
      DALCarValuation.updateFirstPhoto();
    } else if (val[0] === 1) {
      // 精品车
      DALCarValuation.updateSecondPhoto();
    }
  }

  updateChange(e, type) {
    DALCarValuation.updateData([type], e.target.value);
  }

  submitCarInfo = () => {
    // 提交
    DALCarValuation.updateInfo().then(res => {
      if (res) {
        Toast.info('提交成功');
        this.props.history.goBack();
      }
    });
  }

  render() {
    return (
      <Provider DALCarValuation={DALCarValuation}>
        <div className={styles.CarSaleApply}>
          <TopNavBar title={'申请零售'}></TopNavBar>
          <div className={styles.cardWrap}>
            <Card>
              <div className={styles.title}>零售信息</div>
              <LabelItem label="车架号" isRequisite={true} bottomBorderVisible={true}>
                <InputItem placeholder="请输入车架号" value={DALCarValuation.vin} onChange={(e) => this.updateChange(e, 'vin')} maxLength={17} />
              </LabelItem>
              <LabelItem label="上牌时间" isRequisite={true}>
                <DatePickerItem placeholder="请选择上牌时间" onOk={this.updateEnterTimeChange} value={DALCarValuation.carLicenseDate} minDate={this.props.year ? this.props.year : '2000-01-01'} dateFormat='YYYY-MM-DD' />
              </LabelItem>
              <LabelItem label="表显公里" isRequisite={true} bottomBorderVisible={true} handleContent="公里">
                <InputItem placeholder="请输入表显公里" value={DALCarValuation.mileage} onChange={(e) => this.updateChange(e, 'mileage')} />
              </LabelItem>
              <LabelItem label="车辆品类" isRequisite={true}>
                <PickerItem pickerData={CarApplyCarType} placeholder="请选择车辆品类" type='carType' onOk={this.updateSoureChange} value={DALCarValuation.carType} />
              </LabelItem>
              <LabelItem label="认证类型" isRequisite={true}>
                <PickerItem pickerData={CarApplyAuthType} placeholder="请选择认证类型" type='authType' onOk={this.updateSoureChange} value={DALCarValuation.authType} />
              </LabelItem>
              <LabelItem label="认证编号" isRequisite={false} bottomBorderVisible={true}>
                <InputItem placeholder="请输入认证编号" value={DALCarValuation.authNum} onChange={(e) => this.updateChange(e, 'authNum')} />
              </LabelItem>
              <NewInputItem title='收购价格' isRequisite={true} placeholder="请输入收购价格" onChange={(e) => this.updateChange(e, 'purchasePirce')}
                value={DALCarValuation.purchasePirce} type="number"
                pattern="\d*"
                moneyCapitals={convertUnitNumPrice(DALCarValuation.purchasePirce)} />
              {DALCarValuation.saleSugestPrice !== null && DALCarValuation.saleSugestPrice !== 0 && DALCarValuation.saleSugestPrice !== '' && <div className={styles.tips}>官方推荐销售价{DALCarValuation.saleSugestPrice}元</div>}
              <NewInputItem title='销售价格' isRequisite={true} placeholder="请输入销售价格" onChange={(e) => this.updateChange(e, 'salePrice')}
                value={DALCarValuation.salePrice} type="number"
                pattern="\d*"
                moneyCapitals={convertUnitNumPrice(DALCarValuation.salePrice)} />
            </Card>
            <CarBaseParams />
            <Footer>
              <Button label="提交" type="primary" onClick={this.submitCarInfo} disabled={false} />
            </Footer>
          </div>
        </div>
      </Provider >
    );
  }
}
export default CarSaleApply;