import React from 'react';
import styles from './style/inputItem.module.scss';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

@autobind
class InputItem extends React.PureComponent {
  onChange(e) {
    if (this.props.maxLength) {
      //
    }
    this.props.onChange(e);
  }

  render() {
    return (
      <input
        className={`${styles.inputItem} ${this.props.className || ''}`}
        type={this.props.type || 'text'}
        value={(this.props.value && this.props.value !== null) ? this.props.value : ''}
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        onInput={this.props.onInput}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
        disabled={this.props.disabled}
        pattern={this.props.pattern}
        maxLength={this.props.maxLength}
      />
    );
  }
}
InputItem.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  pattern: PropTypes.string
};
InputItem.defaultProps = {
  className: '',
  placeholder: '',
  disabled: false,
  type: 'text',
  pattern: '' // \d*
};

export default InputItem;
