import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatThousands } from '@/utils/dataFormat';
import { NewLabelItem, NewTitleCard, NoData } from '@/components';
// import { purchaseBusinessTypeList, paymentTypeList, purchaseStatus } from '@/localData';
// import { getItemByValue, formatThousands } from '@/utils/dataFormat';

function makeReadyInfo(props) {
  const baseInfo = props.baseInfo;
  return (
    <NewTitleCard title="整备信息">
      <NewLabelItem label="入场时间" handleContent={<div>{(baseInfo && baseInfo.makeInDate) ? baseInfo.makeInDate : ''}</div>} />
      <NewLabelItem label="出场时间" handleContent={<div>{(baseInfo && baseInfo.makeOutDate) ? baseInfo.makeOutDate : ''}</div>} />
      <NewLabelItem label="整备成本" handleContent={<div>{(baseInfo && baseInfo.makePirce !== null) ? formatThousands(baseInfo.makePirce) + ' 元' : ''}</div>} />
      <NewLabelItem label="整备内容" handleContent={<div>{(baseInfo && baseInfo.makeDetail) ? baseInfo.makeDetail : ''}</div>} />
    </NewTitleCard>
  );
}
makeReadyInfo.propTypes = {
  baseInfo: PropTypes.object
};
export default withRouter(makeReadyInfo);
