import React from 'react';
import { NewCard } from '@/components';
import styles from './style/newCard.module.scss';

function NewLabelItem(props) {
  return (
    <div className={`${styles.labelItem} ${props.noPadding ? styles.noPadding : ''}`}>
      <div className={styles.label}>{props.label + '：'}</div>
      <div className={styles.content}>
        {props.handleContent}
      </div>
    </div>
  );
}

export default NewLabelItem;
