/* eslint-disable no-return-assign */
import React from 'react';
import { ListView, Toast } from 'antd-mobile';
import styles from './style/CarMsgList.module.scss';
import { TopNavBar } from '@/components';
import iconPass from '@/assets/image/icon_pass.png';
import iconNotPass from '@/assets/image/icon_not_pass.png';

class CarMsgList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 转换后的list
      list: new ListView.DataSource({
        rowHasChanged: (row1, row2) => {
          return row1 !== row2;
        }
      }),
      // 接口获取的
      list1: [
        {
          pass: true,
          title: '您的车辆LSG1219818K00012',
          content: '库存融资收购价格客户已确认通过',
          time: '2021-12-02 13:40'
        },
        {
          pass: false,
          title: '您的车辆LSG1219818K00012',
          content: '库存融资收购价格客户已确认通过',
          time: '2021-12-02 13:41'
        },
        {
          pass: false,
          title: '您的车辆LSG1219818K00012',
          content: '库存融资收购价格客户已确认通过',
          time: '2021-12-02 13:42'
        },
        {
          pass: false,
          title: '您的车辆LSG1219818K00012',
          content: '库存融资收购价格客户未通过，请及时修改哦',
          time: '2021-12-02 13:43'
        },
        {
          pass: false,
          title: '您的车辆LSG1219818K00012',
          content: '库存融资收购价格客户已确认通过',
          time: '2021-12-02 13:44'
        }
      ]
    };
  };

  componentDidMount() {
    const list = this.state.list.cloneWithRows({ ...this.state.list1 });
    this.setState({
      list: list
    });
  }

  onCarItemClick(index) {
    // TODO
    const msgList = [...this.state.list1];
    msgList.splice(index, 1);
    const list = this.state.list.cloneWithRows({ ...msgList });
    this.setState({
      list1: [...msgList],
      list: list
    });
  }

  render() {
    const { list } = this.state;
    const row = (item, sectionID, rowID) => {
      // console.log('sectionID:' + sectionID);
      // console.log('rowID:' + rowID);
      return (
        <div className={styles.itemBg} key={rowID}>
          <div className={`${styles.leftContent} ${(item && item.pass) ? styles.leftContentPass : styles.leftContentNotPass}`}>
            {(item && item.pass) ? <img className={styles.imgBg} src={iconPass} /> : <img className={styles.imgBg} src={iconNotPass} />}
          </div>
          <div className={styles.rightContent}>
            <span className={styles.title}>{item.title}</span>
            <span className={styles.content}>{item.content}</span>
            <div className={styles.bottomBtnBg}>
              <span className={styles.time}>{item.time}</span>
              <button className={styles.deleteBtnBg} onClick={() => this.onCarItemClick(rowID)}>删除</button>
            </div>
          </div>
        </div >
      );
    };

    return (
      <div className={styles.carMsgList} >
        <TopNavBar title={'消息中心'} />
        <ListView
          dataSource={list}
          renderRow={row}
          renderBodyComponent={() => <div style={{ backgroundColor: '#F5F5F5' }} />}
          useBodyScroll
          ref={el => this.lv = el}
          onEndReached={this.onEndReached}
          onEndReachedThreshold={10} />
      </div>
    );
  }
}
export default CarMsgList;