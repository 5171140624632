import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import AppRouter from '../src/pages/carManage/carList/CarList';
import AppRouter from '../src/router/AppRouter';
import Vconsole from 'vconsole';
import * as serviceWorker from '@/serviceWorker';
import DALCommon from '@/constants/common/state';
import '@/assets/iconfont/iconfont.css';
import '@/assets/cadillacFont/cadillacFont.scss';
import '@/assets/style/iconStyle.css';
// if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_ROOT === 'sit') {
// const vconsole = new Vconsole();
// }
// export default vconsole;

(async function () {
  if (!DALCommon.blocNum) {
    await DALCommon.getBlocNum();
  }
  if (!DALCommon.basicId) {
    await DALCommon.getBasicId();
  }

  ReactDOM.render(<AppRouter />, document.getElementById('root'));
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
