/*
 * @Descripttion: 公共模块状态
 * @Author: zhongyuanrui
 * @Date: 2019-05-21 09:57:37
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-10-24 09:40:30
 */

import {
  observable,
  action
} from 'mobx';
import {
  getQueryString
} from '@/utils/dataFormat';

class DALCommon {
  static getInstance() {
    if (!DALCommon.instance) {
      DALCommon.instance = new DALCommon();
    }
    return DALCommon.instance;
  }

  @observable basicId = null;
  @observable blocNum = null;

  // 获取经销商id
  @action.bound
  getBlocNum() {
    if (getQueryString('blocNum')) {
      const base64 = getQueryString('blocNum');
      const bytes = window.atob(base64);
      this.blocNum = bytes;
      window.sessionStorage.setItem('blocNum', this.blocNum);
      console.log('byte:' + bytes);
      return true;
    } else {
      if (window.sessionStorage.getItem('blocNum') && window.sessionStorage.getItem('blocNum') !== 'null') {
        this.blocNum = window.sessionStorage.getItem('blocNum');
        return true;
      } else {
        if (window.sessionStorage.getItem('blocNum') && window.sessionStorage.getItem('blocNum') !== 'null') {
          this.blocNum = window.sessionStorage.getItem('blocNum');
          return true;
        } else {
          return false;
        }
      }
    }
  }

  // 获取经销商id
  @action.bound
  getBasicId() {
    if (getQueryString('basicId')) {
      window.sessionStorage.setItem('basicId', getQueryString('basicId'));
      this.basicId = getQueryString('basicId');
      const a = getQueryString('isFrom');
      return true;
    } else {
      if (window.sessionStorage.getItem('basicId') && window.sessionStorage.getItem('basicId') !== 'null') {
        this.basicId = window.sessionStorage.getItem('basicId');
        return true;
      } else {
        if (window.sessionStorage.getItem('basicId') && window.sessionStorage.getItem('basicId') !== 'null') {
          this.basicId = window.sessionStorage.getItem('basicId');
          return true;
        } else {
          return false;
        }
      }
    }
  }
}

export default DALCommon.getInstance();
