/*
 * @Descripttion:添加图片card
 * @Author: zhongyuanrui
 * @Date: 2019-10-11 14:29:40
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-10-24 11:33:40
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './style/addImageCard.module.scss';
import iconAdd from '@/assets/image/tj.png';

function AddImageCard(props) {
  return (
    <div className={`${styles.addItemBox} ${props.className}`}>
      <div className={styles.addItem}>
        <img className={styles.addLogo} src={iconAdd} />
        {/* <p>点击添加图片</p> */}
      </div>
      {/* {props.bgImage && <img className={styles.bgImage} src={require(`@/assets/image/defaultImage/${props.bgImage}.png`).default} />} */}
      <input
        type="file"
        accept="image/*"
        className={styles.inputFile}
        multiple={props.multiple}
        onChange={props.onChange}
        disabled={props.ableUploadImg}
      />
    </div>
  );
}

AddImageCard.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  ableUploadImg: PropTypes.bool
};
AddImageCard.defaultProps = {
  className: '',
  multiple: false,
  ableUploadImg: false
};

export default AddImageCard;
