/*
 * @Descripttion:车辆配置 - 车轮制动
 * @Author: zhongyuanrui
 * @Date: 2019-09-17 11:40:07
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-23 18:28:29
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TitleCard } from '@/components';
import ConfigItem from '../configItem/ConfigItem';

function WheelBraking(props) {
  const data = props.data;
  return (
    <TitleCard title="车轮制动" showHeaderLine={false}>
      <ConfigItem label="后轮胎规格" value={data && data.backwheel ? data.backwheel : ''} />
      <ConfigItem label="前制动器类型" value={data && data.frontbrake ? data.frontbrake : ''} />
      <ConfigItem label="后轮毂规格" value={data && data.backhub ? data.backhub : ''} />
      {/* <ConfigItem label="第二备胎" value={data.a} /> */}
      <ConfigItem label="驻车制动类型" value={data && data.parkingBrakeType ? data.parkingBrakeType : ''} />
      <ConfigItem label="后制动器类型" value={data && data.backbrake ? data.backbrake : ''} />
      <ConfigItem label="前轮毂规格" value={data && data.fronthub ? data.fronthub : ''} />
      <ConfigItem label="备眙规格" value={data && data.sparewheel ? data.sparewheel : ''} />
      <ConfigItem label="前轮胎规格" value={data && data.frontwheel ? data.frontwheel : ''} />
    </TitleCard>
  );
}

WheelBraking.propTypes = {
  data: PropTypes.object
};

export default WheelBraking;
