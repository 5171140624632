import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NewLabelItem, NewTitleCard, NoData } from '@/components';
import { OperationTypeStatus, TransferTimesStatus, UseTypeStatus } from '@/localData';
// import { purchaseBusinessTypeList, paymentTypeList, purchaseStatus } from '@/localData';
// import { getItemByValue, formatThousands } from '@/utils/dataFormat';

function purchaseInfo(props) {
  const carBasic = props.carBasic;
  return (
    <NewTitleCard title="牌证信息">
      <NewLabelItem label="使用性质" handleContent={<div>{(carBasic && carBasic.useProperty && OperationTypeStatus[carBasic.useProperty]) ? OperationTypeStatus[carBasic.useProperty] : ''}</div>} />
      <NewLabelItem label="车辆用途" handleContent={<div>{(carBasic && carBasic.useType && UseTypeStatus[carBasic.useType]) ? UseTypeStatus[carBasic.useType] : ''}</div>} />
      <NewLabelItem label="车辆所有人" handleContent={<div>{(carBasic && carBasic.carOwner) ? carBasic.carOwner : ''}</div>} />
      <NewLabelItem label="过户次数" handleContent={<div>{(carBasic && carBasic.assignedNumber !== null && carBasic.assignedNumber !== '' && TransferTimesStatus[carBasic.assignedNumber]) ? TransferTimesStatus[carBasic.assignedNumber] : ''}</div>} />
    </NewTitleCard>
  );
}
purchaseInfo.propTypes = {
  carBasic: PropTypes.object
};
export default withRouter(purchaseInfo);
