/* eslint-disable multiline-ternary */
import React from 'react';
import styles from './style/newCard.module.scss';

function NewTitleCard(props) {
  return (
    <div className={styles.newCard}>
      <div className={`${styles.carTitle} ${props.noBottomBorder ? styles.noBottomBorder : ''}`} onClick={props.lineFun ? props.lineFun : undefined}>
        <div className={styles.carTit}>{props.title}</div>
        <div className={`${styles.operate} ${props.imgStyle ? styles.imgStyle : ''}`}>
          {(props.carInfoOperateList && props.carInfoOperateList.length > 0)
            ? props.carInfoOperateList.map((item, index) => (
              <div key={index}>
                {/* {item.value && <img src={require(`@/assets/image/common/${item.value}.png`)} alt=""/>} */}
                <span className={`${props.buttonStyle ? styles.buttonStyle : ''}`} onClick={item.fun}>{item.label}</span>
              </div>
            )) : ''
          }
        </div>
      </div>
      {/* <div className={styles.labelItemWrap}>{props.children}</div> */}
      {props.children}
    </div>
  );
}

export default NewTitleCard;
