import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NewLabelItem, NewTitleCard, NoData } from '@/components';
import { StackFinacingStatus, CarSourceType } from '@/localData';
import { formatThousands } from '@/utils/dataFormat';

function buyInfo(props) {
  const baseInfo = props.baseInfo;
  return (
    <NewTitleCard title="采购信息">
      <NewLabelItem label="车辆来源" handleContent={<div>{(baseInfo && baseInfo.carSource !== null && CarSourceType[baseInfo.carSource]) ? CarSourceType[baseInfo.carSource].label : ''}</div>} />
      <NewLabelItem label="收购价格" handleContent={<div>{(baseInfo && baseInfo.purchasePirce !== null) ? formatThousands(baseInfo.purchasePirce) + ' 元' : ''}</div>} />
      <NewLabelItem label="买方单位" handleContent={<div>{(baseInfo && baseInfo.buyerName !== null) ? baseInfo.buyerName : ''}</div>} />
      <NewLabelItem label="开票日期" handleContent={<div>{(baseInfo && baseInfo.invoiceDate !== null) ? baseInfo.invoiceDate : ''}</div>} />
      <NewLabelItem label="入库时间" handleContent={<div>{(baseInfo && baseInfo.inDate !== null) ? baseInfo.inDate : ''}</div>} />
      <NewLabelItem label="采购人员" handleContent={<div>{(baseInfo && baseInfo.purchaseStaff !== null) ? baseInfo.purchaseStaff : ''}</div>} />
      {/* <NewLabelItem label="库存融资" handleContent={<div>{(baseInfo && baseInfo.stackFinacing !== null && StackFinacingStatus[baseInfo.stackFinacing]) ? StackFinacingStatus[baseInfo.stackFinacing].label : ''}</div>} /> */}
      <NewLabelItem label="过户成本" handleContent={<div>{(baseInfo && baseInfo.transferPrice !== null) ? formatThousands(baseInfo.transferPrice) + ' 元' : ''}</div>} />
      <NewLabelItem label="物流成本" handleContent={<div>{(baseInfo && baseInfo.expressPrice !== null) ? formatThousands(baseInfo.expressPrice) + ' 元' : ''}</div>} />
      <NewLabelItem label="其他费用" handleContent={<div>{(baseInfo && baseInfo.otherPrice !== null) ? formatThousands(baseInfo.otherPrice) + ' 元' : ''}</div>} />
      <NewLabelItem label="备注说明" handleContent={<div>{(baseInfo && baseInfo.remark !== null) ? baseInfo.remark : ''}</div>} />
    </NewTitleCard>
  );
}
buyInfo.propTypes = {
  baseInfo: PropTypes.object
};
export default withRouter(buyInfo);
