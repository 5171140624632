/* eslint-disable multiline-ternary */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NewLabelItem, NewTitleCard, NoData } from '@/components';
import { SaleStatusType } from '@/localData';
import { formatThousands } from '@/utils/dataFormat';
// import { getAuthority } from '@/utils/getAuthority';
import './index.scss';

function carConInfo(props) {
  const carBasic = props.carBasic;
  const retailInfo = props.retailInfo;
  const dealerBloc = props.dealerBloc;
  const orderInfo = props.orderInfo;
  return (
    <NewTitleCard title="零售信息">
      <NewLabelItem label="零售状态" handleContent={<div>{(retailInfo && retailInfo.saleStatus !== null && SaleStatusType[retailInfo.saleStatus]) ? SaleStatusType[retailInfo.saleStatus].label : ''}</div>} />
      <NewLabelItem label="经销商名称" handleContent={<div>{(dealerBloc && dealerBloc.blocName) ? dealerBloc.blocName : ''}</div>} />
      <NewLabelItem label="地址" handleContent={<div>{(dealerBloc && dealerBloc.blocArr) ? dealerBloc.blocArr : ''}</div>} />
      <NewLabelItem label="联系人" handleContent={<div>{(carBasic && carBasic.contact) ? carBasic.contact : ''}</div>} />
      <NewLabelItem label="联系电话" handleContent={<div>{(carBasic && carBasic.contactNum) ? carBasic.contactNum : ''}</div>} />
      <NewLabelItem label="入库总成本" handleContent={<div>{(retailInfo && retailInfo.purchaseTotalPirce !== null) ? formatThousands(retailInfo.purchaseTotalPirce) + ' 元' : ''}</div>} />
      <NewLabelItem label="销售定价" handleContent={<div>{(retailInfo && retailInfo.salePrice !== null) ? formatThousands(retailInfo.salePrice) + ' 元' : ''}</div>} />
      <NewLabelItem label="销售成本" handleContent={<div>{(orderInfo && orderInfo.saleRealPrice !== null) ? formatThousands(orderInfo.saleRealPrice) + ' 元' : ''}</div>} />
      <NewLabelItem label="金融零售合同编号" handleContent={<div>{(orderInfo && orderInfo.financeRetailNum !== null) ? orderInfo.financeRetailNum : ''}</div>} />
    </NewTitleCard>
  );
}
carConInfo.propTypes = {
  carBasic: PropTypes.object,
  retailInfo: PropTypes.object,
  dealerBloc: PropTypes.object,
  orderInfo: PropTypes.object
};
export default withRouter(carConInfo);
