/*
* 车辆入库
*/
import React from 'react';
import { LabelItem, InputItem, Card, PickerItem, DatePickerItem, TopNavBar, Footer, Button, NewInputItem } from '@/components';
import autobind from 'autobind-decorator';
import API from '@/api';
import { StackFinacingStatus, CarSourceType } from '@/localData';
import { Toast, Modal } from 'antd-mobile';
import { Dialog } from 'antd-mobile-v5';
import {
  MoreUpload,
  NewUpload
} from '@/containers/common';
import { convertUnitNumPrice } from '@/utils/moneyFormat';
import {
  formatDateString,
  formatDateObject
} from '@/utils/date';
import { mergePhotosArr, mergePhotosArrByCode } from '@/utils/dataFormat';
import DALCommon from '@/constants/common/state';
import styles from './style/CarEnterStore.module.scss';

class CarEnterStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicId: null,
      // 车辆来源
      carSource: null,
      // 收购价
      purchasePirce: '',
      buyerName: [], // 买方单位
      invoiceDate: null, // 开票时间
      // 入库时间
      inDate: null,
      // 采购人员
      purchaseStaff: '',
      // 库存融资(暂时去掉了)
      // stackFinacing: [],
      // 过户成本
      transferPrice: '',
      // 物流成本
      expressPrice: '',
      // 其他费用
      otherPrice: '',
      // 备注说明
      remark: '',
      buyerNameList: [], // 买方单位列表
      photos7: [
        {
          code: '7-1',
          name: '收车发票照片',
          url: '',
          qczj: '',
          bgImage: 'zq45',
          isRequire: true
        },
        {
          code: '7-2',
          name: '登记证P1-P2页照片',
          url: '',
          qczj: '',
          bgImage: 'zq45',
          isRequire: true
        },
        {
          code: '7-3',
          name: '登记证P3-P4页照片',
          url: '',
          qczj: '',
          bgImage: 'zq45',
          isRequire: true
        }
      ]
    };
  }

  componentDidMount() {
    // if (this.props.location.query && this.props.location.query.basicId) {
    // const basicId = this.props.location.query.basicId;
    // this.state.basicId = basicId;
    // this.getData();
    // console.log(basicId);
    // }
    this.state.basicId = DALCommon.basicId;
    this.getData();
    this.getBuyerNameList(DALCommon.blocNum);
  }

  componentWillUnmount() {
    if (this.photos7 && this.photos7.length > 0) {
      this.photos7.map(item => {
        item.url = '';
        item.qczj = '';
      });
    }
    this.state.basicId = null;
    this.state.carSource = null;
    this.state.purchasePirce = '';
    this.state.buyerName = [];
    this.state.invoiceDate = null;
    this.state.inDate = null;
    this.state.purchaseStaff = '';
    // this.state.stackFinacing = [];
    this.state.transferPrice = '';
    this.state.expressPrice = '';
    this.state.otherPrice = '';
    this.state.remark = '';
  }

  // 获取买方单位
  getBuyerNameList(blocNum) {
    //
    API.CarSource.getBuyerNameList({
      blocNum: blocNum
    }).then(res => {
      //
      if (res.code === 200 && res.data) {
        let buyerList = res.data || [];
        buyerList = buyerList.map((item, index) => {
          return {
            label: item,
            value: item
          };
        });
        this.setState({
          buyerNameList: [...buyerList]
        });
      }
    });
  }

  getData = () => {
    API.CarSource.getCarStoreinfo({
      basicId: this.state.basicId
    }).then(res => {
      if (res.code === 200) {
        //
        if (this.state.photos7 && this.state.photos7.length > 0) {
          this.state.photos7.map(item => {
            item.url = '';
            item.qczj = '';
          });
        }
        const list1 = mergePhotosArrByCode(this.state.photos7, [...res.data.photos || []]);
        this.setState({
          carSource: res.data.carSource,
          expressPrice: res.data.expressPrice || '',
          inDate: res.data.inDate || null,
          otherPrice: res.data.otherPrice,
          buyerName: [res.data.buyerName] || [],
          invoiceDate: res.data.invoiceDate || null,
          purchasePirce: res.data.purchasePirce || null,
          purchaseStaff: res.data.purchaseStaff,
          remark: res.data.remark,
          photos7: [...list1],
          // stackFinacing: [...this.state.stackFinacing, res.data.stackFinacing],
          transferPrice: res.data.transferPrice
        }, () => {
          console.log(this.state.otherPrice);
        });
      } else {
        //
      }
    });
  }

  updateChange(e, type) {
    this.setState({
      [type]: e.target.value
    });
  }

  @autobind
  updateTimeChange(val) {
    this.setState({
      inDate: val
    });
  }

  @autobind
  updateInvoiceDateChange(val) {
    this.setState({
      invoiceDate: val
    });
  }

  @autobind
  updateSourceChange(val, type) {
    console.log(type, val);
    this.setState({
      [type]: val
    });
  }

  onChangeImage = (file, type, photoType) => {
    const photoList = this.state['photos' + photoType];
    const imgIndex = photoList.findIndex(item => item.code === type);
    photoList[imgIndex].url = file.url;
    photoList[imgIndex].qczj = file.qczj;
    this.setState({
      ['photos' + photoType]: photoList
    });
  }

  submitCarInfo = () => {
    if (!this.state.expressPrice) {
      Toast.info('请输入物流成本');
      return;
    }
    if (!this.state.inDate) {
      Toast.info('请输入入库时间');
      return;
    }
    if (!this.state.buyerName) {
      Toast.info('请选择买方单位');
      return;
    }
    if (!this.state.invoiceDate) {
      Toast.info('请选择开票时间');
      return;
    }
    if (!this.state.otherPrice) {
      Toast.info('请输入其他费用');
      return;
    }
    if (!this.state.purchasePirce) {
      Toast.info('请输入收购价格');
      return;
    }
    if (!this.state.purchaseStaff) {
      Toast.info('请输入采购人员');
      return;
    }
    // if (!this.state.stackFinacing) {
    // Toast.info('请选择库存融资');
    // return;
    // }
    if (!this.state.transferPrice) {
      Toast.info('请输入过户成本');
      return;
    }
    for (const item of this.state.photos7) {
      if (item.isRequire && item.url === '') {
        Toast.info('请上传' + item.name + '照片');
        return;
      }
    }
    this.submit();
    // 库存融资为是
    // if (this.state.stackFinacing[0] === 1) {
    // Modal.alert('收购价格：' + this.state.purchasePirce + '元', '因库存融资车辆需要融资客户确认收购价格收购价格提交后不可更改,是否继续提交？', [
    // { text: '我再想想', onPress: () => console.log('cancel') },
    // { text: '继续提交', onPress: () => this.submit() }
    // ]);
    // } else {
    // this.submit();
    // }
  }

  submit = () => {
    const params = {
      basicId: this.state.basicId,
      carSource: this.state.carSource,
      expressPrice: this.state.expressPrice,
      buyerName: this.state.buyerName[0] || '',
      invoiceDate: formatDateString(this.state.invoiceDate),
      inDate: formatDateString(this.state.inDate),
      photos: [...this.state.photos7],
      otherPrice: this.state.otherPrice,
      purchasePirce: this.state.purchasePirce,
      purchaseStaff: this.state.purchaseStaff,
      remark: this.state.remark,
      // stackFinacing: this.state.stackFinacing[0],
      transferPrice: this.state.transferPrice
    };
    const res = API.CarSource.updateCarStoreinfo(params).then(res => {
      if (res.code === 200) {
        Toast.info('提交成功');
        this.props.history.goBack();
      }
    });
  }

  render() {
    return (
      <div>
        <TopNavBar title={'车辆入库'}></TopNavBar>
        <div className={styles.cardWrap}>
          <Card>
            <div className={styles.title}>采购信息</div>
            <LabelItem label="车辆来源" isRequisite={true}>
              <InputItem placeholder="请选择车辆来源" type='carSource' onOk={this.updateSourceChange} value={CarSourceType[this.state.carSource] ? CarSourceType[this.state.carSource].label : ''} disabled={true} />
            </LabelItem>
            <NewInputItem title='收购价格' isRequisite={true} placeholder="请输入收购价格" onChange={(e) => this.updateChange(e, 'purchasePirce')}
              value={this.state.purchasePirce} type="number"
              pattern="\d*"
              moneyCapitals={convertUnitNumPrice(this.state.purchasePirce)} />
            <LabelItem label="买方单位" isRequisite={true}>
              <PickerItem pickerTitle='买方单位' pickerData={this.state.buyerNameList} placeholder="请选择买方单位" type='buyerName' onOk={this.updateSourceChange} value={this.state.buyerName} />
            </LabelItem>
            <LabelItem label="开票日期" isRequisite={true}>
              <DatePickerItem placeholder="请选择开票日期" onOk={this.updateInvoiceDateChange} value={this.state.invoiceDate} minDate={this.props.year ? this.props.year : '2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
            <LabelItem label="入库时间" isRequisite={true}>
              <DatePickerItem placeholder="请选择入库时间" onOk={this.updateTimeChange} value={this.state.inDate} minDate={this.props.year ? this.props.year : '2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
            <LabelItem label="采购人员" isRequisite={true} bottomBorderVisible={true}>
              <InputItem placeholder="请输入采购人员" onChange={(e) => this.updateChange(e, 'purchaseStaff')} value={this.state.purchaseStaff} />
            </LabelItem>
            {/* <LabelItem label="库存融资" isRequisite={true} bottomBorderVisible={false}> */}
              {/* <PickerItem pickerData={StackFinacingStatus} placeholder="请选择库存融资" type='stackFinacing' onOk={this.updateSourceChange} value={this.state.stackFinacing} /> */}
            {/* </LabelItem> */}
            <NewUpload
              title='采购发票照片'
              photoList={this.state.photos7}
              photoType={7}
              onChange={this.onChangeImage}
            />
          </Card>
          <Card>
            <div className={styles.title}>采购成本</div>
            <NewInputItem title='过户成本' isRequisite={true} placeholder="请输入过户成本" onChange={(e) => this.updateChange(e, 'transferPrice')}
              value={this.state.transferPrice} type="number"
              pattern="\d*"
              moneyCapitals={convertUnitNumPrice(this.state.transferPrice)} />
            <NewInputItem title='物流成本' isRequisite={true} placeholder="请输入物流成本" onChange={(e) => this.updateChange(e, 'expressPrice')}
              value={this.state.expressPrice} type="number"
              pattern="\d*"
              moneyCapitals={convertUnitNumPrice(this.state.expressPrice)} />
            <NewInputItem title='其他费用' isRequisite={true} placeholder="请输入其他费用" onChange={(e) => this.updateChange(e, 'otherPrice')}
              value={this.state.otherPrice} type="number"
              pattern="\d*"
              moneyCapitals={convertUnitNumPrice(this.state.otherPrice)} />
            <LabelItem label="备注说明" isRequisite={false} bottomBorderVisible={true}>
              <InputItem placeholder="请输入备注说明" onChange={(e) => this.updateChange(e, 'remark')} value={this.state.remark} />
            </LabelItem>
            {/* <LabelItem label="备注说明" isRequisite={false} bottomBorderVisible={false}> */}
            {/* <TextareaItem className={styles.textarea} placeholder="click the button below to focus" autoHeight/> */}
            {/* </LabelItem> */}
          </Card>
          <Footer>
            <Button label="提交" type="primary" onClick={this.submitCarInfo} disabled={false} />
          </Footer>
        </div>
      </div >
    );
  }
}
export default CarEnterStore;