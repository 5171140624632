import React from 'react';
import styles from './style/button.module.scss';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

@autobind
class Button extends React.PureComponent {
  onClick() {
    !this.props.disabled && this.props.onClick();
  }

  render() {
    return (
      <button
        className={
          `${styles.baseButton}
          ${styles[`${this.props.type}Button`]}
          ${this.props.disabled ? styles.buttonDisabled : ''}
          ${this.props.className}`
        }
        disabled={this.props.disabled}
        onClick={this.onClick}
      >
        {this.props.label}
      </button>
    );
  }
}

Button.propTypes = {
  // 按钮类型
  type: PropTypes.string,
  // 点击方法
  onClick: PropTypes.func,
  // 按钮标签
  label: PropTypes.string,
  // 禁止点击
  disabled: PropTypes.bool,
  // class样式
  className: PropTypes.string
};
Button.defaultProps = {
  type: 'default',
  label: '',
  disabled: false,
  transparent: false,
  solid: false
};

export default Button;