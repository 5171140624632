/*
 * @Descripttion:车辆配置 -操控配置
 * @Author: zhongyuanrui
 * @Date: 2019-09-17 11:40:07
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-23 18:07:37
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TitleCard } from '@/components';
import ConfigItem from '../configItem/ConfigItem';

function ControlConfig(props) {
  const data = props.data;
  return (
    <TitleCard title="操控配置" showHeaderLine={false}>
      <ConfigItem label="中央差速器锁止功能" value={data && data.limitedSlipDifferential ? data.limitedSlipDifferential : ''} />
      <ConfigItem label="陡坡缓降" value={data && data.hillDescentControl ? data.hillDescentControl : ''} />
      <ConfigItem label="空气悬架" value={data && data.aimatic ? data.aimatic : ''} />
      <ConfigItem label="可变转向比" value={data && data.variableGearSteeringRatio ? data.variableGearSteeringRatio : ''} />
      <ConfigItem label="刹车辅助(EBA/BAS/BA等)" value={data && data.brakeAssistEbaBasBa ? data.brakeAssistEbaBasBa : ''} />
      <ConfigItem label="上坡辅助" value={data && data.hillStartAssist ? data.hillStartAssist : ''} />
      {/* <ConfigItem label="盲点检测" value={data.a} /> */}
      {/* <ConfigItem label="电磁感应悬架" value={data.a} /> */}
      <ConfigItem label="车身稳定控制(ESC/ESP/DSC等)" value={data && data.vehicleStabilityControlEspDscVsc ? data.vehicleStabilityControlEspDscVsc : ''} />
      <ConfigItem label="牵引力控制(ASR/TCS/TRC等)" value={data && data.tractionControlSystemAsrTcsTrc ? data.tractionControlSystemAsrTcsTrc : ''} />
      <ConfigItem label="可变悬架" value={data && data.variableSuspension ? data.variableSuspension : ''} />
      <ConfigItem label="制动力分配(EBD/CBC等)" value={data && data.brakingForceDistributionEbdCbc ? data.brakingForceDistributionEbdCbc : ''} />
      <ConfigItem label="ABS防抱死" value={data && data.absAntilock ? data.absAntilock : ''} />
      {/* <ConfigItem label="随速助力转向调节(EPS)" value={data.a} /> */}
      {/* <ConfigItem label="多地形可选驾驶系统" value={data.a} /> */}
      <ConfigItem label="自动驻车" value={data && data.automaticParking ? data.automaticParking : ''} />
      <ConfigItem label="后桥限滑差速器/差速锁" value={data && data.backVaq ? data.backVaq : ''} />
      <ConfigItem label="前桥限滑差速器/差速锁" value={data && data.frontVaq ? data.frontVaq : ''} />
    </TitleCard>
  );
}

ControlConfig.propTypes = {
  data: PropTypes.object
};

export default ControlConfig;
