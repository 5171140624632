/*
* 车辆配置页面
*/
import React from 'react';
import { TopNavBar } from '@/components';
import styles from './style/CarConfigure.module.scss';
import CarConfigureHeader from '../../vehicle/carConfigureHeader';
import CarConfigureInfo from '../../vehicle/carConfigureInfo';
import API from '@/api';
import DALCommon from '@/constants/common/state';

class CarConfigure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicId: null,
      carName: '',
      Basedata: {}
    };
  }

  componentDidMount() {
    // if (this.props.location.query && this.props.location.query.id) {
    // const id = this.props.location.query.id;
    // console.log(id);
    // }
    this.state.basicId = DALCommon.basicId;
    this.getData();
  }

  getData() {
    API.CarSource.getSettinginfo({
      basicId: this.state.basicId
    }).then(res => {
      if (res.code === 200) {
        //
        if (res.data) {
          this.setState({
            carName: res.data.carBasicSettingDesc ? res.data.carBasicSettingDesc.familyname + res.data.carBasicSettingDesc.salesdesc : '',
            Basedata: res.data
          });
        }
      } else {
        //
      }
    });
  }

  render() {
    return (
      <div className={styles.CarConfigure}>
        <TopNavBar title={'车型配置'} />
        <CarConfigureHeader vehicleName={this.state.carName} />
        <CarConfigureInfo data={this.state.Basedata} />
      </div>
    );
  }
}
export default CarConfigure;