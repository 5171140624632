import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Card } from '@/components';
import './index.scss';

@autobind
class NewInputItem extends React.PureComponent {
  onChange(e) {
    if (this.props.maxLength) {
      //
    }
    this.props.onChange(e);
  }

  render() {
    return (
        <div className='newipt-item'>
          <span className='newipt-item_title'>{this.props.isRequisite && <span>*</span>}{this.props.title}</span>
          <div className={`newipt-item_content ${this.props.isBorder ? 'bottomBorder' : ''}`}>
            {this.props.isShowMark && <span className='newipt-item_sign'>¥</span>}
            <input
              placeholder={this.props.placeholder}
              value={this.props.value}
              onChange={this.onChange}
              onInput={this.props.onInput}
              onBlur={this.props.onBlur}
              onFocus={this.props.onFocus}
              disabled={this.props.disabled}
              type={this.props.type || 'text'}
              pattern="\d*"
            />
            {this.props.isShowYuan && <span className='newipt-item_unit'>元</span>}
          </div>
          {this.props.moneyCapitals && <span className='newipt-item_capitals'>{this.props.moneyCapitals}</span>}
        </div>
    );
  }
}
NewInputItem.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  moneyCapitals: PropTypes.string,
  isShowMark: PropTypes.bool,
  isShowYuan: PropTypes.bool,
  isBorder: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  pattern: PropTypes.string,
  isRequisite: PropTypes.bool
};
NewInputItem.defaultProps = {
  title: '',
  className: '',
  moneyCapitals: '',
  placeholder: '请输入价格',
  disabled: false,
  type: 'text',
  pattern: '', // \d*
  isRequisite: false,
  isShowMark: true,
  isShowYuan: true,
  isBorder: true
};

export default NewInputItem;
