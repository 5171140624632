/*
 * @Descripttion:车辆配置 -发动机
 * @Author: zhongyuanrui
 * @Date: 2019-09-17 11:40:07
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-23 18:13:33
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TitleCard } from '@/components';
import ConfigItem from '../configItem/ConfigItem';

function Engine(props) {
  const data = props.data;
  return (
    <TitleCard title="发动机" showHeaderLine={false}>
      <ConfigItem label="行程(mm)" value={data && data.strokemm ? data.strokemm : ''} />
      <ConfigItem label="缸体材料" value={data && data.cylinderMaterial ? data.cylinderMaterial : ''} />
      <ConfigItem label="每缸气门数(个)" value={data && data.valve ? data.valve : ''} />
      <ConfigItem label="最大扭矩(Nm)" value={data && data.maxnm ? data.maxnm : ''} />
      <ConfigItem label="排量(mL)" value={data && data.output ? data.output : ''} />
      <ConfigItem label="发动机型号" value={data && data.engineType ? data.engineType : ''} />
      {/* <ConfigItem label="气门结构" value={data.a} /> */}
      <ConfigItem label="配气机构" value={data && data.valveMechanism ? data.valveMechanism : ''} />
      <ConfigItem label="缸盖材料" value={data && data.headPort ? data.headPort : ''} />
      <ConfigItem label="环保标准" value={data && data.emission ? data.emission : ''} />
      <ConfigItem label="气缸数(个)" value={data && data.lwvnumber ? data.lwvnumber : ''} />
      <ConfigItem label="最大马力(Ps)" value={data && data.maxps ? data.maxps : ''} />
      <ConfigItem label="最大功率转速(pm)" value={data && data.maxrpm ? data.maxrpm : ''} />
      <ConfigItem label="排量(L)" value={data && data.output ? data.output : ''} />
      <ConfigItem label="压缩比" value={data && data.compress ? data.compress : ''} />
      {/* <ConfigItem label="发动机位置" value={data.a} /> */}
      <ConfigItem label="进气形式" value={data && data.method ? data.method : ''} />
      <ConfigItem label="缸径(mm)" value={data && data.boremm ? data.boremm : ''} />
      <ConfigItem label="燃油标号" value={data && data.fuelnumber ? data.fuelnumber : ''} />
      <ConfigItem label="气缸排列形式" value={data && data.lwv ? data.lwv : ''} />
      <ConfigItem label="最大扭矩转速(rpm)" value={data && data.maxtorque ? data.maxtorque : ''} />
      <ConfigItem label="最大功率(kW)" value={data && data.maxpower ? data.maxpower : ''} />
      <ConfigItem label="发动机特有技术" value={data && data.engineTechnology ? data.engineTechnology : ''} />
      <ConfigItem label="供油方式" value={data && data.fuelmethod ? data.fuelmethod : ''} />
    </TitleCard>
  );
}

Engine.propTypes = {
  data: PropTypes.object
};

export default Engine;
