import React, { Component } from 'react';
import styles from './style/imagePopup.module.scss';
import autobind from 'autobind-decorator';
import {
  nativeApi
} from '@/jsBridge';
import WxImageViewer from '@/components/imageViewer/WxImageViewer';
import downloadImg from '@/assets/image/newDownload.png';
import closeImg from '@/assets/image/close.png';
import { photoType } from '@/localData';

class NewImagePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: this.props.index,
      innerImageList: [],
      imgHeight: 176
    };
  }

  // 下载图片
  // @autobind
  // downloadImage() {
  // nativeApi.saveImage({
  // url: this.props.urls[this.state.currentImageIndex].url
  // });
  // }

  @autobind
  currentIndexChange(index) {
    this.setState({
      currentImageIndex: index
    });
  }

  render() {
    return (
      <div className={styles.NewImagePopup}>
        <WxImageViewer urls={this.props.urls} index={this.state.currentImageIndex} onClose={this.props.onClose} currentIndexChange={this.currentIndexChange} />
        <div className={styles.imgTop}>
          <img src={closeImg} alt="" onClick={this.props.onClose} />
          {this.state.currentImageIndex + 1 + '/' + this.props.urls.length}
          {/* <img src={downloadImg} alt="" /> */}
        </div>
        <div className={styles.imgBottom}>{photoType[this.props.urls[this.state.currentImageIndex].code]}</div>
      </div>
    );
  }
}

export default NewImagePopup;
