/*
 * @Descripttion: 图片处理
 * @Author: zhongyuanrui
 * @Date: 2019-10-23 15:14:23
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-10-25 10:09:34
 */
import {
  mountGlobalLoading,
  unMountGlobalLoading
} from '@/components/loading/RenderGlobalLoading';
/**
 * @description 把文件流格式的图片转换成Blob（暂时限定为图片类型）(并做图片压缩)
 * @export changeFileToBlob
 * @param {*} file
 * @returns {*} Blob
 */
export function changeFileToBlob(file) {
  mountGlobalLoading();
  let src;
  const fileSize = parseFloat(parseInt(file.size) / 1024 / 1024).toFixed(2);
  const read = new FileReader();
  return new Promise(function (resolve, reject) {
    read.readAsDataURL(file);
    read.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        // 默认按比例压缩
        const w = this.width;
        const h = this.height;
        // 生成canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let base64;
        // 创建属性节点
        canvas.setAttribute('width', w);
        canvas.setAttribute('height', h);
        ctx.drawImage(this, 0, 0, w, h);
        // 进行图片压缩，小于1M不压缩原图上传；1-4M轻量压缩(0.7)，提高上传时间；大于4M尽量控制再4M以内，保证识别率
        if (fileSize <= 1) {
          base64 = canvas.toDataURL(file.type);
        } else if (fileSize > 1 && fileSize <= 4) {
          base64 = canvas.toDataURL(file.type, 0.7);
        } else {
          base64 = canvas.toDataURL(file.type, ((4 / fileSize) - 0.1));
        }
        // 这里对base64串进行操作，去掉url头，并转换为byte
        const bytes = window.atob(base64.split(',')[1]);
        const array = [];
        for (let i = 0; i < bytes.length; i++) {
          array.push(bytes.charCodeAt(i));
        }
        const blob = new Blob([new Uint8Array(array)], {
          type: file.type
        });
        unMountGlobalLoading();
        resolve(blob);
      };
    };
  });
};

/**
 * @description 把文件流格式的图片转换成Blob（暂时限定为图片类型）(并做图片压缩)
 * @export changeFileToBlob
 * @param {*} file
 * @returns {*} Blob
 */
export function changeFile2Blob(file) {
  mountGlobalLoading();
  let src;
  const fileSize = parseFloat(parseInt(file.size) / 1024 / 1024).toFixed(2);
  const read = new FileReader();
  return new Promise(function (resolve, reject) {
    read.readAsDataURL(file);
    read.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        // 默认按比例压缩
        const w = this.width * 0.8;
        const h = this.height * 0.8;
        console.log('w:' + w);
        console.log('h' + h);
        // 生成canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let base64;
        // 创建属性节点
        canvas.setAttribute('width', w);
        canvas.setAttribute('height', h);
        ctx.drawImage(this, 0, 0, w, h);
        // 进行图片压缩，小于1M不压缩原图上传；1-4M轻量压缩(0.7)，提高上传时间；大于4M尽量控制再4M以内，保证识别率
        if (fileSize <= 1) {
          base64 = canvas.toDataURL(file.type);
        } else if (fileSize > 1 && fileSize <= 4) {
          base64 = canvas.toDataURL(file.type, 0.7);
        } else {
          base64 = canvas.toDataURL(file.type, ((4 / fileSize) - 0.1));
        }
        // 这里对base64串进行操作，去掉url头，并转换为byte
        const bytes = window.atob(base64.split(',')[1]);
        const array = [];
        for (let i = 0; i < bytes.length; i++) {
          array.push(bytes.charCodeAt(i));
        }
        const blob = new Blob([new Uint8Array(array)], {
          type: file.type
        });
        unMountGlobalLoading();
        resolve(blob);
      };
    };
  });
};