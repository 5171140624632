/*
 * @Descripttion: 图片card
 * @Author: zhongyuanrui
 * @Date: 2019-10-11 14:40:35
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-10-24 18:56:41
 */
import React from 'react';
import PropTypes, { bool } from 'prop-types';
import styles from './style/imageCard.module.scss';
import { imageBaseUrl } from '@/api/http';

function ImageCard(props) {
  return (
    <div className={`${styles.imageCard} ${props.className}`}>
      <div className={styles.imageCardImage} onClick={props.onClickImage}>
        <img src={props.imageUrl ? props.imageUrl.indexOf('http') > -1 ? props.imageUrl : imageBaseUrl + props.imageUrl : ''} />
      </div>
      {props.ableRemoveImg && <div className={styles.removeImageButton} onClick={props.onClickRemove}>
        <img src={require('@/assets/image/sc.png').default} />
      </div>}
    </div>
  );
}

ImageCard.propTypes = {
  className: PropTypes.string,
  imageUrl: PropTypes.string,
  onClickImage: PropTypes.func,
  onClickRemove: PropTypes.func,
  ableRemoveImg: bool // 清除是否可见
};
ImageCard.defaultProps = {
  className: '',
  imageUrl: '',
  ableRemoveImg: true
};

export default ImageCard;
