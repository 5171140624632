import React from 'react';
import PropTypes from 'prop-types';
import styles from './style/labelItem.module.scss';

function LabelItem(props) {
  return (
    <div className={`${styles.labelItem} ${!props.bottomBorderVisible ? 'noBorder' : ''} ${props.className}`}>
      <div className={`${styles.label} ${props.changeWidth ? styles.changeWidth : ''}`}>{props.isRequisite && <span>*</span>}{props.label}</div>
      <div className={styles.content}>
        {props.children}
      </div>
      {props.handleContent && <div className={styles.handleContent}>
        {props.handleContent}
      </div>}
    </div>
  );
}

LabelItem.propTypes = {
  // 标签内容
  label: PropTypes.node,
  // 子元素
  children: PropTypes.node,
  // 是否显示底部边框
  bottomBorderVisible: PropTypes.bool,
  // 最右边操作内容
  handleContent: PropTypes.node,
  // 样式
  className: PropTypes.string,
  // 是否必填
  isRequisite: PropTypes.bool
};

LabelItem.defaultProps = {
  bottomBorderVisible: true,
  className: '',
  isRequisite: false,
  changeWidth: false
};

export default LabelItem;
