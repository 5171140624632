import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@/components';
import styles from './style/titleCard.module.scss';

function TitleCard (props) {
  return (
    <Card onClick={props.onClick}>
      <div className={styles.headerBox} onClick={props.onHeaderClick}>
        <div className={`${styles.titleBeforeLine} ${!props.showTitleBeforeLine ? styles.noTitleBeforeLine : ''}`}></div>
        <div className={`${styles.header} ${props.headerClassName} ${!props.showHeaderLine ? 'noBorder' : ''}`}>
          {props.title}
          <div className={styles.headerHandle}>
            {props.headerHandleChildren}
            {props.showHeaderArrow && <i className="iconfont iconarrow"></i>}
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {props.children}
      </div>
    </Card>
  );
}

TitleCard.propTypes = {
  // 标题
  title: PropTypes.string,
  // header部分的className
  headerClassName: PropTypes.string,
  // header部分的点击事件
  onHeaderClick: PropTypes.func,
  // header操作部分的节点
  headerHandleChildren: PropTypes.node,
  // 是否显示header部分的箭头
  showHeaderArrow: PropTypes.bool,
  // 是否显示title前面的线条
  showTitleBeforeLine: PropTypes.bool,
  // 是否显示header的下划线
  showHeaderLine: PropTypes.bool,
  onClick: PropTypes.func
};

TitleCard.defaultProps = {
  title: '',
  headerClassName: '',
  headerHandleChildren: '',
  showHeaderArrow: false,
  showTitleBeforeLine: true,
  showHeaderLine: true
};

export default TitleCard;
