/*
 * @Descripttion:车辆配置 -基础参数
 * @Author: zhongyuanrui
 * @Date: 2019-09-17 11:40:07
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-09-23 19:16:29
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TitleCard } from '@/components';
import ConfigItem from '../configItem/ConfigItem';

function BaseParams(props) {
  const data = props.data;
  return (
    <TitleCard title="基础参数" showHeaderLine={false}>
      {/* <ConfigItem label="市区工况油耗" value={props.data.a} /> */}
      <ConfigItem label="实测油耗(L/100km)" value={data && data.petrolTest ? data.petrolTest : ''} />
      <ConfigItem label="工信部综合油耗(L/100km)" value={data && data.petrol !== null ? data.petrol : ''} />
      <ConfigItem label="车身结构" value={data && data.bodystyle !== null ? data.bodystyle : ''} />
      <ConfigItem label="发动机" value={data && data.engine !== null ? data.engine : ''} />
      <ConfigItem label="实测100-0km/h制动(m)" value={data && data.brakeTest !== null ? data.brakeTest : ''} />
      <ConfigItem label="实测0-100km/h加速(s)" value={data && data.accelerateTest !== null ? data.accelerateTest : ''} />
      <ConfigItem label="官方0-100km/h加速(s)" value={data && data.accelerate !== null ? data.accelerate : ''} />
      <ConfigItem label="长*宽*高(mm)" value={`${data && data.length !== null ? data.length : '-'}*${data && data.width !== null ? data.width : '-'}*${data && data.heigh !== null ? data.heigh : '-'}`} />
      <ConfigItem label="级别" value={data && data.level !== null ? data.level : ''} />
      {/* <ConfigItem label="市郊工况油耗" value={data.a} /> */}
      <ConfigItem label="整车质保" value={data && data.vehicleWarranty !== null ? data.vehicleWarranty : ''} />
      <ConfigItem label="最高车速(km/h)" value={data && data.masspeed !== null ? data.masspeed : ''} />
      <ConfigItem label="变速箱" value={data && data.geardesc !== null ? data.geardesc : ''} />
      <ConfigItem label="厂商" value={data && data.factoryname !== null ? data.factoryname : ' '} />
    </TitleCard>
  );
}

BaseParams.propTypes = {
  data: PropTypes.object
};

export default BaseParams;
