import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NewLabelItem, NewTitleCard, NoData } from '@/components';
import { AuthType, CarType } from '@/localData';
// import { getAuthority } from '@/utils/getAuthority';
import styles from './auctionInquiry.module.scss';

function auctionInquiry(props) {
  const carBasic = props.carBasic;
  const retailInfo = props.retailInfo;
  return (
    <NewTitleCard title="认证信息">
      <NewLabelItem label="认证类型" handleContent={<div>{(carBasic && carBasic.authType !== null && AuthType[carBasic.authType]) ? AuthType[carBasic.authType + 1].label : ''}</div>} />
      <NewLabelItem label="认证编号" handleContent={<div>{(carBasic && carBasic.authNum) ? carBasic.authNum : ''}</div>} />
      {/* <NewLabelItem label="认证费用" handleContent={<div>{(baseInfo && baseInfo.retailInfo && baseInfo.retailInfo.saleStatus) ? '' : ''}</div>} /> */}
      <NewLabelItem label="车辆品类" handleContent={<div>{(retailInfo && carBasic.carType !== null && CarType[carBasic.carType + 1]) ? CarType[carBasic.carType + 1].label : ''}</div>} />
    </NewTitleCard>
  );
}
auctionInquiry.propTypes = {
  carBasic: PropTypes.object,
  retailInfo: PropTypes.object
};
export default withRouter(auctionInquiry);
