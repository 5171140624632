import React from 'react';
import styles from './style/topNavBar.module.scss';
import { BackButton } from '@/components';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { withRouter } from 'react-router-dom';

@withRouter
@autobind
class TopNavBar extends React.PureComponent {
  onBack() {
    if (this.props.onBack) {
      this.props.onBack();
    } else {
      this.props.history.goBack();
    }
  }

  render() {
    return (
      <div className={styles.topNavBar}>
        <div className={styles.leftHandle}>{this.props.leftHandle}</div>
        {this.props.showBack &&
          <BackButton onBack={this.onBack} />}
        {this.props.title}
        <div className={styles.rightHandle}>{this.props.rightHandle}</div>
      </div>
    );
  }
}

TopNavBar.propTypes = {
  // 标题
  title: PropTypes.string,
  // 返回事件
  onBack: PropTypes.func,
  // 是否显示返回按钮
  showBack: PropTypes.bool,
  // 右边操作内容slot
  rightHandle: PropTypes.node,
  // 左边操作内容slot
  leftHandle: PropTypes.node
};
TopNavBar.defaultProps = {
  title: '',
  showBack: true
};

export default TopNavBar;
