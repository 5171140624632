import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CarDetail from '../pages/carManage/carDetail/CarDetail';
// import loadable from '@/utils/loadable/loadable.jsx';
import CarList from '../pages/carManage/carList/CarList';
import CarConfigure from '../pages/carManage/carConfigure/CarConfigure';
import CarEnterStore from '../pages/carManage/carEnterStore/CarEnterStore';
import CarHostling from '../pages/carManage/carHostling/CarHostling';
import CarInfoEdit from '../pages/carManage/carInfoEdit/CarInfoEdit';
import CarOnShelve from '../pages/carManage/carOnShelve/CarOnShelve';
import CarOffShelve from '../pages/carManage/carOffShelve/CarOffShelve';
import CarPhotos from '../pages/carManage/carPhotos/CarPhotos';
import CarSaleApply from '../pages/carManage/carSaleApply/CarSaleApply';
import CarAddNew from '../pages/carManage/carAddNew/CarAddNew';
import CarMsgList from '../pages/carManage/carMsgList/CarMsgList';
import DealerEcharts from '../pages/dealerView/dealerEcharts/dealerEcharts';

export default class AppRouter extends React.Component {
  render() {
    return (
      <Router basename={process.env.REACT_APP_API_ROOT !== 'uat' ? '/h5' : '/h5_uat'}>
          <Switch>
            <Route exact path='/carList' component={CarList} />
            <Route exact path='/carDetail' component={CarDetail} />
            <Route exact path='/carConfigure' component={CarConfigure} />
            <Route exact path='/carEnterStore' component={CarEnterStore} />
            <Route exact path='/carHostling' component={CarHostling} />
            <Route exact path='/carInfoEdit' component={CarInfoEdit} />
            <Route exact path='/carOnShelve' component={CarOnShelve} />
            <Route exact path='/carOffShelve' component={CarOffShelve} />
            <Route exact path='/carPhotos' component={CarPhotos} />
            <Route exact path='/carSaleApply' component={CarSaleApply} />
            <Route exact path='/carAdd' component={CarAddNew} />
            <Route exact path='/carMsgList' component={CarMsgList} />
            <Route exact path='/dealerEcharts' component={DealerEcharts} />
          </Switch>
      </Router>
    );
  }
}
