import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { LabelItem, InputItem, Card, PickerItem, DatePickerItem, TopNavBar, Footer, Button, ImageUploadCard } from '@/components';
import { AreaPicker } from '@/containers/carInfoEdit';
import autobind from 'autobind-decorator';
import API from '@/api';
import { changePickerFormat } from '@/utils/dataFormat';
import { observer, inject } from 'mobx-react';
import { UseNatureList, TransferTimesList, CarColorList, UseTypeList } from '@/localData';
import styles from './modifyCarInfo.module.scss';
import { InformationCircleOutline } from 'antd-mobile-icons';
import { Toast, Picker } from 'antd-mobile';
import { Popover } from 'antd-mobile-v5';
import DALCommon from '@/constants/common/state';
import { runInAction } from 'mobx';

@inject('DALModifyCarInfo')
@observer
class ModifyCarInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleList: [],
      familyList: [],
      //
      familyName: [],
      // 车牌号
      plateNumber: '',
      // 车辆所有人
      carOwner: '',
      // vin
      vin: '',
      // 发动机号
      engineNumber: '',
      // 品牌车系
      vehicleName: [],
      // 上牌日期
      carLicenseDate: null,
      // 上牌地区
      carLicenseArea: [],
      // 行驶里程
      mileage: '',
      // 外观颜色
      carColor: [],
      // 出厂时间
      factoryDate: null,
      // 品牌id
      brandId: '',
      // 车系id
      familyId: '',
      // 是否展示地区
      isShowArea: true,
      // 车辆所在地
      carArea: [],
      // 使用性质
      useProperty: [],
      // 过户次数
      assignedNumber: []
    };
  }

  componentDidMount() {
    this.props.DALModifyCarInfo.updateData('basicId', DALCommon.basicId);
    this.props.DALModifyCarInfo.apiSelectCarInfo();
    this.props.DALModifyCarInfo.getFamilyList();
  }

  componentWillUnmount() {
    this.props.DALModifyCarInfo.updateData('basicId', null);
    this.props.DALModifyCarInfo.updateData('familyId', []);
    this.props.DALModifyCarInfo.updateData('vehicleId', []);
    this.props.DALModifyCarInfo.updateData('familyName', '');
    this.props.DALModifyCarInfo.updateData('vehicleName', '');
    this.props.DALModifyCarInfo.updateData('plateNumber', '');
    this.props.DALModifyCarInfo.updateData('carOwner', '');
    this.props.DALModifyCarInfo.updateData('vin', '');
    this.props.DALModifyCarInfo.updateData('engineNumber', '');
    this.props.DALModifyCarInfo.updateData('carLicenseDate', null);
    this.props.DALModifyCarInfo.updateData('carYearDate', null);
    this.props.DALModifyCarInfo.updateData('mileage', '');
    this.props.DALModifyCarInfo.updateData('carColor', []);
    this.props.DALModifyCarInfo.updateData('compulsoryLicenseDate', null);
    this.props.DALModifyCarInfo.updateData('businesssLicenseDate', null);
    this.props.DALModifyCarInfo.updateData('vehicleTaxDate', null);
    this.props.DALModifyCarInfo.updateData('carArea', [1, 1]);
    this.props.DALModifyCarInfo.updateData('useProperty', []);
    this.props.DALModifyCarInfo.updateData('useType', []);
    this.props.DALModifyCarInfo.updateData('assignedNumber', []);
    this.props.DALModifyCarInfo.updateData('contact', '');
    this.props.DALModifyCarInfo.updateData('contactNum', '');
  }

  @autobind
  updateChange(e, type) {
    this.props.DALModifyCarInfo.updateData(type, e.target.value);
  }

  @autobind
  updateFamilyChange(val, type) {
    this.props.DALModifyCarInfo.updateData(type, val);
    this.props.DALModifyCarInfo.getVehicleList();
  }

  @autobind
  updateVehicleChange(val, type) {
    this.props.DALModifyCarInfo.updateData(type, val);
  }

  @autobind
  updateTimeChange(val) {
    this.props.DALModifyCarInfo.updateData('carLicenseDate', val);
  }

  @autobind
  updateYearDateChange(val) {
    this.props.DALModifyCarInfo.updateData('carYearDate', val);
  }

  @autobind
  updateAreaChange(val) {
    this.props.DALModifyCarInfo.updateData('carLicenseArea', val);
  }

  @autobind
  updateCarAreaChange(val) {
    this.props.DALModifyCarInfo.updateData('carArea', val);
  }

  @autobind
  updateCompulsoryDateChange(val) {
    this.props.DALModifyCarInfo.updateData('compulsoryLicenseDate', val);
  }

  @autobind
  updateBusinesssDateChange(val) {
    this.props.DALModifyCarInfo.updateData('businesssLicenseDate', val);
  }

  @autobind
  updateVehicleDateChange(val) {
    this.props.DALModifyCarInfo.updateData('vehicleTaxDate', val);
  }

  // vin码识别
  @autobind
  onVinIdentify() {
    this.props.DALModifyCarInfo.getVinIdentifySucc();
  }

  // 行驶证识别
  onIdentifyLicense = (e) => {
    this.props.DALModifyCarInfo.getIdenfityLicenseSucc(e).then(res2 => {
      this.props.DALModifyCarInfo.identifyPlateNo(this.refs.myArea);
    });
  }

  submitCarInfo = () => {
    this.props.DALModifyCarInfo.sumbitCarInfo().then(res => {
      if (res) {
        Toast.info('提交成功');
        this.props.history.goBack();
      }
    });
  }

  render() {
    return (
      <div>
        <TopNavBar title={'编辑车辆信息'}></TopNavBar>
        <div className={styles.cardWrap}>
          <ImageUploadCard onChange={(e) => this.onIdentifyLicense(e)} />
          <Card>
            <div className={styles.title}>
              车辆信息
              <span className={styles.tipText}>(只支持5年内车型录入)</span>
            </div>
            <LabelItem label="VIN" isRequisite={true} bottomBorderVisible={true} handleContent={<span className={styles.vinText} onClick={this.onVinIdentify}>车架号识别</span>}>
              <InputItem placeholder="请输入VIN" onChange={(e) => this.updateChange(e, 'vin')} value={this.props.DALModifyCarInfo.vin} maxLength={17} />
            </LabelItem>
            <LabelItem label="品牌" isRequisite={true}>
              <InputItem type='brandName' value={'凯迪拉克'} disabled={true} />
            </LabelItem>
            <LabelItem label="车系" isRequisite={true}>
              <PickerItem pickerData={this.props.DALModifyCarInfo.familyList} placeholder="请选择车系" type='familyId' onOk={this.updateFamilyChange} value={this.props.DALModifyCarInfo.familyId} />
            </LabelItem>
            <LabelItem label="具体车型" isRequisite={true}>
              <PickerItem pickerData={this.props.DALModifyCarInfo.vehicleList} placeholder="请选择具体车型" onOk={this.updateVehicleChange} type='vehicleId' value={this.props.DALModifyCarInfo.vehicleId} />
            </LabelItem>
            <LabelItem label="车牌号" isRequisite={false} bottomBorderVisible={true}>
              <InputItem placeholder="请输入车牌号" onChange={(e) => this.updateChange(e, 'plateNumber')} value={this.props.DALModifyCarInfo.plateNumber} />
            </LabelItem>
            <LabelItem label="发动机号" isRequisite={false}>
              <InputItem placeholder="请输入发动机号" onChange={(e) => this.updateChange(e, 'engineNumber')} value={this.props.DALModifyCarInfo.engineNumber} />
            </LabelItem>
            <LabelItem label="表显里程" isRequisite={true} handleContent="公里">
              <InputItem placeholder="请输入表显里程" onChange={(e) => this.updateChange(e, 'mileage')} value={this.props.DALModifyCarInfo.mileage} maxLength={6} type='tel' />
            </LabelItem>
            <AreaPicker ref="myArea" label="车辆所在地" placeholder="请选择车辆所在地" onSelectArea={this.updateCarAreaChange} defaultValue={this.props.DALModifyCarInfo.carArea} cols={2} isRequisite={false} bottomBorderVisible={true} />
            <LabelItem label="上牌时间" isRequisite={true}>
              <DatePickerItem placeholder="请选择上牌时间" onOk={this.updateTimeChange} value={this.props.DALModifyCarInfo.carLicenseDate} minDate={this.props.year ? this.props.year : '2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
            <LabelItem label="年审时间" isRequisite={true}>
              <DatePickerItem placeholder="请选择年审时间" onOk={this.updateYearDateChange} value={this.props.DALModifyCarInfo.carYearDate} minDate={this.props.year ? this.props.year : '2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
            <LabelItem label="外观颜色" isRequisite={true} bottomBorderVisible={true}>
              <PickerItem pickerData={CarColorList} placeholder="请选择外观颜色" onOk={this.updateVehicleChange} value={this.props.DALModifyCarInfo.carColor} type='carColor' />
            </LabelItem>
            <LabelItem label="交强险到期日" isRequisite={true} bottomBorderVisible={true}>
              <DatePickerItem placeholder="请选择交强险到期日" onOk={this.updateCompulsoryDateChange} value={this.props.DALModifyCarInfo.compulsoryLicenseDate} minDate={'2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
            <LabelItem label="商业险到期日" isRequisite={false} bottomBorderVisible={true}>
              <DatePickerItem placeholder="请选择商业险到期日" onOk={this.updateBusinesssDateChange} value={this.props.DALModifyCarInfo.businesssLicenseDate} minDate={'2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
            <LabelItem label="车船税到期日" isRequisite={false} bottomBorderVisible={false}>
              <DatePickerItem placeholder="请选择车船税到期日" onOk={this.updateVehicleDateChange} value={this.props.DALModifyCarInfo.vehicleTaxDate} minDate={'2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
          </Card>
          <Card>
            <div className={styles.title}>牌照信息</div>
            <LabelItem label="使用性质" bottomBorderVisible={true}>
              <PickerItem pickerData={UseNatureList} placeholder="请选择使用性质" onOk={this.updateVehicleChange} value={this.props.DALModifyCarInfo.useProperty} type='useProperty' />
            </LabelItem>
            <LabelItem label="车辆用途" isRequisite={true} bottomBorderVisible={true}>
              <PickerItem pickerData={UseTypeList} placeholder="请选择车辆用途" onOk={this.updateVehicleChange} value={this.props.DALModifyCarInfo.useType} type='useType' />
            </LabelItem>
            <LabelItem label="车辆所有人" isRequisite={false}>
              <InputItem placeholder="请输入车辆所有人" onChange={(e) => this.updateChange(e, 'carOwner')} value={this.props.DALModifyCarInfo.carOwner} />
            </LabelItem>
            <LabelItem label="过户次数" bottomBorderVisible={true} isRequisite={true}>
              <PickerItem pickerData={TransferTimesList} placeholder="请选择过户次数" onOk={this.updateVehicleChange} value={this.props.DALModifyCarInfo.assignedNumber} type='assignedNumber' />
            </LabelItem>
          </Card>
          <Card>
            <div className={styles.title}>二手车销售顾问</div>
            <LabelItem label="联系人" isRequisite={false}>
              <InputItem placeholder="请输入联系人" onChange={(e) => this.updateChange(e, 'contact')} value={this.props.DALModifyCarInfo.contact} />
            </LabelItem>
            <LabelItem label="联系电话" isRequisite={false} bottomBorderVisible={true}>
              <InputItem placeholder="请输入联系电话" onChange={(e) => this.updateChange(e, 'contactNum')} value={this.props.DALModifyCarInfo.contactNum} type='tel' maxLength={11} />
            </LabelItem>
          </Card>
          <Footer>
            <Button label="提交" type="primary" onClick={this.submitCarInfo} disabled={false} />
          </Footer>
        </div>
        <Picker
          data={this.props.DALModifyCarInfo.pickerList}
          cols={1}
          visible={this.props.DALModifyCarInfo.pickerVisible}
          onDismiss={() => {
            this.props.DALModifyCarInfo.updateData('pickerVisible', false);
          }}
          value={this.props.DALModifyCarInfo.pickerValue}
          onOk={v => {
            this.props.DALModifyCarInfo.updateData('vehicleId', v);
            this.props.DALModifyCarInfo.updateData('pickerVisible', false);
          }}
        />
      </div>
    );
  }
}

export default withRouter(ModifyCarInfo);
