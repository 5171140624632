import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { LabelItem, InputItem, Card, PickerItem, DatePickerItem, TopNavBar, Footer, Button, ImageUploadCard } from '@/components';
import { AreaPicker } from '@/containers/carInfoEdit';
import autobind from 'autobind-decorator';
import API from '@/api';
import { changePickerFormat } from '@/utils/dataFormat';
import { observer, inject } from 'mobx-react';
import { UseNatureList, TransferTimesList, CarColorList, UseTypeList } from '@/localData';
import styles from './carAddInfo.module.scss';
import { InformationCircleOutline } from 'antd-mobile-icons';
import { Toast, Picker, List } from 'antd-mobile';
import { Popover } from 'antd-mobile-v5';
import { string } from 'prop-types';

@inject('DALCarAddInfo')
@observer
class CarAddInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.DALCarAddInfo.getFamilyList();
    // this.props.DALCarAddInfo.getAreaList();
    // this.props.DALCarAddInfo.getVehicleList();
    // setTimeout(() => {
    // this.props.DALCarAddInfo.updateData('carArea', [12, 110]);
    // console.log(this.props.DALCarAddInfo.carArea);
    // const i = this.refs.myArea;
    // i.initData();
    // }, 5000);
  }

  componentWillUnmount() {
    this.props.DALCarAddInfo.updateData('familyId', []);
    this.props.DALCarAddInfo.updateData('vehicleId', []);
    this.props.DALCarAddInfo.updateData('familyName', '');
    this.props.DALCarAddInfo.updateData('vehicleName', '');
    this.props.DALCarAddInfo.updateData('plateNumber', '');
    this.props.DALCarAddInfo.updateData('carOwner', '');
    this.props.DALCarAddInfo.updateData('vin', '');
    this.props.DALCarAddInfo.updateData('engineNumber', '');
    this.props.DALCarAddInfo.updateData('carLicenseDate', null);
    this.props.DALCarAddInfo.updateData('carYearDate', null);
    this.props.DALCarAddInfo.updateData('mileage', '');
    this.props.DALCarAddInfo.updateData('carColor', []);
    this.props.DALCarAddInfo.updateData('compulsoryLicenseDate', null);
    this.props.DALCarAddInfo.updateData('businesssLicenseDate', null);
    this.props.DALCarAddInfo.updateData('vehicleTaxDate', null);
    this.props.DALCarAddInfo.updateData('carArea', [1, 1]);
    this.props.DALCarAddInfo.updateData('useProperty', []);
    this.props.DALCarAddInfo.updateData('useType', []);
    this.props.DALCarAddInfo.updateData('assignedNumber', []);
    this.props.DALCarAddInfo.updateData('contact', '');
    this.props.DALCarAddInfo.updateData('contactNum', '');
  }

  @autobind
  updateChange(e, type) {
    this.props.DALCarAddInfo.updateData(type, e.target.value);
  }

  @autobind
  updateFamilyChange(val, type) {
    this.props.DALCarAddInfo.updateData(type, val);
    this.props.DALCarAddInfo.getVehicleList();
  }

  @autobind
  updateVehicleChange(val, type) {
    this.props.DALCarAddInfo.updateData(type, val);
  }

  onAreaChange(v) {
    this.props.DALCarAddInfo.updateData('carLicenseArea', v);
  }

  @autobind
  updateTimeChange(val) {
    this.props.DALCarAddInfo.updateData('carLicenseDate', val);
  }

  @autobind
  updateYearDateChange(val) {
    this.props.DALCarAddInfo.updateData('carYearDate', val);
  }

  @autobind
  updateAreaChange(val) {
    this.props.DALCarAddInfo.updateData('carLicenseArea', val);
  }

  @autobind
  updateCarAreaChange(val) {
    this.props.DALCarAddInfo.updateData('carArea', val);
  }

  @autobind
  updateCompulsoryDateChange(val) {
    this.props.DALCarAddInfo.updateData('compulsoryLicenseDate', val);
  }

  @autobind
  updateBusinesssDateChange(val) {
    this.props.DALCarAddInfo.updateData('businesssLicenseDate', val);
  }

  @autobind
  updateVehicleDateChange(val) {
    this.props.DALCarAddInfo.updateData('vehicleTaxDate', val);
  }

  // vin码识别
  @autobind
  onVinIdentify() {
    this.props.DALCarAddInfo.getVinIdentifySucc();
  }

  // 行驶证识别
  onIdentifyLicense = (e) => {
    this.props.DALCarAddInfo.getIdenfityLicenseSucc(e).then(res2 => {
      this.props.DALCarAddInfo.identifyPlateNo(this.refs.myArea);
    });
  }

  submitCarInfo = () => {
    this.props.DALCarAddInfo.sumbitCarInfo().then(res => {
      if (res) {
        Toast.info('提交成功');
        this.props.history.goBack();
      }
    });
  }

  render() {
    return (
      <div>
        <TopNavBar title={'添加车源'}></TopNavBar>
        <div className={styles.cardWrap}>
          <ImageUploadCard onChange={this.onIdentifyLicense} />
          <Card>
            <div className={styles.title}>
              车辆信息
              <span className={styles.tipText}>(只支持5年内车型录入)</span>
            </div>
            <LabelItem label="VIN" isRequisite={true} bottomBorderVisible={true} handleContent={<span className={styles.vinText} onClick={this.onVinIdentify}>车架号识别</span>}>
              <InputItem placeholder="请输入VIN" onChange={(e) => this.updateChange(e, 'vin')} value={this.props.DALCarAddInfo.vin} maxLength={17} />
            </LabelItem>
            <LabelItem label="品牌" isRequisite={true}>
              <InputItem type='brandName' value={'凯迪拉克'} disabled={true} />
            </LabelItem>
            <LabelItem label="车系" isRequisite={true}>
              <PickerItem pickerData={this.props.DALCarAddInfo.familyList} placeholder="请选择车系" type='familyId' onOk={this.updateFamilyChange} value={this.props.DALCarAddInfo.familyId} />
            </LabelItem>
            <LabelItem label="具体车型" isRequisite={true}>
              <PickerItem pickerData={this.props.DALCarAddInfo.vehicleList} placeholder="请选择具体车型" onOk={this.updateVehicleChange} type='vehicleId' value={this.props.DALCarAddInfo.vehicleId} />
            </LabelItem>
            <LabelItem label="车牌号" isRequisite={false} bottomBorderVisible={true}>
              <InputItem placeholder="请输入车牌号" onChange={(e) => this.updateChange(e, 'plateNumber')} value={this.props.DALCarAddInfo.plateNumber} />
            </LabelItem>
            <LabelItem label="发动机号" isRequisite={false}>
              <InputItem placeholder="请输入发动机号" onChange={(e) => this.updateChange(e, 'engineNumber')} value={this.props.DALCarAddInfo.engineNumber} />
            </LabelItem>
            <LabelItem label="表显里程" isRequisite={true} handleContent="公里" bottomBorderVisible={true}>
              <InputItem placeholder="请输入表显里程" onChange={(e) => this.updateChange(e, 'mileage')} value={this.props.DALCarAddInfo.mileage} maxLength={6} type='tel' />
            </LabelItem>
            {/* <List className={styles.pickerList}> */}
            {/* <Picker title="选择地区" data={this.state.cityList} cols={2} value={[0, 1]} extra="请选择(可选)" onOk={v => this.onAreaChange(v)}> */}
            {/* <List.Item arrow="horizontal" className={styles.areaListItem}><span>*</span>车辆所在地</List.Item> */}
            {/* </Picker> */}
            {/* </List> */}
            <AreaPicker ref="myArea" label="车辆所在地" placeholder="请选择车辆所在地" onSelectArea={this.updateCarAreaChange} defaultValue={this.props.DALCarAddInfo.carArea} cols={2} isRequisite={true} bottomBorderVisible={true} ondefaultValueChange={this.onAreaDefaultChange} />
            <LabelItem label="上牌时间" isRequisite={true}>
              <DatePickerItem placeholder="请选择上牌时间" onOk={this.updateTimeChange} value={this.props.DALCarAddInfo.carLicenseDate} minDate={this.props.year ? this.props.year : '2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
            <LabelItem label="年审时间" isRequisite={true}>
              <DatePickerItem placeholder="请选择年审时间" onOk={this.updateYearDateChange} value={this.props.DALCarAddInfo.carYearDate} minDate={this.props.year ? this.props.year : '2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
            <LabelItem label="外观颜色" isRequisite={true} bottomBorderVisible={true}>
              <PickerItem pickerData={CarColorList} placeholder="请选择外观颜色" onOk={this.updateVehicleChange} value={this.props.DALCarAddInfo.carColor} type='carColor' />
            </LabelItem>
            <LabelItem label="交强险到期日" isRequisite={true} bottomBorderVisible={true}>
              <DatePickerItem placeholder="请选择交强险到期日" onOk={this.updateCompulsoryDateChange} value={this.props.DALCarAddInfo.compulsoryLicenseDate} minDate={'2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
            <LabelItem label="商业险到期日" isRequisite={false} bottomBorderVisible={true}>
              <DatePickerItem placeholder="请选择商业险到期日" onOk={this.updateBusinesssDateChange} value={this.props.DALCarAddInfo.businesssLicenseDate} minDate={'2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
            <LabelItem label="车船税到期日" isRequisite={false} bottomBorderVisible={false}>
              <DatePickerItem placeholder="请选择车船税到期日" onOk={this.updateVehicleDateChange} value={this.props.DALCarAddInfo.vehicleTaxDate} minDate={'2000-01-01'} dateFormat='YYYY-MM-DD' />
            </LabelItem>
          </Card>
          <Card>
            <div className={styles.title}>牌照信息</div>
            <LabelItem label="使用性质" bottomBorderVisible={true}>
              <PickerItem pickerData={UseNatureList} placeholder="请选择使用性质" onOk={this.updateVehicleChange} value={this.props.DALCarAddInfo.useProperty} type='useProperty' />
            </LabelItem>
            <LabelItem label="车辆用途" isRequisite={true} bottomBorderVisible={true}>
              <PickerItem pickerData={UseTypeList} placeholder="请选择车辆用途" onOk={this.updateVehicleChange} value={this.props.DALCarAddInfo.useType} type='useType' />
            </LabelItem>
            <LabelItem label="车辆所有人" isRequisite={false}>
              <InputItem placeholder="请输入车辆所有人" onChange={(e) => this.updateChange(e, 'carOwner')} value={this.props.DALCarAddInfo.carOwner} />
            </LabelItem>
            <LabelItem label="过户次数" bottomBorderVisible={true} isRequisite={true}>
              <PickerItem pickerData={TransferTimesList} placeholder="请选择过户次数" onOk={this.updateVehicleChange} value={this.props.DALCarAddInfo.assignedNumber} type='assignedNumber' />
            </LabelItem>
          </Card>
          <Card>
            <div className={styles.title}>二手车销售顾问</div>
            <LabelItem label="联系人" isRequisite={false}>
              <InputItem placeholder="请输入联系人" onChange={(e) => this.updateChange(e, 'contact')} value={this.props.DALCarAddInfo.contact} />
            </LabelItem>
            <LabelItem label="联系电话" isRequisite={false} bottomBorderVisible={true}>
              <InputItem placeholder="请输入联系电话" onChange={(e) => this.updateChange(e, 'contactNum')} value={this.props.DALCarAddInfo.contactNum} maxLength={11} type='tel' />
            </LabelItem>
          </Card>
          <Footer>
            <Button label="提交" type="primary" onClick={this.submitCarInfo} disabled={false} />
          </Footer>
        </div>
        <Picker
          data={this.props.DALCarAddInfo.pickerList}
          cols={1}
          visible={this.props.DALCarAddInfo.pickerVisible}
          onDismiss={() => {
            this.props.DALCarAddInfo.updateData('pickerVisible', false);
          }}
          value={this.props.DALCarAddInfo.pickerValue}
          onOk={v => {
            this.props.DALCarAddInfo.updateData('vehicleId', v);
            this.props.DALCarAddInfo.updateData('pickerVisible', false);
          }}
        />
      </div>
    );
  }
}

export default withRouter(CarAddInfo);
