/*
 * @Author: zhongyuanrui
 * @Date: 2019-09-22 13:43:21
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-11-14 15:09:06
 */
import {
  observable,
  action,
  runInAction
} from 'mobx';
import API from '@/api';
import DALCommon from '@/constants/common/state';
import {
  Toast
} from 'antd-mobile';
import {
  formatDateString
} from '@/utils/date';
import {
  mountGlobalLoading,
  unMountGlobalLoading
} from '@/components/loading/RenderGlobalLoading';
import { changePickerFormat } from '@/utils/dataFormat';
import { changeFile2Blob } from '@/utils/image';

class DALCarAddInfo {
  static getInstance() {
    // if (!DALCarAddInfo.instance) {
    // DALCarAddInfo.instance = new DALCarAddInfo();
    // }
    DALCarAddInfo.instance = new DALCarAddInfo();
    return DALCarAddInfo.instance;
  }

  @observable brandName = '凯迪拉克';
  // 品牌id
  @observable brandId = '58';
  @observable familyList = [];
  // 车系id
  @observable familyId = [];
  // 车系
  @observable familyName = '';
  @observable vehicleList = [];
  //
  @observable vehicleId = [];
  // 品牌车型
  @observable vehicleName = '';
  // 车牌号
  @observable plateNumber = '';
  // 车辆所有人
  @observable carOwner = '';
  // vin
  @observable vin = '';
  // 发动机号
  @observable engineNumber = '';
  // 上牌日期
  @observable carLicenseDate = null;
  // 年审日期
  @observable carYearDate = null;
  // 上牌地区
  // @observable carLicenseArea = [];
  // 行驶里程
  @observable mileage = '';
  // 外观颜色
  @observable carColor = [];
  // 交强险到期时间
  @observable compulsoryLicenseDate = null;
  // 交强险到期时间
  @observable businesssLicenseDate = null;
  // 交强险到期时间
  @observable vehicleTaxDate = null;
  // 是否展示地区
  @observable isShowArea = true;
  // 车辆所在地
  @observable carArea = [1, 1];
  // 使用性质
  @observable useProperty = [];
  // 车辆用途
  @observable useType = []
  // 过户次数
  @observable assignedNumber = [];

  @observable contact = '' // 联系人

  @observable contactNum = '' // 联系电话

  // vin识别车型弹窗
  @observable pickerList = [];
  @observable pickerVisible = false;
  @observable pickerValue = ''

  // 更新数据
  @action.bound
  updateData(key, value) {
    this[key] = value;
  }

  async getFamilyList() {
    await API.CarSource.getFamilyList().then(res => {
      if (res.code === 200) {
        //
        this.familyList = changePickerFormat(res.data, 'name', 'id');
      }
    });
    console.log(this.carArea);
  }

  async getVehicleList() {
    const familyId = this.familyId[0];
    await API.CarSource.getVehicleList({ familyId: familyId }).then(res => {
      if (res.code === 200) {
        //
        this.vehicleList = changePickerFormat(res.data, 'name', 'id');
      }
    });
  }

  // 识别vin
  async getVinIdentifySucc() {
    const vin = this.vin;
    if (vin === '') {
      Toast.info('VIN位数为空');
      return;
    }
    if ((vin + '').length !== 17) {
      Toast.info('VIN位数必须为17位');
      return;
    }
    mountGlobalLoading();
    const res = await API.CarSource.identifyVin({ vin: vin });
    if (res.code === 200 && res.data && res.data.length > 0) {
      Toast.info('vin码查询成功');
      this.familyId = [res.data[0].familyid];
      this.getVehicleList();
      if (res.data.length === 1) {
        this.vehicleId = [res.data[0].unicdatakey];
      } else if (res.data.length > 1) {
        const list = [];
        res.data.map((item, index) => {
          //
          list.push({
            label: item.salesdesc,
            value: item.unicdatakey
          });
        });
        this.pickerList = list;
        this.pickerVisible = true;
      }
    } else {
      Toast.info('vin码查询失败，请手动输入车系车型');
    }
    unMountGlobalLoading();
  }

  // 识别行驶证
  async getIdenfityLicenseSucc(e) {
    mountGlobalLoading();
    if (e.target.files) {
      const _file = e.target.files[0];
      const _formData = new FormData();
      // _formData.append('file', _file);
      const _blob = await changeFile2Blob(_file);
      _formData.append('file', _blob, `${_file.name}`);
      _formData.append('type', _blob.type);
      _formData.append('size', _blob.size);
      const res = await API.CarSource.identifyLicense(_formData);
      if (res.code === 200 && res.data) {
        Toast.info('行驶证识别成功，请仔细核对信息');
        runInAction(() => {
          if (res.data.engineNo) {
            this.engineNumber = res.data.engineNo;
          }
          if (res.data.registerDate) {
            this.carLicenseDate = res.data.registerDate;
          }
          if (res.data.userCharecter) {
            if (res.data.userCharecter === '非营运') {
              this.useProperty = [1];
            } else if (res.data.userCharecter === '租赁非营运') {
              this.useProperty = [2];
            } else if (res.data.userCharecter === '营运') {
              this.useProperty = [3];
            } else if (res.data.userCharecter === '营转非') {
              this.useProperty = [4];
            }
          }
          if (res.data.owner) {
            this.carOwner = res.data.owner;
          }
          if (res.data.vin) {
            this.vin = res.data.vin;
            // this.getVinIdentifySucc();
          }
          if (res.data.plateNo) {
            this.plateNumber = res.data.plateNo;
          }
        });
      } else {
        Toast.info('行驶证识别失败,请手动输入信息');
      }
    }
    unMountGlobalLoading();
  }

  async identifyPlateNo(a) {
    const myArea = a;
    const plateNo = this.plateNumber;
    if (!this.plateNumber) {
      return false;
    }
    const res = await API.CarSource.identifyLineArea({ plateNumber: plateNo });
    if (res.code === 200 && res.data && res.data.province && res.data.city) {
      //
      runInAction(() => {
        const provinceId = res.data.province.id;
        const cityId = res.data.city.id;
        this.carArea = [provinceId, cityId];
        myArea.initData();
        console.log(this.carArea);
        return true;
      });
    }
    return false;
  }

  // 提交
  @action.bound
  async sumbitCarInfo() {
    if (DALCommon.blocNum === null) {
      Toast.info('经销商信息不全');
      return false;
    }

    if (!this.vin) {
      Toast.info('请输入VIN');
      return false;
    }
    if ((this.vin + '').length !== 17) {
      Toast.info('VIN位数必须为17位');
      return false;
    }
    if (this.familyId.length === 0) {
      Toast.info('请选择车系');
      return false;
    }
    if (this.vehicleId.length === 0) {
      Toast.info('请选择具体车型');
      return false;
    }
    if (!this.carLicenseDate) {
      Toast.info('请选择上牌日期');
      return false;
    }
    if (this.carArea.length === 0) {
      Toast.info('请选择车辆所在地');
      return false;
    }
    if (!this.mileage) {
      Toast.info('请输入表显里程');
      return false;
    }
    if (!/^\d+(\.\d+)?$/.test(this.mileage)) {
      Toast.info('请输入正确的行驶里程数');
      return false;
    }
    if (this.assignedNumber.length === 0) {
      Toast.info('请选择过户次数');
      return false;
    }
    if (!this.carYearDate) {
      Toast.info('请选择年审时间');
      return false;
    }
    if (this.carColor.length === 0) {
      Toast.info('请选择车辆外观颜色');
      return false;
    }
    if (!this.compulsoryLicenseDate) {
      Toast.info('请选择交强险到期日');
      return false;
    }
    if (this.useType.length === 0) {
      Toast.info('请选择车辆用途');
      return false;
    }
    const _data = {
      blocNum: DALCommon.blocNum,
      brandId: this.brandId,
      familyId: this.familyId[0],
      assignedNumber: this.assignedNumber[0],
      carLicenseDate: formatDateString(this.carLicenseDate),
      mileage: this.mileage,
      vehicleId: this.vehicleId[0],
      //
      plateNumber: this.plateNumber,
      carOwner: this.carOwner,
      vin: this.vin,
      engineNumber: this.engineNumber,
      sourceProvinceId: this.carArea[0],
      sourceCityId: this.carArea[1],
      carColor: this.carColor[0],
      useProperty: this.useProperty[0],
      useType: this.useType[0],
      factoryDate: formatDateString(this.factoryDate),
      carYearDate: formatDateString(this.carYearDate),
      compulsoryLicenseDate: formatDateString(this.compulsoryLicenseDate),
      businesssLicenseDate: formatDateString(this.businesssLicenseDate),
      vehicleTaxDate: formatDateString(this.vehicleTaxDate),
      contact: this.contact,
      contactNum: this.contactNum
    };
    const res = await API.CarSource.addCar(_data);
    return res.code === 200;
  }
}

export default DALCarAddInfo.getInstance();
