import React from 'react';
import { CarBaseInfo, PurchaseInfo, CarConInfo, AuctionInquiry, ChannelInfo, OrderInfo, BuyInfo, MakeReadyInfo, ApplyInfo } from '@/containers/carDetail';
import PropTypes, { oneOfType } from 'prop-types';
import { observer } from 'mobx-react';
import autobind from 'autobind-decorator';

export default function carDetailInfo(props) {
  return (
    <div>
      {/* 审批信息 */}
      <ApplyInfo
        baseInfo={props.approvalInfo}
        carBasic={props.carBasic}
      />
      {/* 车辆信息 */}
      <CarBaseInfo
        baseInfo={props.carBasic}
        basicId={props.basicId}
      />
      {/* 牌证信息 */}
      <PurchaseInfo
        carBasic={props.carBasic}
      />
      {/* 零售信息 */}
      <CarConInfo
        retailInfo={props.retailInfo}
        carBasic={props.carBasic}
        dealerBloc={props.dealerBloc}
        orderInfo={props.orderInfo}
      />
      {/* 认证信息 */}
      <AuctionInquiry
        carBasic={props.carBasic}
        retailInfo={props.retailInfo}
      />
      {/* 展示渠道 */}
      <ChannelInfo
        retailInfo={props.retailInfo}
      />
      {/* 订单信息 */}
      <OrderInfo
        baseInfo={props.orderInfo}
      />
      {/* 采购信息 */}
      <BuyInfo
        baseInfo={props.purchaseInfo}
      />
      {/* 整备信息 */}
      <MakeReadyInfo
        baseInfo={props.makeReadyInfo}
      />
    </div>
  );
}
carDetailInfo.propTypes = {
  approvalInfo: PropTypes.object,
  carBasic: PropTypes.object,
  dealerBloc: PropTypes.object,
  makeReadyInfo: PropTypes.object,
  orderInfo: PropTypes.object,
  purchaseInfo: PropTypes.object,
  retailInfo: PropTypes.object,
  basicId: oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};
carDetailInfo.defaultProps = {
  approvalInfo: {},
  carBasic: {},
  dealerBloc: {},
  makeReadyInfo: {},
  orderInfo: {},
  purchaseInfo: {},
  retailInfo: {},
  basicId: null
};
