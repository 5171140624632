/*
 * @Descripttion:日期
 * @Author: zhongyuanrui
 * @Date: 2019-05-16 15:48:49
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2019-10-16 16:23:45
 */
import moment from 'moment';
import { func } from 'prop-types';
import {
  checkDataType
} from '../dataFormat';
import { isiOS } from '../device';

/**
 * @description 转换时间格式
 * @export formatDateString
 * @param {*} date 传入日期对象或者日期格式的字符
 * @param {string} [format='YYYY-MM-DD']
 * @returns {string} date 返回日期格式字符串
 */
export function formatDateString(date, format = 'YYYY-MM-DD') {
  let _date = '';
  if (checkDataType(date) === 'String') {
    _date = moment(date).format(format);
  } else if (checkDataType(date) === 'Date') {
    _date = moment(date).format(format);
  } else {
    _date = date;
  }
  return _date;
}
/**
 * @description 转换时间对象
 * @export formatDateObject
 * @param {*} date 传入日期对象或者日期格式的字符
 * @param {*} format 传入日期对象或者日期格式的格式化样式
 * @returns {Date} date 返回日期对象
 */
export function formatDateObject(date, format = 'YYYY-MM-DD') {
  let _date = '';
  if (checkDataType(date) === 'String') {
    if (isiOS) {
      date = date.replace(/-/g, '/');
    }
    _date = new Date(date);
  } else if (checkDataType(date) === 'Date') {
    _date = date;
  } else {
    _date = date;
  }
  return _date;
}
// 获取当前格式化的时间
export function getCurrentDate(format = 'YYYY-MM-DD hh:mm:ss') {
  return moment(new Date()).format(format);
}

export function compareSimpleArray(a, b) {
  //
  if (a.length !== b.length) {
    return false;
  } else {
    a.map((item, index) => {
      if (a[index] !== b[index]) {
        return false;
      }
      return true;
    });
  }
}