import {
  observable,
  action
} from 'mobx';
import API from '@/api';
import DALCommon from '@/constants/common/state';
import {
  Toast
} from 'antd-mobile';
import { mergePhotosArr } from '@/utils/dataFormat';

class DALCarValuation {
  static getInstance() {
    if (!DALCarValuation.instance) {
      DALCarValuation.instance = new DALCarValuation();
    }
    return DALCarValuation.instance;
  }

  @observable isRenderPic = true;
  @observable basicId = null; // 车源基本表主键
  @observable authNum = ''; // 认证编号
  @observable authType = [];// 认证类型 0-安吉星认证 1-诚新认证
  @observable carLicenseDate = null;// 上牌时间
  @observable carType = [];// 车辆品类 0-优选一年车 1-精品车
  @observable mileage = '';// 表显里程（公里）
  @observable purchasePirce = '';// 收购价格
  @observable salePrice = '';// 销售定价
  @observable saleSugestPrice=''; // 建议销售定价
  @observable vin = '';// 车架号
  // 其他照片
  @observable otherPhotos = []; // code  4-1
  @observable photos11 = [];

  @observable photos2 = []; // 接口获取的所有图片
  @observable photos1 = [ // 车辆照片
    {
      code: '1-1',
      name: '45度角照片',
      url: '',
      qczj: '',
      bgImage: 'zq45',
      ableUploadImg: true, // 是否支持上传图片
      ableRemoveImg: false, // 清除图片按钮是否可见
      isRequire: false // 必填*号
    },
    {
      code: '1-2',
      name: '正前方',
      url: '',
      qczj: '',
      bgImage: 'yh45',
      ableUploadImg: true,
      ableRemoveImg: false,
      isRequire: false
    },
    {
      code: '1-3',
      name: '正后方',
      url: '',
      qczj: '',
      bgImage: 'zqm',
      ableUploadImg: true,
      ableRemoveImg: false,
      isRequire: false
    },
    {
      code: '1-4',
      name: '正侧面',
      url: '',
      qczj: '',
      bgImage: 'yqm',
      ableUploadImg: true,
      ableRemoveImg: false,
      isRequire: false
    },
    {
      code: '1-5',
      name: '发动机舱',
      url: '',
      qczj: '',
      bgImage: 'zhm',
      ableUploadImg: true,
      ableRemoveImg: false,
      isRequire: false
    },
    {
      code: '1-6',
      name: '后备箱',
      url: '',
      qczj: '',
      bgImage: 'zkt',
      ableUploadImg: true,
      ableRemoveImg: false,
      isRequire: false
    },
    {
      code: '1-7',
      name: '仪表盘',
      url: '',
      qczj: '',
      bgImage: 'fdjc',
      ableUploadImg: true,
      ableRemoveImg: false,
      isRequire: false
    },
    {
      code: '1-8',
      name: '中控',
      url: '',
      qczj: '',
      bgImage: 'clmp',
      ableUploadImg: true,
      ableRemoveImg: false,
      isRequire: false
    },
    {
      code: '1-9',
      name: '前排（A柱B柱）',
      url: '',
      qczj: '',
      bgImage: 'clgls',
      ableUploadImg: true,
      ableRemoveImg: false,
      isRequire: false
    },
    {
      code: '1-10',
      name: '后排（B柱C柱）',
      url: '',
      qczj: '',
      bgImage: 'cz12',
      ableUploadImg: true,
      ableRemoveImg: false,
      isRequire: false
    },
    {
      code: '1-11',
      name: '备胎（其他附件）',
      url: '',
      qczj: '',
      bgImage: 'cz12',
      ableUploadImg: true,
      ableRemoveImg: false,
      isRequire: false
    },
    {
      code: '1-12',
      name: '车钥匙',
      url: '',
      qczj: '',
      bgImage: 'cz34',
      ableUploadImg: true,
      ableRemoveImg: false,
      isRequire: false
    }
  ]

  // 更新数据
  @action.bound
  updateData(key, value) {
    this[key] = value;
    // console.log(key + value);
  }

  // 更新优选一年车的照片和销售定价
  async updateFirstPhoto() {
    API.CarSource.getCarFamilyPhotos({
      basicId: this.basicId
    }).then(res => {
      //
      if (res.data) {
        this.photos1.map(item => {
          item.url = '';
          item.qczjUrl = '';
        });
        this.updateData('photos1', mergePhotosArr([...this.photos1], [...res.data.photos]));
        this.updateData('saleSugestPrice', res.data.price);
      }
    });
  }

  async updateSecondPhoto() {
    this.photos1.map(item => {
      item.url = '';
      item.qczjUrl = '';
    });
    this.updateData('photos1', mergePhotosArr([...this.photos1], [...this.photos2]));
    this.updateData('saleSugestPrice', '');
  }

  async getData() {
    if (this.basicId === null) {
      return;
    }
    API.CarSource.getRetailinfo({
      basicId: this.basicId
    }).then(res => {
      if (res.code === 200) {
        if (res.data) {
          this.otherPhotos = [];
          if (this.photos1 && this.photos1.length > 0) {
            this.photos1.map(item => {
              item.url = '';
            });
          }
          this.authNum = res.data.authNum;
          const authTypeData = res.data.authType;
          this.authType = (authTypeData !== '' && authTypeData !== null && (authTypeData === 0 || authTypeData === 1)) ? [Number(authTypeData)] : [];
          this.carLicenseDate = res.data.carLicenseDate;
          const carType = res.data.carType;
          this.carType = (carType !== '' && carType !== null && (carType === 0 || carType === 1)) ? [Number(carType)] : [];
          this.mileage = res.data.mileage;
          this.purchasePirce = res.data.purchasePirce || '';
          this.vin = res.data.vin;
          if (res.data.photos && res.data.photos.length > 0) {
            this.updateData('photos2', [...res.data.photos]);// 更新接口获取的所有图片
            res.data.photos.map(item => {
              if (item.photoTypeDetailCode === '3-1') {
                this.otherPhotos.push({
                  url: item.photoUrl,
                  qczj: item.qczjUrl
                });
              }
            });
            this.updateData('otherPhotos', [...this.otherPhotos]);
          }
          if (this.carType.length > 0 && this.carType[0] === 1) {
            // 精品车
            this.updateSecondPhoto();
          }
          this.salePrice = res.data.salePrice || '';
          if (this.carType.length > 0 && this.carType[0] === 0) {
            // 优选一年车
            this.updateFirstPhoto();
          }
          // console.log(res.data.photos);
          // console.log(this.otherPhotos[0]);
        }
      } else {
        //
      }
    });
  }

  // 更新
  @action.bound
  async updateInfo() {
    if (this.authType.length === 0) {
      Toast.info('请选择认证类型');
      return;
    }
    if (!this.carLicenseDate) {
      Toast.info('请选择上牌时间');
      return;
    }
    if (this.carType.length === 0) {
      Toast.info('请选择车辆品类');
      return;
    }
    if (!this.mileage) {
      Toast.info('请输入表显里程');
      return;
    }
    if (!this.purchasePirce) {
      Toast.info('请输入收购价格');
      return;
    }
    if (!this.salePrice) {
      Toast.info('请输入销售定价');
      return;
    }
    if (!this.vin) {
      Toast.info('请输入VIN');
      return;
    }
    if ((this.vin + '').length !== 17) {
      Toast.info('VIN位数必须为17位');
      return;
    }
    // if (this.authType.length === 0 || !this.carLicenseDate || this.carType.length === 0 ||
    // !this.mileage || !this.purchasePirce || !this.salePrice || !this.vin) {
    // Toast.info('信息填写不完善');
    // return;
    // }

    if (!/^\d+(\.\d+)?$/.test(this.mileage)) {
      Toast.info('请输入正确的表显里程数');
      return;
    }
    // 必传照片
    // for (const item of this.photos1) {
    // if (item.isRequire && item.url === '') {
    // Toast.info('请上传车辆' + item.name + '照片');
    // return;
    // }
    // }

    if (this.otherPhotos && this.otherPhotos.length > 0) {
      this.photos11 = [];
      this.otherPhotos.map(item => {
        this.photos11.push({
          url: item.url,
          qczj: item.qczj,
          code: '3-1'
        });
      });
    }
    const params = {
      basicId: this.basicId,
      authNum: this.authNum,
      authType: this.authType[0],
      carType: this.carType[0],
      carLicenseDate: this.carLicenseDate,
      mileage: this.mileage,
      purchasePirce: this.purchasePirce,
      salePrice: this.salePrice,
      vin: this.vin,
      photos: [...this.photos11]
    };
    const res = await API.CarSource.updateRetailinfo(params);
    return res.code === 200;
  }
}

export default DALCarValuation.getInstance();
