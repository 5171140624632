import React from 'react';
import PropTypes from 'prop-types';
import styles from './style/ImageUploadCard.module.scss';

function ImageUploadCard(props) {
  return (
    <div className={`${styles.Box} ${props.className}`}>
      <span className={styles.titleName}>行驶证车辆信息智能识别 &gt; </span>
      <span className={styles.tipName}>(ocr识别结果请确认无误后再提交)</span>
      <input
        type="file"
        accept="image/*"
        className={styles.inputFile}
        onChange={props.onChange}
      />
    </div>
  );
}

ImageUploadCard.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string
};
ImageUploadCard.defaultProps = {
  className: ''
};

export default ImageUploadCard;
